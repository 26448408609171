import { DefaultFooter } from '@/components/Footer';
import { Head } from '@/components/Head';
import { Image, Flex, Text, Box, Button, Header, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { EventCard } from '../components/EventCard';
import { useNavigate } from 'react-router-dom';
import { EventsData } from '../types';
import { useEvents } from '../api/getEvents';
import { DefaultLoadingOverlay } from '@/components/DefaultLoadingOverlay';
import TypeTabs, { TypeTabProps } from '../components/TypeTabs';
import intimacyIcon from '@/assets/Event/intimacy.svg';
import passionIcon from '@/assets/Event/passion.svg';
import commitmentIcon from '@/assets/Event/commitment.svg'
import FeaturedEvents from '../components/FeaturedEvents';
import MatchesTab from '../components/MatchesTab';
import { useMatches } from '../api/getMatches';
import { useIdToken } from 'react-firebase-hooks/auth';
import auth from '@/lib/firebase';
import { useUserInfoStore } from '@/stores/authentication';


export const Events = () => {
  const navigate = useNavigate();
  const [user, loading, error] = useIdToken(auth);
  const { userInfo } = useUserInfoStore();
  const [intimacyPage, setIntimacyPage] = useState(1);
  const [passionPage, setPassionPage] = useState(1);
  const [commitmentPage, setCommitmentPage] = useState(1);
  const [singlesPage, setSinglesPage] = useState(1);
  const [couplesPage, setCouplesPage] = useState(1);
  const [allPage, setAllPages] = useState(1);
  const [intimacyLimit, setIntimacyLimit] = useState(5);
  const [passionLimit, setPassionLimit] = useState(5);
  const [commitmentLimit, setCommitmentLimit] = useState(5);
  const [singlesLimit, setSinglesLimit] = useState(5);
  const [couplesLimit, setCouplesLimit] = useState(5);
  
  const [isMatchLoaded, setIsMatchLoaded] = useState(false);

  const intimacyQuery = useEvents({
    tags: ':intimacy',
    limit: intimacyLimit,
    skip: (intimacyPage - 1) * intimacyLimit,
    config: {
    }
  });

  const passionQuery = useEvents({
    tags: ':passion',
    limit: passionLimit,
    skip: (passionPage - 1) * passionLimit,
    config: {
    }
  });

  const commitmentQuery = useEvents({
    tags: ':commitment',
    limit: commitmentLimit,
    skip: (commitmentPage - 1) * commitmentLimit,
    config: {
    }
  });

  const singlesQuery = useEvents({
    tags: ':singles',
    limit: singlesLimit,
    skip: (singlesPage - 1) * singlesLimit,
    config: {
    }
  });

  const couplesQuery = useEvents({
    tags: ':couples',
    limit: couplesLimit,
    skip: (couplesPage - 1) * couplesLimit,
    config: {
    }
  });

  const intimacy = intimacyQuery?.data;
  const passion = passionQuery?.data;
  const commitment = commitmentQuery?.data;
  const singles = singlesQuery?.data;
  const couples = couplesQuery?.data;

  const intimacyTab = {
    type: 'intimacy',
    display: 'Intimacy',
    cover: intimacyIcon,
    onCardClick: (eventId: string) => {
      navigate(`/events/${eventId}`);
    },
    active: true,
    activeType: 'underline',
    events: intimacy?.data.Events,
  };

  const passionTab = {
    type: 'passion',
    display: 'Passion',
    cover: passionIcon,
    onCardClick: (eventId: string) => {
      navigate(`/events/${eventId}`);
    },
    active: true,
    activeType: 'underline',
    events: passion?.data.Events,
  };

  const commitmentTab = {
    type: 'commitment',
    display: 'Commitment',
    cover: commitmentIcon,
    onCardClick: (eventId: string) => {
      navigate(`/events/${eventId}`);
    },
    active: true,
    activeType: 'underline',
    events: commitment?.data.Events,
  };

  const eventsTab = [intimacyTab, passionTab, commitmentTab];
  return (
    <>
      <DefaultLoadingOverlay loading={!intimacy && !passion && !commitment && isMatchLoaded} />
      <Head title="Events" headDescription="Date events" />
      <Flex direction="column" pb='md' w='100vw' maw='540px'>
        <TypeTabs
          types={eventsTab}
          defaultValue="intimacy"
          snap
        />
        {
          !!user && !!userInfo && (
            <MatchesTab
              setIsMatchLoaded={setIsMatchLoaded}
              user={user}
              snap
            />
          )
        }
        <FeaturedEvents
          title='Specials for singles'
          id=':singles'
          snap
          events={singles?.data.Events}
          onCardClick={(url: string) => {
            navigate(url);
          }}
        />
        <FeaturedEvents
          title='Specials for couples'
          id=':couples'
          snap
          events={couples?.data.Events}
          onCardClick={(url: string) => {
            navigate(url);
          }}
        />
      </Flex>
      <DefaultFooter />
    </>
  );
};
