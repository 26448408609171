import { useEffect, useRef, useState } from "react";
import { Head } from "@/components/Head";
import { Flex, UnstyledButton, Stack, Title, Text, createStyles, TextInput, Divider, Select, Checkbox, Alert, Box } from "@mantine/core";
import { IconAlertCircle, IconArrowLeft } from "@tabler/icons-react";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from "@/lib/firebase";
import { BaseButton } from "@/components/BaseButton";
import { NewUserProps, useNewUser } from '../api/newUser';
import { HeadlessNavigation } from "@/components/HeadlessNavigation";
import { useUserInfoStore } from "@/stores/authentication";
import { Gender } from "@/features/account/types";
import { useGetUser } from "../api/getUser";

const useStyles = createStyles((theme) => ({
  input: {
    margin: 0,
    marginBottom: theme.spacing.xs,
    '.mantine-Input-input': {
      borderBottom: '1px solid #E5E5E5',
    }
  },
  selectInput: {
    margin: 0,
    marginBottom: theme.spacing.xs,
    '[data-selected]': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.primary[3],
      color: theme.white,
      ':hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.primary[3],
        color: theme.white,
      },
    },

    // applies styles to hovered item (with mouse or keyboard)
    '[data-hovered]': {},
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100%'
  }
})
);

export const SignUpDetails = () => {
  const { classes, cx } = useStyles();

  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const location = useLocation();

  const [canSubmit, setCanSubmit] = useState<boolean>(false);

  type UserWithPrivacyPolicy = NewUserProps & {
    agreePrivacyPolicy: boolean;
  };

  const [formDetails, setFormDetails] = useState<UserWithPrivacyPolicy>({
    givenName: !!user && user.displayName !== null ? user.displayName.slice(0, user.displayName.indexOf(' ')) : undefined,
    familyName: !!user && user.displayName !== null ? user.displayName.slice(user.displayName.indexOf(' ') + 1) : undefined,
    phoneNumber: !!user && user.providerData[0].phoneNumber !== null ? user.providerData[0].phoneNumber : undefined,
    email: !!user && user.providerData[0].email !== null ? user.providerData[0].email : undefined,
    sex: Gender.Male,
    agreePrivacyPolicy: false,
  });
  const { register, handleSubmit, formState: { errors }, setValue } = useForm({
    defaultValues: {
      ...formDetails,
    },
  });

  const errorRef = useRef<HTMLDivElement>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [canGetUser, setCanGetUser] = useState<boolean>(false);

  const { setUserInfo } = useUserInfoStore();

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
    }
  }, [errorRef, errorMessage])

  useGetUser({
    user: user,
    config: {
      enabled: !!user && canGetUser,
      onSuccess: async (data) => {
        await setUserInfo(data);
        navigate('/auth/journey', {
          state: {
            from: 'signup',
          },
        });
      },
      onError: (e: any) => {
      }
    },
  });

  useNewUser({
    user: user,
    givenName: formDetails.givenName,
    familyName: formDetails.familyName,
    phoneNumber: formDetails.phoneNumber,
    sex: formDetails.sex,
    email: formDetails.email,
    config: {
      enabled: !!user && !!formDetails.givenName && !!formDetails.familyName && !!formDetails.phoneNumber && !!formDetails.email && !!formDetails.sex && canSubmit,
      retry: false,
      onSuccess: async () => {
        setCanGetUser(true)
        setCanSubmit(false);
      },
      onError: (e: any) => {
        setCanGetUser(false)
        setCanSubmit(false);
        if (e && e.response && e.response.data.message == 'user already exists') {
          navigate('/auth/success', {
            state: {
              from: 'login',
            },
          });
        }
        if (e && e.response && e.response.data.message == 'duplicated email error') {
          setValue('email', '');
          setErrorMessage('You have registered with the same email before, please try with a different one instead.');
          setShowErrorMessage(true);
        }
      }
    },
  });

  const onSubmit = (data: any) => {
    if (data.agreePrivacyPolicy) {
      if (showErrorMessage) {
        setShowErrorMessage(false);
        setErrorMessage('');
      }
      setFormDetails(data);
      setCanSubmit(true);
    }
  };

  return (
    <HeadlessNavigation back={1} headTitle='Personal Information' headDescription='Personal Information' title='Personal Information' divider >
      <form
        onSubmit={handleSubmit(onSubmit)}
        onChange={() => {
          setCanSubmit(false);
        }}
        className={cx(classes.form)}
      >
        <Stack>
          <Stack p="xl" pb={0} pt='md' spacing='md'>
            <Title fz={18}>Log in method</Title>
            <Text fz={16}>{location.state && location.state.method ? location.state.method : ''}</Text>
          </Stack>
          <Divider size={20} color='#f0f0f0' />
          <Stack p="xl" pt={0} spacing='md'>
            <Title fz={18} pb="md">Contact information</Title>
            <div>
              <TextInput
                id='givenName'
                label="First Name"
                className={classes.input}
                variant="unstyled"
                withAsterisk
                defaultValue={formDetails.givenName}
                {...register(`givenName`, { required: 'First Name required' })}
              />
              <ErrorMessage
                errors={errors}
                name='givenName'
                render={({ message }) => <Text color="red" size="sm">{message}</Text>}
              />
            </div>
            <div>
              <TextInput
                id='familyName'
                label="Last Name"
                className={classes.input}
                variant="unstyled"
                withAsterisk
                defaultValue={formDetails.familyName}
                {...register(`familyName`, { required: 'Last Name required' })}
              />
              <ErrorMessage
                errors={errors}
                name='familyName'
                render={({ message }) => <Text color="red" size="sm">{message}</Text>}
              />
            </div>
            <div>
              <Select
                id='sex'
                label='Gender'
                placeholder="Gender"
                className={classes.selectInput}
                withAsterisk
                data={[
                  { value: Gender.Female, label: 'Female (Ms)' },
                  { value: Gender.Male, label: 'Male (Mr)' },
                  { value: Gender.NonBinary, label: 'Non-binary (Mx)' },
                ]}
                defaultValue={Gender.Male}
                styles={(theme) => ({
                  item: {
                    '&[data-selected]': {
                      '&, &:hover': {
                        backgroundColor: theme.colors.primary[3],
                        color: theme.white
                      },
                    },
                  }
                })}
                {...register(`sex`)}
                onChange={(v) => {
                  if (v) setValue('sex', v);
                }}
              />

            </div>
            <div>
              <TextInput
                id='phoneNumber'
                label="Phone Number"
                className={classes.input}
                variant="unstyled"
                withAsterisk
                defaultValue={formDetails.phoneNumber}
                {...register(`phoneNumber`, { required: 'Phone Number required' })}
              />
              <ErrorMessage
                errors={errors}
                name={'phoneNumber'}
                render={({ message }) => <Text color="red" size="sm">{message}</Text>}
              />
            </div>
            <div>
              <TextInput
                id='email'
                label="Email"
                className={classes.input}
                variant="unstyled"
                withAsterisk
                defaultValue={formDetails.email}
                {...register(`email`, { required: 'Email required', pattern: /^\S+@\S+$/ })}
              />
              <ErrorMessage
                errors={errors}
                name={'email'}
                render={({ message }) => <Text color="red" size="sm">{message}</Text>}
              />
            </div>
            <Checkbox defaultChecked={formDetails.agreePrivacyPolicy}
              color='primary.3'
              label={
                <Text >I have read and agreed to the <Link to="/privacy-policy" >
                  <Text span color='primary.3' sx={{
                    textDecoration: 'underline',
                    cursor: 'pointer'
                  }}>Privacy Policy</Text></Link>.</Text>
              }
              p={0}
              {...register(`agreePrivacyPolicy`, { required: 'Please agree to the privacy policy' })}
            />
            <ErrorMessage
              errors={errors}
              name={'agreePrivacyPolicy'}
              render={({ message }) => <Text color="red" size="sm">{message}</Text>}
            />
            <BaseButton type='submit' content="Save" style={{ marginTop: 16 }} />
            {
              showErrorMessage && (
                <Alert ref={errorRef} icon={<IconAlertCircle size="1rem" />} title="Failed to save your info" color="red">
                  {errorMessage}
                </Alert>
              )
            }

          </Stack>
        </Stack>
      </form>
    </HeadlessNavigation>
  );
};