import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { string } from 'zod';
import { Booking, NewBooking } from '../types';

type createBookingProps = {
  newBooking: NewBooking;
  user?: any;
};

export const createBooking = async ({ newBooking, user }: createBookingProps) => {
  if (user) {
    const idToken = await user?.getIdToken();
    const data = await axios.post<Booking>(`/bookings/`, newBooking, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });
    return data;
  }
};

type QueryFnType = typeof createBooking;

type UseBookingOptions = {
  booking: NewBooking;
  user?: any;
  config?: QueryConfig<QueryFnType>;
};

export const useBooking = ({ booking, config, user }: UseBookingOptions) => {
  try {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: ['booking', booking],
      queryFn: () => {
        return createBooking({ newBooking: booking, user });
      },
    });
  } catch (e) {
    return;
  }
};
