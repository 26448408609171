import { useIdToken, useAuthState } from 'react-firebase-hooks/auth';
import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { Event } from '../types';

type getEventProps = {
  id?: string;
};

export const getEvent = async ({ id }: getEventProps) => {
  const { data } = await axios.get<Event>(`/events/${id}`);
  return data;
};

type QueryFnType = typeof getEvent;

type UseEventOptions = {
  eventId?: string;
  config?: QueryConfig<QueryFnType>;
};

export const useEvent = ({ eventId, config }: UseEventOptions) => {
  try {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [eventId],
      queryFn: () => getEvent({ id: eventId}),
    });
  } catch (e) {
    console.log(e);
    return;
  }
};
