import { createStyles, Footer, UnstyledButton, Text, Grid, Loader } from '@mantine/core';

import bookmarkUnfilled from '@/assets/Event/bookmark-unfilled.svg';
import bookmarkFilled from '@/assets/Event/bookmark-filled.svg';
import share from '@/assets/Event/share-unfilled.svg';
import { DEPLOY_STAGE } from '@/config';
import { useEffect, useState } from 'react';
import { useRouteStore } from '@/stores/route';
import { useNavigate } from 'react-router-dom';
import { useCreateSavedEvent } from '../api/createSavedEvent';
import { useDeleteSavedEvent } from '../api/deleteSavedEvent';
import { useSavedEvents } from '../api/getSavedEvents';

type EventFooterProps = {
  isToggleSave?: string | null;
  user?: any;
  userInfo?: any;
  eventId?: string;
  title?: string;
  url?: string;
  currency?: string;
  price?: string;
  disabled?: boolean;
  onBook?: () => void;
};

const useStyles = createStyles((theme) => ({
  footer: {
    padding: 19,
    paddingLeft: 16,
    paddingRight: 16,
    boxShadow: '0px -2px 7px rgba(0, 0, 0, 0.2)',
    WebkitBoxShadow: '0px -2px 7px rgba(0, 0, 0, 0.2)',
  },
  footerButton: {
    width: '100%',
    padding: 14.5,
    backgroundColor: theme.colors.primary[3],
    borderRadius: 8,
    transition: '0.3s',
  },
  disabled: {
    backgroundColor: theme.colors.gray[5],
    pointerEvents: 'all',
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: theme.colors.gray[5],
    },
  },
}));

export const EventFooter = ({ currency, price, disabled, onBook, user, userInfo, isToggleSave, eventId, title, url }: EventFooterProps) => {
  const { classes, cx } = useStyles();
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [toggleSave, setToggleSave] = useState<boolean>(false);
  const { setRedirectRoute, } = useRouteStore();
  const navigate = useNavigate();

const handleToggleSave = () => {
    if (!user || !userInfo) {
      setRedirectRoute(`${window.location.pathname}?toggleSave=t`);
      navigate('/auth');
      return;
    }
    setToggleSave(true);
  };

  const handleShare = () => {
    const shareData = {
      title,
      // text: description,
      url
    };

    if (navigator.share) {
      navigator.share(shareData);
    } else {
      // fallback
      console.log('fallback');
    }

  };

  useCreateSavedEvent({
    user,
    toggleSave,
    eventId,
    config: {
      enabled: toggleSave && !isSaved && !!user && !!userInfo,
      onSuccess: (data) => {
        setToggleSave(false);
      },
      onError: (error) => {
        console.log(error);
        setToggleSave(false);
      }
    },
  });

  useDeleteSavedEvent({
    user,
    toggleSave,
    eventId,
    config: {
      enabled: toggleSave && isSaved && !!user && !!userInfo,
      onSuccess: () => {
        setToggleSave(false);
      },
      onError: (error: any) => {
        console.log(error);
        setToggleSave(false);
      }
    },
  });

  const savedEventsQuery = useSavedEvents({
    user,
    toggleSave,
    config: {
      enabled: !!user && !!userInfo,
      onSuccess: (data) => {
      },
      onError: (error) => {
        console.log(error);
      }
    }
  });


  useEffect(() => {
    if (isToggleSave && eventId) {
      setToggleSave(true);
    }
  }, [isToggleSave, eventId]);

  useEffect(() => {
    if (savedEventsQuery?.data) {
      savedEventsQuery?.data?.forEach((savedEvent) => {
        if (savedEvent.ID === eventId) {
          setIsSaved(true);
          return;
        }
      });
    } else {
      if (savedEventsQuery?.data !== undefined) setIsSaved(false);
    }
  }, [savedEventsQuery?.data]);

  return (
    <Footer height="auto" className={cx(classes.footer)}>
      <Grid align="center" gutter={'xs'}>
        <Grid.Col span={12} pb="sm">
          {
            price && currency ? (
              <Text fz={14}>
                From&nbsp;
                <Text
                  span
                  fz={20}
                  fw={700}
                  sx={(theme) => ({
                    color:
                      theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.primary[3],
                  })}
                >
                  {currency} {price}
                </Text>
              </Text>
            ) : <></>
          }
        </Grid.Col>
        <Grid.Col span={1}>
          <UnstyledButton
            onClick={handleToggleSave}
            component="img"
            src={isSaved ? bookmarkFilled : bookmarkUnfilled}
            style={{
              width: 22,
              height: 22,
              alignContent: 'center',
            }}
          />
        </Grid.Col>
        <Grid.Col span={1}>
          <UnstyledButton
            onClick={handleShare}
            component="img"
            src={share}
            style={{
              width: 22,
              height: 22,
              alignContent: 'center',
            }}
          />
            </Grid.Col>
        <Grid.Col span={10}>
          <UnstyledButton
            className={cx(classes.footerButton, `${disabled ? classes.disabled : ''}`)}
            onClick={onBook}
          >
            <Text fz={18} fw={700} color="white" align="center">
              Book Now
            </Text>
          </UnstyledButton>
        </Grid.Col>
      </Grid>
    </Footer>
  );
};
