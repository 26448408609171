import { Stack, createStyles, Flex, Title, Divider, Space } from '@mantine/core';


import { HeadlessNavigation } from '@/components/HeadlessNavigation';
import { AccountCover } from '../components/AccountCover';
import { AccountOption } from '../components/AccountOption';
import { useAuthState, useSignOut } from 'react-firebase-hooks/auth';
import auth from '@/lib/firebase';

import PersonalInformation from '@/assets/Account/portrait.svg';
import Bookings from '@/assets/Account/description.svg';
import SavedEvents from '@/assets/Account/bookmark-border.svg';
import Logout from '@/assets/Account/logout.svg';

import storage from '@/utils/storage';
import { useNavigate } from 'react-router';
import { DEPLOY_STAGE } from '@/config';
import { useUserInfoStore } from '@/stores/authentication';

const useStyles = createStyles((theme) => ({

}));

export const Account = () => {
  const { classes, cx } = useStyles();

  const [user] = useAuthState(auth);
  const { userInfo, clearUserInfo } = useUserInfoStore();

  const [signOut, loading, error] = useSignOut(auth);

  const navigate = useNavigate();

  return (
    <HeadlessNavigation back={1}>
      <Stack spacing="xs">
        <AccountCover img={userInfo.Picture || undefined} />
        <Flex w='100%' justify='center' mt={35}>
          <Title order={3}>{(userInfo.GivenName && userInfo.FamilyName) ? `${userInfo.GivenName} ${userInfo.FamilyName}` : user?.displayName ? user?.displayName : 'User'}</Title>
        </Flex>
        <Space h='xl' />
          <AccountOption img={PersonalInformation} text="Personal information" onClick={() => navigate('/account/personal-information')} />
          <Divider mx='xl' />
        <AccountOption img={Bookings} text="Bookings" onClick={() => navigate('/bookings')} />
            <Divider mx='xl' />
            <AccountOption img={SavedEvents} text="Saved events" onClick={() => navigate('/saved-events')} />
        <Divider mx='xl' />
        <AccountOption img={Logout} text="Log out" onClick={async () => {
          const success = await signOut();
          if (success) {
            await clearUserInfo();
            navigate('/')
          }
        }
        } />
      </Stack>
    </HeadlessNavigation>
  );
};
