import { createStyles, Drawer, Flex, useMantineTheme, UnstyledButton, Button } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import { useState } from 'react';
import dayjs from 'dayjs';
type SlotDatePickerProps = {
  date: Date | null;
  setDate: (date: Date | null) => void;
  open: boolean;
  onClose: () => void;
  slotsByDate: any;
};

const useStyles = createStyles((theme) => ({
  clear: {
    opacity: 0.5,
    transition: '0.3s',
    '&:hover': {
      color: theme.colors.primary[3],
    },
  },
  drawerInner: {
    margin: 'auto',
    marginBottom: 0,
    width: `${theme.breakpoints.xs}`,
    '@media (max-width: 540px)': {
      width: '100%',
    }},
  drawerContent: {
      borderRadius: '8px 8px 0 0',
      height: 450,
    },
  drawerTitle: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      fontWeight: 500,
      fontSize: 18,
      padding: 16,
      margin: 0,
    },
  drawerHeader: {
      borderBottom: '1px solid #F0F0F0',
    },
  drawerClose: {
      display: 'none',
  },
  calendar: {
    padding: theme.spacing.md,
    minHeight: 350
  },
  calendarHeader: {
    maxWidth: '100% !important'
  },
  calendarMonthLevel: {
    flexGrow: 1
  },
  calendarYearLevel: {
    flexGrow: 1,
  },
  calendarDecadeLevel: {
    flexGrow: 1,
  },
  calendarMonth: {
    width: '100% !important',
  },
  calendarMonthsList: {
    justifyContent: 'center',
    margin: 'auto',
    width: '100% !important',
  },
  calendarYearList: {
    justifyContent: 'center',
    margin: 'auto',
    width: '100% !important',
  },
  calendarYearLevelGroup: {
    justifyContent: 'center',
  },
  calendarDecadeLevelGroup: {
    justifyContent: 'center',
  },
  calendarPickerControl: {
    margin: 'auto'
  },
  applyWrapper: {
    position: 'fixed',
    bottom: 0,
    left: 0,
  },
  apply: {
    transition: '0.3s',
    backgroundColor: `${theme.colors.primary[3]}`,
    '&:hover': {
      backgroundColor: `${theme.colors.primary[2]} !important`,
    }
  },
}));

export const SlotDatePicker = ({ date, setDate, open, onClose, slotsByDate }: SlotDatePickerProps) => {
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  return (
    <Drawer
      classNames={{
        inner: cx(classes.drawerInner),
        content: cx(classes.drawerContent),
        title: cx(classes.drawerTitle),
        header: cx(classes.drawerHeader),
        close: cx(classes.drawerClose),
      }}
      overlayProps={{
        color: theme.colors.dark[9],
        opacity: 0.55,
        blur: 3
      }}
      position="bottom"
      opened={open}
      onClose={onClose}
      shadow="md"
      title={
        <>
          <Flex w="100%" justify="center">
            Date
          </Flex>
          <UnstyledButton
            w={40}
            className={cx(classes.clear)}
            onClick={() => setSelectedDate(null)}
          >
            Clear
          </UnstyledButton>
        </>
      }
    >
      <DatePicker
        firstDayOfWeek={0}
        allowDeselect
        value={selectedDate}
        onChange={setSelectedDate}
        classNames={{
          calendar: cx(classes.calendar),
          calendarHeader: cx(classes.calendarHeader),
          monthLevel: cx(classes.calendarMonthLevel),
          month: cx(classes.calendarMonth),
          monthsList: cx(classes.calendarMonthsList),
          yearsList: cx(classes.calendarYearList),
          yearLevelGroup: cx(classes.calendarYearLevelGroup),
          decadeLevelGroup: cx(classes.calendarDecadeLevelGroup),
          yearLevel: cx(classes.calendarYearLevel),
          decadeLevel: cx(classes.calendarDecadeLevel),
          pickerControl: cx(classes.calendarPickerControl),
        }}
        getDayProps={(date) => {
          if (dayjs(selectedDate).isSame(dayjs(date))) {
            return {
              sx: (theme) => ({
                backgroundColor: `${theme.colors.primary[3]} !important`,
              })
            };
          }
          return {};
        }}
        getMonthControlProps={(date) => {
          if (dayjs(selectedDate).month() === dayjs(date).month()) {
            return {
              sx: (theme) => ({
                color: `${theme.white} !important`,
                backgroundColor: `${theme.colors.primary[3]} !important`,
              })
            };
          }
          return {};
        }}
        getYearControlProps={(date) => {
          if (dayjs(selectedDate).year() === dayjs(date).year()) {
            return {
              sx: (theme) => ({
                color: `${theme.white} !important`,
                backgroundColor: `${theme.colors.primary[3]} !important`,
              })
            };
          }
          return {};
        }}
        excludeDate={(date) => {
          for (const slot of slotsByDate) {
            if (dayjs(slot.date).isSame(dayjs(date)) && !slot.areAllTimesExpired) {
              return false;
            }
          }
          return true;
        }}
      />
      <Flex w="100%" justify="center" mt="sm" className={cx(classes.applyWrapper)}>
        <Button
          w="100%"
          m="sm"
          h={45}
          radius="md"
          className={cx(classes.apply)}
          disabled={!selectedDate}
          onClick={() => {
            setDate(selectedDate);
            onClose();
          }}
        >
          Apply
        </Button>
      </Flex>
    </Drawer>
  );
};
