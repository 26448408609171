import { Carousel, Embla } from "@mantine/carousel";
import { createStyles, Flex, Pagination, getStylesRef, UnstyledButton, Box } from '@mantine/core';
import { useState, useEffect, useCallback } from 'react';

type EventsCarouselProps = {
  children: React.ReactNode;
  events: any;
  slideSize?: string;
  slideToScroll?: number;
  slideGap?: number;
};

const useStyles = createStyles((_theme, _params, getRef) => ({
  root: {
    '&:hover': {
      [`& .${getStylesRef('controls')}`]: {
        opacity: 1,
      },
    },
  },
  slide: {
    paddingLeft: _theme.spacing.md,
    paddingRight: _theme.spacing.md,
  },
  control: {
    color: _theme.colors.primary[3],
    '&[data-inactive]': {
      opacity: 0,
      cursor: 'default',
    },
    [`@media (max-width: ${_theme.breakpoints.sm})`]: {
      display: 'none'
    },
  },
  controls: {
    ref: getStylesRef('controls'),
    transition: 'opacity 150ms ease',
    opacity: 0,
  },
}));

const PaginationItem = (props: any) => {
  return <UnstyledButton
    {...props}
    p={4.5}
    sx={(theme) => ({
      background: props.active ? theme.colors.primary[3] : '#ccc',
    })
    }
  > </UnstyledButton>;
}

const EventsCarousel = ({
  children,
  events,
  slideSize,
  slideToScroll,
  slideGap,
}: EventsCarouselProps) => {

  const { classes } = useStyles();
  const [embla, setEmbla] = useState<Embla | null>(null);
  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (embla) {
      embla.on('scroll', handleScroll);
    }
  }, [embla]);

  useEffect(() => {
    if (events && events.length > 0) {
      if (slideSize == '50%') {
        if (events.length % 2 == 0) {
          setTotal(events.length / 2);
        } else {
          setTotal((events.length / 2) + 1);
        }
      } else {
        setTotal(events.length);
      }
    }
  }, [events])

  const handleScroll = useCallback(() => {
    let eventsLength = events.length;
    if (slideSize == '50%') {
      eventsLength = events.length / 2;
    }

    if (!embla) return;

    if (embla.scrollProgress() < -0) {
      setActivePage(1);
      return;
    }

    if (embla.scrollProgress() > 1) {
      setActivePage(eventsLength);
      return;
    }

    if (embla.scrollProgress() === -0) {
      setActivePage(1);
      return;
    }
    setActivePage(Math.ceil(embla?.scrollProgress() * eventsLength));
  }, [embla]);

  const handlePageChange = (page: number) => {
    setActivePage(page);
    embla && embla.scrollTo(page - 1);
  };

  return (
    <>
      <Carousel
        classNames={classes}
        slideSize={slideSize ? slideSize : '100%'}
        slideGap={slideGap ? slideGap : 0}
        slidesToScroll={slideToScroll ? slideToScroll : 1}
        align="start"
        inViewThreshold={0.2}
        draggable={events && events.length > 0 ? true : false}
        controlsOffset={15}
        getEmblaApi={setEmbla}
        sx={{
          '.mantine-Carousel-controls': {
            display: !events || events.length == 0 ? 'none' : 'flex',
          }
        }}
      >
        {children}
      </Carousel>
      <Flex justify='center' w='100%'>
        <Pagination
          mt={10}
          total={total}
          value={activePage}
          onChange={(page) => handlePageChange(page)}
          withControls={false}
          radius='xl'
          color='primary.3'
          size={9}
          getItemProps={(page) => ({
            component: PaginationItem,
            active: page === activePage,
          })}
        />
        {events && events.length < 2 && <Box my={10.5}></Box>}
      </Flex>
    </>
  );
};

export default EventsCarousel;