export const API_URL = process.env.REACT_APP_API_URL as string;
export const JWT_SECRET = '123456' as string;
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string;
export const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY as string;
export const FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_FIREBASE_AUTH_DOMAIN as string;
export const FIREBASE_DATABASE_URL = process.env.REACT_APP_FIREBASE_DATABASE_URL as string;
export const FIREBASE_STORAGE_BUCKET = process.env.REACT_APP_FIREBASE_STORAGE_BUCKET as string;
export const FIREBASE_MESSAGING_SENDER_ID = process.env
  .REACT_APP_FIREBASE_MESSAGING_SENDER_ID as string;
export const FIREBASE_APP_ID = process.env.REACT_APP_FIREBASE_APP_ID as string;
export const FIREBASE_MEASUREMENT_ID = process.env.REACT_APP_FIREBASE_MEASUREMENT_ID as string;
export const DEPLOY_STAGE = process.env.REACT_APP_DEPLOY_STAGE as string;