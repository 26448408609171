import { Box, createStyles, Flex, Group, Header, TextInput, UnstyledButton, Text, Button } from '@mantine/core';
import { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import omsLogo from '@/assets/logo.svg';
import tagline from '@/assets/NavigationBar/tagline.svg';
import person from '@/assets/NavigationBar/person.svg';
import { SearchInputBox as SearchInputBox } from '@/features/search/components/SearchInputBox';
import { DEPLOY_STAGE } from '@/config';
import { useEventListener } from '@mantine/hooks';
import { useSearchStore } from '@/stores/search';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '@/lib/firebase';
import { useUserInfoStore } from '@/stores/authentication';
import { useRouteStore } from '@/stores/route';

type NavigationBarProps = {
};

const useStyle = createStyles((theme) => ({
  root: {
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : 'white',
    border: 'none',
    position: 'sticky',
    top: 0,
    transition: 'top 0.3s',
    zIndex: 1002,
  },
  logo: {
    maxWidth: 90,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      maxWidth: 70,
    },
  },
  searchWrapper: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  linkIcon: {
    width: 25,
    height: 25,
    filter: theme.colorScheme === 'dark' ? 'invert(100%)' : 'none',
  },
  searchInput: {
    width: '100%',
  },
}));

export const NavigationBar = ({ }: NavigationBarProps) => {
  const navigate = useNavigate();

  const { classes, cx } = useStyle();

  const [isTop, setIsTop] = useState(true);
  const [scrolledUp, setScrolledUp] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const { searchOpened, setSearchOpened } = useSearchStore();
  const { userInfo } = useUserInfoStore();
  const { setRedirectRoute } = useRouteStore();
  const [user, loading] = useAuthState(auth);


  // const useClickOutsideRef = useClickOutside(() => setSearchOpened(false));

  const handleScroll = useCallback(() => {
    const main = document.getElementById('main');
    const navigationBar = document.getElementById('navigation-bar');
    if (main && navigationBar) {
      setScrolledUp(main.scrollTop < prevScrollPos);
      if (main.scrollTop < prevScrollPos) {
        navigationBar.style.top = "0";
      } else {
        navigationBar.style.top = "-80px";
      }
      setIsTop(main.scrollTop === 0);
      setPrevScrollPos(main.scrollTop);
    }
  }, [document.getElementById('main')?.scrollTop]);

  useEffect(() => {
    const main = document.getElementById('main');
    if (main) {
      main.addEventListener('scroll', handleScroll, { passive: true });
    }
    return () => {
      if (main) {
        main.removeEventListener('scroll', handleScroll);
      }
    };
  }, [document.getElementById('main')?.scrollTop]);

  return (
    <Header
      id='navigation-bar'
      style={{
        boxShadow: scrolledUp && !isTop ? '0px 2px 7px rgba(0, 0, 0, 0.2)' : 'none',
        WebkitBoxShadow: scrolledUp && !isTop ? '0px 2px 7px rgba(0, 0, 0, 0.2)' : 'none',
        transition: 'top 0.3s',
      }}
      className={cx(classes.root)}
      height={{
        base: 58,
        md: 58,
      }}
      px='lg'
    >
      <Flex h={58} align="center" gap="sm">
        {
          !searchOpened && (
            <Link to="/">
              <img src={omsLogo} alt='Evol App' className={cx(classes.logo)} />
            </Link>
          )
        }
        {/* {
          !searchOpened && DEPLOY_STAGE == 'development' && (
            <Link to="/theory-of-love">
              <img
                src={tagline}
                alt='Triangular Theory of Love'
                className={cx(classes.logo)}
              />
            </Link>
          )
        } */}
        {/* <Box className={cx(classes.searchWrapper)} ref={useClickOutsideRef}> */}
        <Box className={cx(classes.searchWrapper)}>
          <SearchInputBox searchOpened={searchOpened} setSearchOpened={setSearchOpened} />
        </Box>

        {!searchOpened && (
          <UnstyledButton
            alt="person"
            component="img"
            src={person}
            className={cx(classes.linkIcon)}
            onClick={async () => {
              if (!(!!user && !!userInfo)) {
                await setRedirectRoute('/account');
              }
              navigate('/account');
            }}
          />
        )}
      </Flex>
    </Header>
  );
};
