import { useSearchStore } from "@/stores/search";
import { createStyles } from "@mantine/core";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';

const useStyles = createStyles((theme) => ({
})
);

export const Search = () => {
  const { classes, cx } = useStyles();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate()

  const {
    searchOpened,
    setSearchOpened,
    setLoveFactor,
    setMatch,
    setDate,
    setLocation,
    setSort,
    setTags,
  } = useSearchStore();

  const loveFactor = searchParams.get('love-factor');
  const match = searchParams.get('match');
  const date = searchParams.get('date');
  const location = searchParams.get('location');
  const sort = searchParams.get('sort');
  const tags = searchParams.get('tags');


  useEffect(() => {
    if (!searchOpened) setSearchOpened(true);
    if (loveFactor) setLoveFactor(loveFactor);
    if (match) setMatch(match); 
    if (date) {
      const _date = dayjs(date).format('YYYY-MM-DD');
      setDate(_date);
    }
    if (location) setLocation(location);
    if (sort) setSort(sort);
    if (tags) setTags(tags)
  }, [navigate]);

  return (
    <></>
  );
};