import { Card, createStyles, Stack, Text } from '@mantine/core';
import dayjs from 'dayjs';

type SlotDateCardProps = {
  selected?: boolean;
  id?: string;
  date?: string;
  areAllTimesExpired?: boolean;
  handleSelect: (date?: string) => void;
};

const useStyles = createStyles((theme) => ({
  card: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 80,
    transition: '0.3s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.primary[1],
      color: theme.colors.primary[3],
    },
  },
  dimmed: {
    opacity: 0.5,
  },
}));

export const SlotDateCard = ({ selected, id, date, areAllTimesExpired, handleSelect }: SlotDateCardProps) => {
  const { classes, cx } = useStyles();
  return (
    <Card
      id={id}
      className={cx(classes.card)}
      shadow="sm"
      p="md"
      onClick={() => {
        if (!areAllTimesExpired) {
          handleSelect(date);
        }
      }}
      radius="md"
      withBorder
      sx={(theme) => ({
        backgroundColor: selected ? theme.colors.primary[1] : 'transparent',
        color: selected ? theme.colors.primary[3] : 'inherit',
        opacity: areAllTimesExpired ? 0.5 : 1,
        pointerEvents: areAllTimesExpired ? 'none' : 'auto',
      })}
    >
      <Card.Section>
        <Stack align="center" p="xs" spacing={5}>
          <Text weight={500} size={14}>
            {dayjs(date).format('DD MMM')}
          </Text>
          <Text size={14} className={cx(classes.dimmed)}>
            {dayjs(date).format('ddd')}
          </Text>
        </Stack>
      </Card.Section>
    </Card>
  );
};
