import { Carousel } from "@mantine/carousel";
import { Avatar, createStyles, Flex, Tabs, Text, Image, Button, Box, Title, useMantineTheme, Card } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { EventCard } from "./EventCard";
import EventsCarousel from "./EventsCarousel";
import { EventSeeMoreCard } from "./EventSeeMoreCard";

type FeaturedEventsProps = {
  id?: string;
  title?: string;
  snap?: boolean;
  events?: any;
  onCardClick: (arg0: any) => void;
};

const useStyles = createStyles((_theme, _params) => ({
  tabs: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: _theme.spacing.md,
    paddingBottom: 0,
    paddingTop: 0,
    marginBottom: _theme.spacing.md,
    flexWrap: 'nowrap',
    WebkitOverflowScrolling: 'touch',
    scrollbarWidth: 'none',
    '::webkit-scrollbar': {
      display: 'none',
    },
    '::MsOverflowStyle': 'none',

  },
  tab: {
    border: 'none',
    borderRadius: _theme.radius.xl,
    background: 'none',
    padding: 'none',
    cursor: 'pointer'
  }
}));

const FeaturedEvents = ({ id, title, snap, events, onCardClick }: FeaturedEventsProps) => {
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const theme = useMantineTheme();

  const slides = events && events.length > 0 ?
    events.map((event: any, i: number) => {
      if (events.length - 1 == i && events.length > 4) {
        return (
          <Carousel.Slide key={i} onClick={() => {
            onCardClick(`/search?tags=${id}`);
          }}>
            <EventSeeMoreCard
              onClick={() => { }}
              width='100%'
              ml={0}
            />
          </Carousel.Slide>
        );
      }
      return (
        <Carousel.Slide key={i} onClick={() => {
          onCardClick(`/events/${event.Snag}`);
        }}>
          <EventCard
            key={`${i}-card`}
            onClick={() => { }}
            snap={snap}
            width='100%'
            name={event.Name}
            startDate={event.StartDate}
            count={event.Count}
            packageCurrency={event.PackageCurrency}
            packagePrice={event.PackagePrice}
            snag={event.Snag}
            pictures={event.Pictures && event.Pictures.length > 0 ? event.Pictures[0] : undefined}
            spotsAvailable={event.SpotsAvailable}
            cardHeight={200}
          />
        </Carousel.Slide>
      );
    })
    :
    <Flex
      justify="center"
      align="center"
      p="md"
      w="100%"
    >
      <Text
        weight={700}
        align="center"

      >
        No suggested events
      </Text>
    </Flex>;

  return (
    <Box pt='md'>
      <Title size={20} p='md'>
        {title}
      </Title>
      {
        events && <EventsCarousel
          events={events}
          slideGap={2}
          slideSize="100%"
        >
          {slides}
        </EventsCarousel>
      }

    </Box>
  );
};

export default FeaturedEvents;