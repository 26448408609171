import { Title, Text, Stack, createStyles } from '@mantine/core';
import { useAuthState, useSignInWithApple, useSignInWithFacebook, useSignInWithGoogle, useSignOut } from 'react-firebase-hooks/auth';
import { Link, useNavigate } from 'react-router-dom';

import google from '@/assets/Brand/google.svg';
import phone from '@/assets/Account/phone.svg';
import facebook from '@/assets/Brand/facebook-white.svg';
import apple from '@/assets/Brand/apple.svg';

import auth from '@/lib/firebase';
import { ActionButton } from '../components/ActionButton';
import { useGetUser } from '../api/getUser';
import { HeadlessNavigation } from '@/components/HeadlessNavigation';
import { useUserInfoStore } from '@/stores/authentication';
import { useEffect, useState } from 'react';
import { DefaultLoadingOverlay } from '@/components/DefaultLoadingOverlay';
import { timer } from '@/utils/timer';
import { DEPLOY_STAGE } from '@/config';

const useStyles = createStyles((theme) => ({
}));

// type SignUpProps = {
//   onSuccess: ({ token }: { token: string; }) => void;
// };

export const AuthAccessOptions = () => {
  const navigate = useNavigate();

  const { classes, cx } = useStyles();
  const { setUserInfo, userInfo } = useUserInfoStore();

  const [signInWithGoogle] = useSignInWithGoogle(auth);
  const [signInWithApple] = useSignInWithApple(auth);
  const [signInWithFacebook] = useSignInWithFacebook(auth);
  const [user] = useAuthState(auth);
  const [redirecting, setRedirecting] = useState(false);
  const [canSignIn, setCanSignIn] = useState(false);
  const [timeLeft, setTimeLeft] = useState(3);
  const [loaderTimeLeft, setLoaderTimeLeft] = useState(60);
  const [signOut, loading, error] = useSignOut(auth);
  const [accessMethod, setAccessMethod] = useState('');

  const handleSignInWithGoogle = async () => {
    try {
      if (user) {
        await signOut();
      }
      setLoaderTimeLeft(60);
      setRedirecting(true);
      timer({
        time: 60000,
        setTimeLeft: setLoaderTimeLeft,
      });
      setAccessMethod('Google');
      await signInWithGoogle();
      setCanSignIn(true);
    } catch (error) {
    }
  };

  const handleSignInWithApple = async () => {
    try {
      if (user) {
        await signOut();
      }
      setLoaderTimeLeft(60);
      setRedirecting(true);
      timer({
        time: 60000,
        setTimeLeft: setLoaderTimeLeft,
      });
      setAccessMethod('Apple');
      await signInWithApple();
      setCanSignIn(true);
    } catch (error) {
    }
  };

  const handleSignInWithFacebook = async () => {
    try {
      if (user) {
        await signOut();
      }
      setLoaderTimeLeft(60);
      setRedirecting(true);
      timer({
        time: 60000,
        setTimeLeft: setLoaderTimeLeft,
      });
      setAccessMethod('Facebook');
      await signInWithFacebook();
      setCanSignIn(true);
    } catch (error) {
    }
  }

  const handleSignInWithPhone = async () => {
    try {
      if (user) {
        await signOut();
      }
      navigate('/auth/signup/phone');
    } catch (error) {
    }
  };

  if (loaderTimeLeft < 1 && redirecting) setRedirecting(false);


  useGetUser({
    user: user,
    config: {
      enabled: !!user && canSignIn,
      retry: false,
      onSuccess: (data) => {
        setRedirecting(true);
        if (data) {
          timer({
            time: 3000,
            setTimeLeft,
          });
          setTimeout(() => {
            setUserInfo(data);
          }, 4000);
          setTimeout(() => {
            setRedirecting(false);
            navigate('/auth/success', {
              state: {
                from: 'login',
              },
            });
          }, 4000);
        }
      },
      onError: (e: any) => {
        console.log('in error')
        if (e && e.response.data.message == 'user not found') {
          timer({
            time: 3000,
            setTimeLeft,
          });
          setTimeout(() => {
            setRedirecting(false);
            navigate('/auth/signup/details', {
              state: {
                method: accessMethod,
              }
            });
          }, 4000);
        }
        else {
            setRedirecting(false);
        }
      }
    },
  });

  return (
    <HeadlessNavigation>
      <DefaultLoadingOverlay loading={redirecting && loaderTimeLeft > 1} text={timeLeft > 0 ? `Logging in... Redirecting in ${timeLeft}` : 'Redirecting...'} />
      <DefaultLoadingOverlay loading={loaderTimeLeft < 2 && loaderTimeLeft > 0} text={'Timeout... Please try again...'} />
      <Stack p="xl" spacing="md">
        <Title order={3}>Welcome</Title>
        {/* <Title order={3}>Sign up</Title> */}
        <Text fz={18}>Get recommended dating events to create your perfect love experience</Text>
      </Stack>
      <Stack p="xl" spacing="md" sx={{ flexGrow: 1 }}>
        <ActionButton content="Log in with Phone Number" img={phone} imgAlt="phone" onClick={handleSignInWithPhone} fullWidth />
        <ActionButton content="Log in with Google" img={google} imgAlt="google" onClick={handleSignInWithGoogle} fullWidth />
        <ActionButton content="Log in with Apple" img={apple} imgAlt="google" onClick={handleSignInWithApple} fullWidth backgroundColor='#000000' color='#fff' />
        <ActionButton content="Log in with Facebook" img={facebook} imgAlt="google" onClick={handleSignInWithFacebook} fullWidth backgroundColor='#1977F3' color='#fff' />
        <Text
          pt='xl'
          align='center'
          color='primary.3'
          component={Link}
          to='/privacy-policy'
          variant='link'
          sx={{ textDecoration: 'underline' }}
          size='xs'
        >
          Privacy Policy
        </Text>
      </Stack>
    </HeadlessNavigation>
  );
};
