import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { User } from '../types';

export type VerifySMSVerificationProps = {
  verificationCode: string;
  confirmationResult: any;
};

export const verifySMSVerificationCode = async ({
  verificationCode,
  confirmationResult,
}: VerifySMSVerificationProps) => {
  try {
    return await confirmationResult.confirm(verificationCode);
  } catch (e) {
    throw e;
  }
};

type QueryFnType = typeof verifySMSVerificationCode;

type UseVerifySMSVerificationCode = {
  verificationCode: string;
  confirmationResult: any;
  config?: QueryConfig<QueryFnType>;
};

export const useVerifySMSVerificationCode = ({
  config,
  verificationCode,
  confirmationResult,
}: UseVerifySMSVerificationCode) => {
  try {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: ['verificationCode', verificationCode],
      queryFn: () => verifySMSVerificationCode({ verificationCode, confirmationResult }),
    });
  } catch (e) {
    return;
  }
};
