import { createStyles, Flex } from '@mantine/core';
type HorizontalScrollContainerProps = {
  style?: React.CSSProperties;
  children: React.ReactNode;
  snap?: boolean;
  center?: boolean;
};

const useStyles = createStyles((theme) => ({
  container: {
    gap: theme.spacing.md,
    padding: theme.spacing.md,
    paddingTop: 0,
    overflowX: 'auto',
    flexWrap: 'nowrap',
    WebkitOverflowScrolling: 'touch',
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    '::MsOverflowStyle': 'none',
  },
}));

export const HorizontalScrollContainer = ({ style, children, snap, center }: HorizontalScrollContainerProps) => {
  const { classes, cx } = useStyles();

  return <Flex className={cx(classes.container)} style={style} sx={{
    scrollSnapType: snap ? 'x mandatory' : undefined,
    justifyContent: center ? 'center' : undefined,
  }}>{children}</Flex>;
};
