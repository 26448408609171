import { createStyles } from "@mantine/styles";
import { Avatar, Box, Image } from "@mantine/core";
import AccountCoverImage from '@/assets/Account/account-cover.svg';


type AccountCoverProps = {
  img?: string;
};

const useStyles = createStyles((theme) => ({
  cover: {
    height: 150,
    width: '100%',
    padding: 0,
    backgroundColor: '#FFF4EF',
    position: 'relative'
  },
  coverImage: {
    '.mantine-Image-imageWrapper': {
      width: 250,
      margin: 'auto'
    }
  },
  avatar: {
    position: 'absolute',
    bottom: -36,
    left: 'calc(50% - 36px)',
    width: 72,
    height: 72,
    boxShadow: '0px 0px 4px 2px rgba(0, 0, 0, 0.08);',
  }

}));

export const AccountCover = ({ img }: AccountCoverProps) => {
  const { classes, cx } = useStyles();


  return (
    <Box className={cx(classes.cover)}>
      <Image
        className={classes.coverImage}
        src={AccountCoverImage}
        alt="Account Cover"
        height={150}
        width="100%"
      />
      <Avatar
        src={img}
        className={classes.avatar}
        radius={100}
      />
    </Box>
  );
};
