import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Payment } from '../types';

type createPaymentProps = {
  bookingId: string;
  user?: any;
};

export const createPayment = async ({ bookingId, user }: createPaymentProps) => {
  if (user) {
    const idToken = await user?.getIdToken();
    const data = await axios.post<Payment>(
      `/bookings/payment`,
      {
        booking_id: bookingId,
      },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    return data;
  }
};

type QueryFnType = typeof createPayment;

type UsePaymentOptions = {
  bookingId: string;
  user?: any;
  config?: QueryConfig<QueryFnType>;
};

export const usePayment = ({ bookingId, config, user }: UsePaymentOptions) => {
  try {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: ['bookingId', bookingId],
      queryFn: () => {
        return createPayment({ bookingId, user });
      },
    });
  } catch (e) {
    return;
  }
};
