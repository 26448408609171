import { useState } from "react";
import { DefaultLoadingOverlay } from "@/components/DefaultLoadingOverlay";
import { Head } from "@/components/Head";
import { Solt } from "@/features/events";
import { Flex, UnstyledButton, Stack, Title, Box, Text, Input, createStyles, TextInput, Button, LoadingOverlay, NumberInput, Popover } from "@mantine/core";
import { IconArrowLeft, IconCalendar } from "@tabler/icons-react";
import { useEffect } from "react";
import { Navigate, redirect, useLocation, useNavigate } from 'react-router-dom';
import { CheckoutFooter } from "../components/CheckoutFooter";
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useBooking } from "../../bookings/api/createBooking";
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from "@/lib/firebase";
import { usePayment } from "../api/createPayment";
import { HeadlessNavigation } from "@/components/HeadlessNavigation";
import { useBookingStore } from "@/stores/booking";
import { useUserInfoStore } from "@/stores/authentication";
import { DatePicker } from "@mantine/dates";
import dayjs from "dayjs";

const useStyles = createStyles((theme) => ({
  input: {
    marginTop: theme.spacing.md,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: theme.spacing.xs,
    '.mantine-Input-input': {
      borderBottom: '1px solid #E5E5E5',
    },
  },
  calendar: {
    padding: theme.spacing.md,
    minHeight: 350
  },
  day: {
    '&[data-selected]': {
      backgroundColor: theme.colors.primary[3],
      '&:hover': {
        backgroundColor: theme.colors.primary[3],
        color: theme.white,
      },
    }
  },
  calendarHeader: {
    maxWidth: '100% !important'
  },
  calendarMonthLevel: {
    flexGrow: 1
  },
  calendarYearLevel: {
    flexGrow: 1,
  },
  calendarDecadeLevel: {
    flexGrow: 1,
  },
  calendarMonth: {
    width: '100% !important',
  },
  calendarMonthsList: {
    justifyContent: 'center',
    margin: 'auto',
    width: '100% !important',
  },
  calendarYearList: {
    justifyContent: 'center',
    margin: 'auto',
    width: '100% !important',
  },
  calendarYearLevelGroup: {
    justifyContent: 'center',
  },
  calendarDecadeLevelGroup: {
    justifyContent: 'center',
  },
  calendarPickerControl: {
    margin: 'auto'
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
    height: '100vh',
  }
})
);

export const Checkout = () => {
  const { classes, cx } = useStyles();

  const navigate = useNavigate();
  const { register, control, setValue, handleSubmit, formState: { errors } } = useForm();
  const [booking, setBooking] = useState<any>(null);
  const [bookingId, setBookingId] = useState<any>(null);
  const [isPaidEvent, setIsPaidEvent] = useState<any>(false);
  const [user] = useAuthState(auth);
  const { booking: bookingStore } = useBookingStore();
  const { userInfo } = useUserInfoStore();
  const [opened, setOpened] = useState<any>();

  const onSubmit = (data: any) => {

    if (!data['email-0']) data['email-0'] = userInfo?.Email;
    if (!data['firstName-0']) data['firstName-0'] = userInfo?.GivenName;
    if (!data['lastName-0']) data['lastName-0'] = userInfo?.FamilyName;
    if (!data['phoneNumber-0']) data['phoneNumber-0'] = userInfo?.MobileNumber;

    // group data into array of objects by attendee
    const attendees = Array.from({ length: quantity }, (_, index) => {
      return {
        first_name: data[`firstName-${index}`],
        last_name: data[`lastName-${index}`],
        phone_number: data[`phoneNumber-${index}`],
        email: data[`email-${index}`],
        birthdate: data[`birthdate-${index}`],
      };
    });
    setBooking({
      solt_id: selectedPackage.ID,
      attendees
    });
    console.log(booking)
  };

  useBooking({
    booking: booking,
    user,
    config: {
      enabled: !!booking && !!user,
      onSuccess: (data: any) => {
        if (data.success == true) {
          setBookingId(data.booking_id);
          setIsPaidEvent(data.is_paid_event)

          if (!data.is_paid_event) {
            navigate('/payment/success')
          }
        }
      }
    },
  });

  usePayment({
    bookingId,
    user,
    config: {
      enabled: !!bookingId && !!user && isPaidEvent,
      onSuccess: (data: any) => {
        if (data.success == true) {
          window.open(data.redirect_to, '_self');
        }
      }
    }
  });

  const { selectedPackage, selectedDate, selectedTime, quantity, isSinglesEvent }: {
    selectedPackage: Solt,
    selectedDate: string,
    selectedTime: string,
    quantity: number,
    isSinglesEvent: boolean;
  } = bookingStore;

  useEffect(() => {
    if (!(selectedPackage && selectedDate && selectedTime && quantity)) {
      setTimeout(() => {
        navigate('/');
      }, 2000);
    }
  }, [selectedDate, selectedTime, selectedPackage, quantity]);

  return (
    (selectedPackage && selectedDate && selectedTime && quantity) ?
      <HeadlessNavigation back={1} headTitle='Payment' title='Payment'>
        <form onSubmit={handleSubmit(onSubmit)} className={cx(classes.form)}>
          <Box>
            <Box component={Stack} spacing="xs" bg="gray.2" p="xl" m="md" sx={(theme) => ({
              borderRadius: theme.radius.md,
            })}>
              <Title order={3}>{selectedPackage.PackageName}</Title>
              <Text>
                <Text>{selectedDate}</Text>
                <Text>{selectedTime}</Text>
                <Text>{selectedPackage.Info}</Text>
                <Text>{quantity} x {selectedPackage.PackagePerUnit}</Text>
              </Text>
            </Box>
            <Box>
              <Title order={3} p='md' pb={0}>Contact Information</Title>
              <Box p="md" pt={0}>
                {
                  Array.from({ length: quantity }, (_, index) => {
                    return <Box pt="md" pb="md">
                      <Title order={4}>Attendee {index + 1}</Title>
                      <Controller
                        name={`firstName-${index}`}
                        control={control}
                        defaultValue={index === 0 ? userInfo?.GivenName : ''}
                        rules={{ required: 'Field required' }}
                        render={({ field }) => (
                          <TextInput
                            id={index.toString()}
                            label="First Name"
                            className={classes.input}
                            variant="unstyled"
                            withAsterisk
                            disabled={index === 0 && userInfo?.GivenName}
                            {...field}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={'firstName-' + index}
                        render={({ message }) => <Text color="red" size="sm">{message}</Text>}
                      />
                      <Controller
                        name={`lastName-${index}`}
                        control={control}
                        defaultValue={index === 0 ? userInfo?.FamilyName : ''}
                        rules={{
                          required: 'Last Name required'
                        }}
                        render={({ field }) => (
                          <TextInput
                            id={index.toString()}
                            label="Last Name"
                            className={classes.input}
                            variant="unstyled"
                            withAsterisk
                            disabled={index === 0 && !!userInfo?.FamilyName}
                            {...field}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`lastName-${index}`}
                        render={({ message }) => <Text color="red" size="sm">{message}</Text>}
                      />
                      <Controller
                        name={`phoneNumber-${index}`}
                        control={control}
                        defaultValue={index === 0 ? userInfo?.MobileNumber : ''}
                        rules={{
                          // required: 'Phone Number required'
                        }}
                        render={({ field }) => (
                          <TextInput
                            id={index.toString()}
                            label="Phone Number"
                            className={classes.input}
                            variant="unstyled"
                            // withAsterisk
                            type="number"
                            disabled={index === 0 && !!userInfo?.MobileNumber}
                            {...field}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`phoneNumber-${index}`}
                        render={({ message }) => <Text color="red" size="sm">{message}</Text>}
                      />
                      <Controller
                        name={`email-${index}`}
                        control={control}
                        defaultValue={index === 0 ? userInfo?.Email : ''}
                        rules={{
                          // required: 'Email required',
                          pattern: {
                            value: /^\S+@\S+$/,
                            message: 'Invalid email',
                          },
                        }}
                        render={({ field }) => (
                          <TextInput
                            id={index.toString()}
                            label="Email"
                            className={classes.input}
                            variant="unstyled"
                            // withAsterisk
                            disabled={index === 0 && !!userInfo?.Email}
                            {...field}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`email-${index}`}
                        render={({ message }) => <Text color="red" size="sm">{message}</Text>}
                      />
                      {
                        isSinglesEvent &&
                        <>
                          <Controller
                            name={`birthdate-${index}`}
                            control={control}
                            rules={{ required: 'Birthdate required' }}
                            render={({ field }) => (
                              <Popover width='100%' position="bottom-start" withArrow shadow="md" opened={opened && !!opened[index] ? opened[index] : false}>
                                <Popover.Target>
                                  <TextInput
                                    id={index.toString()}
                                    label="Birthdate"
                                    className={classes.input}
                                    variant="unstyled"
                                    withAsterisk
                                    disabled={index === 0 && userInfo?.Birthdate}
                                    {...field}
                                    onClick={() => setOpened({
                                      ...opened,
                                      [index]: opened && !!opened[index] ? !opened[index] : true,
                                    })}
                                  />
                                </Popover.Target>
                                <Popover.Dropdown>
                                  <DatePicker
                                    value={dayjs(field.value).toDate()}
                                    onChange={(v) => {
                                      setValue(`birthdate-${index}`, dayjs(v).format('YYYY-MM-DD'));
                                      setOpened({
                                        ...opened,
                                        [index]: false,
                                      });
                                    }}
                                    classNames={{
                                      day: cx(classes.day),
                                      calendar: cx(classes.calendar),
                                      calendarHeader: cx(classes.calendarHeader),
                                      monthLevel: cx(classes.calendarMonthLevel),
                                      month: cx(classes.calendarMonth),
                                      monthsList: cx(classes.calendarMonthsList),
                                      yearsList: cx(classes.calendarYearList),
                                      yearLevelGroup: cx(classes.calendarYearLevelGroup),
                                      decadeLevelGroup: cx(classes.calendarDecadeLevelGroup),
                                      yearLevel: cx(classes.calendarYearLevel),
                                      decadeLevel: cx(classes.calendarDecadeLevel),
                                      pickerControl: cx(classes.calendarPickerControl),
                                    }}
                                  />
                                </Popover.Dropdown>
                              </Popover>
                            )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name={`birthdate-${index}`}
                            render={({ message }) => <Text color="red" size="sm">{message}</Text>}
                          />
                        </>
                      }
                    </Box>;
                  })
                }
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              position: 'sticky',
              bottom: 0,
            }}
          >
            <CheckoutFooter
              currency={selectedPackage.PackageCurrency}
              price={selectedPackage.PackagePrice}
              quantity={quantity}
            />
          </Box>
        </form>
      </HeadlessNavigation>
      : <DefaultLoadingOverlay loading={true} text={'No booking found, redirecting...'} />
  );
};