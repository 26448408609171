import { createStyles, Footer, Text, Flex, Grid, UnstyledButton } from '@mantine/core';

type BaseButtonProps = {
  id?: string;
  onClick?: () => void;
  content: string;
  img?: string;
  imgAlt?: string;
  light?: boolean;
  fullWidth?: boolean;
  fontSize?: number;
  fontWeight?: number;
  padding?: string;
  style?: React.CSSProperties;
  type?: "button" | "submit" | "reset" | undefined;
  disabled?: boolean;
};

const useStyles = createStyles((theme) => ({
  baseButton: {
    display: 'flex',
    borderRadius: 8,
    transition: '0.3s',
  },
  disabled: {
    backgroundColor: `${theme.colors.gray[5]} !important`,
    pointerEvents: 'all',
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: theme.colors.gray[5],
    },
  },
}));

export const BaseButton = ({ id, onClick, content, img, imgAlt, light, fullWidth, fontSize, fontWeight, padding, style, type, disabled }: BaseButtonProps) => {
  const { classes, cx } = useStyles();
  return (
    <UnstyledButton
      id={id}
      type={type}
      className={cx(classes.baseButton, `${disabled ? classes.disabled : ''}`)}
      disabled={disabled}
      onClick={() => onClick && onClick()}
      sx={(theme) => {
        return {
          backgroundColor: light ? '#ffffff' : theme.colors.primary[3],
          color: light ? theme.colors.primary[3] : '#ffffff',
          padding: padding || '13.75px 20px',
          width: fullWidth ? '100%' : 'auto',
          border: light ? `1px solid ${theme.colors.primary[3]}` : '',
          '&:hover': {
            backgroundColor: theme.colors.primary[2],
          },
        };
      }}
      style={style}
    >
      {img ? <img src={img} alt={imgAlt} /> : null}
      <Text fw={fontWeight || 700} align="center" fz={fontSize ? fontSize : 'md'} sx={(theme) => {
        return {
          flexGrow: 1,
          // '@media (max-width: 425px)': {
          //   fontSize: theme.fontSizes.md,
          // },
        };
      }}>
        {content}
      </Text>
    </UnstyledButton>
  );
};
