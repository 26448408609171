import { Navigate, Route, Routes } from 'react-router-dom';
import { Auth } from './AuthLandingRedirect';
import { AuthSinglePage } from './AuthSinglePage';
import { AuthSuccess } from './AuthSuccess';
import { Journey } from './AuthJourney';
import { AuthAccessOptions } from './AuthAccessOptions';
import { SignUpDetails } from './SignUpDetails';
import { DEPLOY_STAGE } from '@/config';
import { AuthPhone } from './AuthPhone';
import { AuthPhoneVerification } from './AuthPhoneVerification';
import { useRouteStore } from '@/stores/route';

type AuthRoutesProps = {
  authenticated?: boolean;
}

export const AuthRoutes = ({ authenticated }: AuthRoutesProps) => {
  const { prevRoute } = useRouteStore();
  return (
    <Routes>
      <Route path="/" element={authenticated ? <Navigate to={'/'} /> : <Auth />} />
      <Route path="signup" element={authenticated ? <Navigate to={'/'} /> : <AuthAccessOptions />} />
      <Route path="signup/phone" element={authenticated ? <Navigate to={'/'} /> : <AuthPhone />} />
      <Route path="signup/phone/verify" element={authenticated ? <Navigate to={'/'} /> : <AuthPhoneVerification />} />
      <Route path="signup/details" element={authenticated ? <Navigate to={'/'} /> : <SignUpDetails />} />
      <Route path="login" element={authenticated ? <Navigate to={'/'} /> : <AuthAccessOptions />} />
      <Route path="journey" element={authenticated ? <Journey /> : <Navigate to={'/'} />} />
      <Route path="success" element={authenticated ? <AuthSuccess /> : <Navigate to={prevRoute && prevRoute !== '/auth/success' ? prevRoute : '/'} />} />
    </Routes>
  );
};


