import { useIdToken, useAuthState } from 'react-firebase-hooks/auth';
import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { User } from '../types';

export type NewUserProps = {
  id?: string;
  user?: any;
  givenName?: string;
  familyName?: string;
  email?: string;
  phoneNumber?: string;
  sex?: string;
};

export const newUser = async ({
  id,
  user,
  givenName,
  familyName,
  email,
  phoneNumber,
  sex,
}: NewUserProps) => {
  try {
    if (user) {
      const idToken = await user?.getIdToken();
      const { data } = await axios.post<User>(
        `/user/`,
        {
          id,
          'given_name': givenName,
          'family_name': familyName,
          email,
          'mobile_number': phoneNumber,
          sex,
        },
        {
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        }
      );
      return data;
    }
  } catch (e) {
    throw e;
  }
};

type QueryFnType = typeof newUser;

type UseUserOptions = {
  id?: string;
  user?: any;
  givenName?: string;
  familyName?: string;
  email?: string;
  phoneNumber?: string;
  sex?: string;
  config?: QueryConfig<QueryFnType>;
};

export const useNewUser = ({
  config,
  id,
  user,
  givenName,
  familyName,
  email,
  phoneNumber,
  sex,
}: UseUserOptions) => {
  try {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [],
      queryFn: () => newUser({ user, id, givenName, familyName, email, phoneNumber, sex }),
    });
  } catch (e) {
    return;
  }
};
