import { createStyles, Footer, Text, Flex, Grid, UnstyledButton } from '@mantine/core';

type ActionButtonProps = {
  onClick: () => void;
  content: string;
  img?: string;
  imgAlt?: string;
  dark?: boolean;
  fullWidth?: boolean;
  fontSize?: number;
  fontWeight?: number;
  padding?: string;
  type?: "button" | "submit" | "reset" | undefined;
  color?: string;
  backgroundColor?: string;
};

const useStyles = createStyles((theme) => ({
  actionButton: {
    display: 'flex',
    borderRadius: 8,
    border: `1px solid ${theme.colors.dark[9]}`,
    transition: '0.3s',
  },
}));

export const ActionButton = ({ onClick, content, img, imgAlt, dark, fullWidth, fontSize, fontWeight, padding, type, color, backgroundColor }: ActionButtonProps) => {
  const { classes, cx } = useStyles();

  return (
    <UnstyledButton type={type} className={cx(classes.actionButton)} onClick={() => onClick()} sx={(theme) => {
      return {
        backgroundColor: dark ? theme.colors.dark[9] : backgroundColor || 'white',
        color: dark ? 'white' : color || theme.colors.dark[9],
        padding: padding || '12px 20px',
        width: fullWidth ? '100%' : 'auto',
        '&:hover': {
          opacity: 0.8,
          transition: '0.2s',
          backgroundColor: dark ? theme.colors.primary[2] : backgroundColor || theme.colors.primary[2],
          color: dark ? theme.colors.dark[9] : color || '',
        },
      };
    }}>
      {img ? <img src={img} alt={imgAlt} /> : null}
      <Text fw={fontWeight || 700} align="center" sx={(theme) => {
        return {
          flexGrow: 1,
          fontSize: theme.fontSizes.md,
        };
      }}>
        {content}
      </Text>
    </UnstyledButton>
  );
};
