import { Card, createStyles, Text } from '@mantine/core';

import { Solt } from '../types';

type SlotPackageTypeCardProps = {
  id: string;
  selected?: boolean;
  slot?: Solt;
  handleSelect: (slot?: Solt) => void;
};

const useStyles = createStyles((theme) => ({
  card: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: '100%',
    transition: '0.3s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.primary[1],
      color: theme.colors.primary[3],
    },
  },
}));

export const SlotPackageTypeCard = ({
  id,
  selected,
  slot,
  handleSelect,
}: SlotPackageTypeCardProps) => {
  const { classes, cx } = useStyles();

  return (
    <Card
      id={id}
      className={cx(classes.card)}
      shadow="sm"
      p="md"
      onClick={() => handleSelect(slot)}
      radius="md"
      withBorder
      sx={(theme) => ({
        backgroundColor: selected ? theme.colors.primary[1] : 'transparent',
        color: selected ? theme.colors.primary[3] : 'inherit',
      })}
    >
      <Text size={16} fw="bold">
        {slot?.PackageName}
      </Text>
      <Text size={14} pb={8}>
        {slot?.Info}
      </Text>
      <Text size={14} pt="sm" fw={500}>
        {slot?.PackageCurrency} {slot?.PackagePrice}
      </Text>
    </Card>
  );
};
