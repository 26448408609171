import { useIdToken, useAuthState } from 'react-firebase-hooks/auth';
import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { User } from '../types';

type getUserProps = {
  user: any;
};

export const getUser = async ({ user }: getUserProps) => {
  if (user) {
    const idToken = await user?.getIdToken();
    const { data } = await axios.get<User>(`/user/`, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });
    return data;
  }
};

type QueryFnType = typeof getUser;

type UseGetUserOptions = {
  user?: any;
  config?: QueryConfig<QueryFnType>;
};

export const useGetUser = ({ config, user }: UseGetUserOptions) => {
  try {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      // queryKey: ['user', user],
      queryFn: () => getUser({ user }),
    });
  } catch (e) {
    return;
  }
};
