import { Text, Stack, Flex, UnstyledButton, createStyles, Image } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { useLocation, useNavigate } from 'react-router-dom';


import { Head } from '@/components/Head';
import { CheckoutSuccessFooter } from '../components/CheckoutSuccessFooter';
import { useEffect } from 'react';
import { DefaultLoadingOverlay } from '@/components/DefaultLoadingOverlay';
import bookingConfirmed from '@/assets/Event/booking-confirmed.svg';
import { HeadlessNavigation } from '@/components/HeadlessNavigation';
import { useBookingStore } from '@/stores/booking';

const useStyles = createStyles((theme) => ({
}));

export const CheckoutSuccess = () => {
  const { classes, cx } = useStyles();
  const { clearBooking } = useBookingStore();

  useEffect(() => {
    clearBooking();
  }, [])

  return (
    <>
      <HeadlessNavigation>
        <Stack p="xl" spacing="xs">
          <Image
            src={bookingConfirmed}
            withPlaceholder
            height={250}
            radius='md'
            alt='Events'
            m="auto"
            mt="md"
            mb="md"
            width={250}
            pt={0}
            imageProps={{
              style: {
                height: 250,
                objectFit: 'contain',
                objectPosition: 'center',
              }
            }}
          />
          <Text
            fw={700}
            fz={32}
            mt={0}
            mb={0}
            align='center'
          >
            Booking Confirmed!
          </Text>
          <Text
            m='auto'
            mt={0}
            mb='md'
            align='center'
            fz={18}
            w='100%'
          >
            Wish you a wonderful date!
          </Text>
          {/* <Text
              m='auto'
              mt='sm'
              mb='md'
              align='center'
              color='gray.7'
              fz={16}
              w='100%'
            >
            Add to Google Calender
            </Text> */}

        </Stack>
      </HeadlessNavigation>
      <CheckoutSuccessFooter />
    </>
  );
};
