import { BaseButton } from '@/components/BaseButton';
import { createStyles, useMantineTheme, Box } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

type DateFilterProps = {
  filter: string;
  handleApply: (v: string) => void;
  resetSelectedFilter: boolean;
  setResetSelectedFilter: (v: boolean) => void;
};

const useStyle = createStyles((theme) => ({
  calendar: {
    padding: theme.spacing.md,
    minHeight: 350
  },
  calendarHeader: {
    maxWidth: '100% !important'
  },
  calendarMonthLevel: {
    flexGrow: 1
  },
  calendarYearLevel: {
    flexGrow: 1,
  },
  calendarDecadeLevel: {
    flexGrow: 1,
  },
  calendarMonth: {
    width: '100% !important',
  },
  calendarMonthsList: {
    justifyContent: 'center',
    margin: 'auto',
    width: '100% !important',
  },
  calendarYearList: {
    justifyContent: 'center',
    margin: 'auto',
    width: '100% !important',
  },
  calendarYearLevelGroup: {
    justifyContent: 'center',
  },
  calendarDecadeLevelGroup: {
    justifyContent: 'center',
  },
  calendarPickerControl: {
    margin: 'auto'
  }

}));


export const DateFilter = ({ filter, handleApply, resetSelectedFilter, setResetSelectedFilter }: DateFilterProps) => {
  const { classes, cx } = useStyle();
  const theme = useMantineTheme();
  const [selectedDate, setSelectedDate] = useState<string>(filter);

  const handleSelectedDate = (date: Date) => {
    setSelectedDate(dayjs(date).format('YYYY-MM-DD'));
  };

  useEffect(() => {
    if (resetSelectedFilter) setSelectedDate('');
    setResetSelectedFilter(false);
  }, [resetSelectedFilter]);

  return (
    <>
      <DatePicker
        firstDayOfWeek={0}
        value={dayjs(selectedDate).toDate()}
        onChange={handleSelectedDate}
        classNames={{
          calendar: cx(classes.calendar),
          calendarHeader: cx(classes.calendarHeader),
          monthLevel: cx(classes.calendarMonthLevel),
          month: cx(classes.calendarMonth),
          monthsList: cx(classes.calendarMonthsList),
          yearsList: cx(classes.calendarYearList),
          yearLevelGroup: cx(classes.calendarYearLevelGroup),
          decadeLevelGroup: cx(classes.calendarDecadeLevelGroup),
          yearLevel: cx(classes.calendarYearLevel),
          decadeLevel: cx(classes.calendarDecadeLevel),
          pickerControl: cx(classes.calendarPickerControl),
        }}
        getDayProps={(date) => {
          if (dayjs(selectedDate).isSame(dayjs(date))) {
            return {
              sx: (theme) => ({
                backgroundColor: `${theme.colors.primary[3]} !important`,
              })
            };
          }
          return {};
        }}
        getMonthControlProps={(date) => {
          if (dayjs(selectedDate).month() === dayjs(date).month()) {
            return {
              sx: (theme) => ({
                color: `${theme.white} !important`,
                backgroundColor: `${theme.colors.primary[3]} !important`,
              })
            };
          }
          return {};
        }}
        getYearControlProps={(date) => {
          if (dayjs(selectedDate).year() === dayjs(date).year()) {
            return {
              sx: (theme) => ({
                color: `${theme.white} !important`,
                backgroundColor: `${theme.colors.primary[3]} !important`,
              })
            };
          }
          return {};
        }}
        excludeDate={(date) => {
          return (dayjs(date).isBefore(dayjs(), 'day')) ? true : false;
        }}
      />
      <Box px='md'>
        <BaseButton
          fullWidth
          onClick={() => {
            handleApply(selectedDate);
          }}
          content={'Apply'}
        />
      </Box>
    </>
  );
};
