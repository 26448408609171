import { Head } from '@/components/Head';
import { MainLayout } from '@/components/Layout';
import { Box } from '@mantine/core';

export const PrivacyPolicy = () => {
  // const navigate = useNavigate();

  const privacyPolicy = `<h1>Privacy Policy</h1>
  <p><em>Last updated: May 10, 2023</em></p>

  <h2>1. Introduction</h2>
  <p>Lovelab Limited ("we", "us", or "our") operates the OMD platform (the "Service"). This Privacy Policy explains how we collect, use, disclose, and protect your personal information when you use our Service. By using the Service, you agree to the collection and use of your personal information in accordance with this Privacy Policy.</p>

  <h2>2. Information We Collect</h2>
  <p>When you use our Service, we may collect the following types of personal information:</p>
  <ul>
    <li>Personal Identifiers: Name, email address, phone number, and relationship status.</li>
    <li>Demographic Information: Gender.</li>
    <li>Payment Information: Details necessary to process payments via Stripe.</li>
  </ul>

  <h2>3. How We Use Your Information</h2>
  <p>We use your personal information for various purposes, including but not limited to:</p>
  <ul>
    <li>Providing and maintaining our Service.</li>
    <li>Personalizing your experience and suggesting dating plans based on your interests.</li>
    <li>Communicating with you about our Service, updates, and promotions.</li>
    <li>Organizing events and sharing necessary information with third parties involved in organizing the events you register for.</li>
    <li>Processing payments for events and activities booked through our Service.</li>
    <li>Complying with applicable laws and regulations.</li>
  </ul>

  <h2>4. Disclosure of Your Information</h2>
  <p>We may share your personal information with third parties in the following circumstances:</p>
  <ul>
    <li>With event organizers and other service providers necessary to organize the events you register for.</li>
    <li>With our payment processor, Stripe, to process payments for events and activities booked through our Service.</li>
    <li>As required by law or in response to legal process, such as a subpoena or court order.</li>
    <li>In connection with a merger, acquisition, or sale of all or substantially all of our assets.</li>
  </ul>

  <h2>5. Data Security</h2>
  <p>We take the security of your personal information seriously and implement reasonable security measures to protect it from unauthorized access, alteration, disclosure, or destruction. However, we cannot guarantee the absolute security of your information, and by using our Service, you acknowledge and accept the inherent risks associated with transmitting information over the internet.</p>

  <h2>6. Third-Party Services and Websites</h2>
  <p>Our Service may contain links to third-party websites and services. We are not responsible for the privacy practices or content of these third parties. We encourage you to review the privacy policies of any third-party websites or services you interact with.</p>

  <h2>7. Changes to This Privacy Policy</h2>
  <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

  <h2>8. Contact Us</h2>
  <p>If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
  <p>Lovelab Limited<br>
  <a href="mailto:info@ohmydatehk.com">info@ohmydatehk.com</a><br>
  <p>We are committed to addressing any concerns you may have about our privacy practices and the use of your personal information.</p>`;

  return (
    <>
      <Head title="OMD" headDescription="Date Planner" />
      <MainLayout>
        <Box
          p="lg"
          pt={0}
          sx={(theme) => {
            return {
              '& h1': {
                color: theme.colors.primary[3],
              },
              '& h2': {
                color: theme.colors.primary[3],
              },
              '& p': {
              },
              '& ul': {
              },
              '& li': {
              },
              '& a': {
                color: theme.colors.primary[3],
              }
            };
          }}
          dangerouslySetInnerHTML={{
            __html: privacyPolicy
          }}>

        </Box>
      </MainLayout>
    </>
  );
};
