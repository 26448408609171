import { createStyles, Footer, Text, Flex, Grid } from '@mantine/core';
import { Link } from 'react-router-dom';

import instagram from '@/assets/Brand/instagram.svg';
import linkedin from '@/assets/Brand/linkedin.svg';
type Link = {
  icon: string;
  alt: string;
  href: string;
};

const useStyles = createStyles((theme) => ({
  linkIcon: {
    width: 25,
  },
  footer: {
    padding: 30,
  },
  externalLink: {
    padding: 9,
  },
  sitemapLink: {
    textAlign: 'center',
    color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  copyright: {
    textAlign: 'center',
    paddingTop: 8,
  },
}));

export const DefaultFooter = () => {
  const { classes, cx } = useStyles();
  const links: Link[] = [
    {
      icon: instagram,
      alt: 'Instagram',
      href: 'https://www.instagram.com/evol.hongkong/',
    },
    {
      icon: linkedin,
      alt: 'LinkedIn',
      href: 'https://www.linkedin.com/company/evol-app/',
    },
  ];

  return (
    <Footer height={250} mah={300} className={cx(classes.footer)}>
      <Flex mih='100%' align="center" justify="space-between" direction="column" rowGap={8}>
        <Text weight={500} size={16}>
          Contact Us
        </Text>
        <Grid className={cx(classes.externalLink)} gutter={'xs'}>
          {links.map(({ icon, alt, href }, i) => {
            return (
              <Grid.Col span={6} key={i}>
                <a href={href} target="_blank" >
                  <img src={icon} alt={alt} className={cx(classes.linkIcon)} />
                </a>
              </Grid.Col>
            );
          })}
        </Grid>
        <Link to="/privacy-policy">
          <Text className={cx(classes.sitemapLink)}>Privacy Policy</Text>
        </Link>
        {/* <Link to="/">
          <Text className={cx(classes.sitemapLink)}>Terms and Conditions</Text>
        </Link> */}
        <Text className={cx(classes.copyright)}>@ 2023 LOVELAB Limited. All Rights Reserved</Text>
      </Flex>
    </Footer>
  );
};
