import { useIdToken, useAuthState } from 'react-firebase-hooks/auth';
import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { EventsData, EventsTotal } from '../types';
import { Match } from '../types/user';

type getMatchesProps = {
  user?: any;
};

export type getMatchesResponse = Match[];

export const getMatches = async ({ user }: getMatchesProps) => {
  if (user) {
    const idToken = await user?.getIdToken();
    const { data } = await axios.get<getMatchesResponse>(`/user/match`, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      params: {},
    });
    return data;
  }
};

type QueryFnType = typeof getMatches;

type UseMatchesOptions = {
  user?: any;
  config?: QueryConfig<QueryFnType>;
};

export const useMatches = ({ user, config }: UseMatchesOptions) => {
  try {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [],
      queryFn: () => getMatches({ user }),
    });
  } catch (e) {
    console.log(e);
    return;
  }
};
