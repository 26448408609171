import { createRef } from 'react';
import { create } from 'zustand';
import { getSearchResponse } from '../features/search/api/getSearch';

interface SearchStore {
  searchInput: React.RefObject<HTMLInputElement>;
  searching: boolean;
  setSearching: (searching: boolean) => void;
  searchOpened: boolean;
  setSearchOpened: (searchOpened: boolean) => void;
  loveFactor: string;
  setLoveFactor: (loveFactor: string) => void;
  clearLoveFactor: () => void;
  match: string;
  setMatch: (match: string) => void;
  clearMatch: () => void;
  date: string;
  setDate: (date: string) => void;
  clearDate: () => void;
  location: string;
  setLocation: (location: string) => void;
  clearLocation: () => void;
  sort: string;
  setSort: (sort: string) => void;
  clearSort: () => void;
  tags: string;
  setTags: (tags: string) => void;
  clearTags: () => void;
  searchResults: getSearchResponse;
  setSearchResults: (searchResults: getSearchResponse) => void;
  clearSearchResults: () => void;
  limit: number;
  setLimit: (limit: number) => void;
  clearLimit: () => void;
  page: number;
  setPage: (skip: number) => void;
  clearPage: () => void;
}

export const useSearchStore = create<SearchStore>((set) => ({
  searchInput: createRef<HTMLInputElement>(),
  searching: false,
  setSearching: (searching: boolean) => set({ searching }),
  searchOpened: false,
  setSearchOpened: (searchOpened: boolean) => set({ searchOpened }),
  loveFactor: '',
  setLoveFactor: (loveFactor: string) => set({ loveFactor }),
  clearLoveFactor: () => set({ loveFactor: '' }),
  match: '',
  setMatch: (match: string) => set({ match }),
  clearMatch: () => set({ match: '' }),
  date: '',
  setDate: (date: string) => set({ date }),
  clearDate: () => set({ date: '' }),
  toDate: '',
  setToDate: (date: string) => set({ date }),
  clearToDate: () => set({ date: '' }),
  location: '',
  setLocation: (location: string) => set({ location }),
  clearLocation: () => set({ location: '' }),
  sort: '',
  setSort: (sort: string) => set({ sort }),
  clearSort: () => set({ sort: '' }),
  tags: '',
  setTags: (tags: string) => set({ tags }),
  clearTags: () => set({ tags: '' }),
  searchResults: {
    Total: 0,
    Page: 0,
    Events: [],
  },
  setSearchResults: (searchResults: getSearchResponse) => set({ searchResults }),
  clearSearchResults: () => set({ searchResults: { Total: 0, Page: 0, Events: [] } }),
  limit: 10,
  setLimit: (limit: number) => set({ limit }),
  clearLimit: () => set({ limit: 10 }),
  page: 1,
  setPage: (page: number) => set({ page }),
  clearPage: () => set({ page: 1 }),
}));
