import { Grid, Loader, Text } from '@mantine/core';

export const SummaryInfo = ({ icon, content }: { icon: string; content?: string | React.ReactNode; }) => {
  return (
    <>
      <Grid.Col span={1}>
        <img
          alt="icon"
          src={icon}
          style={{
            width: 16,
            height: 16,
            alignContent: 'center',
          }}
        />
      </Grid.Col>
      <Grid.Col span={11}>
        {content ? <Text>{content}</Text> : <Loader color="gray" variant="dots" />}
      </Grid.Col>
    </>
  );
};
