import { useIdToken, useAuthState } from 'react-firebase-hooks/auth';
import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { Booking } from '@/features/bookings';

type getBookingsProps = {
  bookingId?: string;
  user?: any;
};

export const getBookings = async ({ bookingId, user }: getBookingsProps) => {
  if (user) {
    const idToken = await user?.getIdToken();
    const data = await axios.get<Booking[]>(`/bookings/`, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
      // params: {
      //   booking_id: bookingId,
      // },
    });
    return {
      data: data.data,
    };
  }
};

type QueryFnType = typeof getBookings;

type UseBookingsOptions = {
  bookingId?: string;
  user?: any;
  config?: QueryConfig<QueryFnType>;
};

export const useBookings = ({ bookingId, config, user }: UseBookingsOptions) => {
  try {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: ['booking', bookingId],
      queryFn: () => getBookings({ bookingId, user }),
    });
  } catch (e) {
    console.log(e);
    return;
  }
};
