import { BaseButton } from "@/components/BaseButton";
import { DefaultLoadingOverlay } from "@/components/DefaultLoadingOverlay";
import { HeadlessNavigation } from "@/components/HeadlessNavigation";
import auth from "@/lib/firebase";
import { Flex, Stack, Image, Text, Box } from "@mantine/core";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from 'react-router-dom';
import { useBookings } from "../api/getBookings";
import { BookingCard } from '../components/BookingCard';
import noBookings from '@/assets/Booking/no-bookings.svg'
import { EventRecommendationsList } from "@/features/events/components/EventRecommendationsList";
import { DEPLOY_STAGE } from "@/config";

export const Bookings = () => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);


  const bookingsQuery = useBookings({
    user,
    config: {
      enabled: !!user,
    }
  });

  const bookings = bookingsQuery?.data;

  return (
    <>
      <DefaultLoadingOverlay loading={!bookings && !user} />
      <HeadlessNavigation back={1} title='Bookings' headTitle='Bookings' divider>
        <Flex direction='column'>
          {
            bookings?.data && bookings.data.length > 0 ? bookings?.data.map((booking, i) => {
              return (
                <BookingCard
                  key={i}
                  booking={booking}
                  onClick={() => {
                    navigate(`/bookings/${booking.booking_id}`)
                  }}
                />
              );
            }) :
              <Box px="md">
                <Stack pt='sm' spacing='0.5rem'>
                  <Image src={noBookings} alt='No bookings' width={106} m='auto' />
                  <Text m='auto' fz='xl'>No bookings yet.</Text>
                </Stack>
                {DEPLOY_STAGE === 'development' && <EventRecommendationsList />}
              </Box>

          }
        </Flex>
      </HeadlessNavigation>
    </>
  );
};