import { useSearchStore } from "@/stores/search";
import { Box, createStyles, Drawer, Flex, Transition, UnstyledButton, useMantineTheme, Text } from "@mantine/core";
import { IconX } from "@tabler/icons-react";
import { useEffect, useState } from 'react';
import { SearchFilters } from "./SearchFilters";
import { ReactComponent as LoveFactor } from '@/assets/Search/love-factor.svg';
import { ReactComponent as Match } from '@/assets/Search/match.svg';
import { ReactComponent as Date } from '@/assets/Search/date.svg';
import { ReactComponent as Location } from '@/assets/Search/location.svg';
import { ReactComponent as Sort } from '@/assets/Search/sort.svg';
import { SearchHasResults } from './SearchHasResults';
import { useSearch } from '@/features/search/api/getSearch';
import { SearchNoResults } from "./SearchNoResults";
import { LocationFilter } from "./SearchLocationFilter";
import { LoveFactorFilter } from "./SearchLoveFactorFilter";
import { SortFilter } from "./SearchSortFIlter.tsx";
import { DateFilter } from "./SearchDateFilter";
import { SearchData } from "../types";
import { MatchFilter } from "./SearchMatchFilter";
import { useAuthState } from "react-firebase-hooks/auth";
import auth from "@/lib/firebase";
import { useUserInfoStore } from "@/stores/authentication";
import { useSearchParams } from "react-router-dom";

type SearchResultsProps = {
};

export type FilterConfigsProps = {
  id: string;
  title: string;
  enabled: boolean;
  icon: JSX.Element;
  value: string;
  setValue: (value: string) => void;
  body: JSX.Element;
  drawerHeight?: number;
};

const useStyles = createStyles((theme) => ({
  icon: {
    alignSelf: 'center'
  },
  clear: {
    opacity: 0.5,
    transition: '0.3s',
    '&:hover': {
      color: theme.colors.primary[3],
    },
  },
  drawerInner: {
    margin: 'auto',
    marginBottom: 0,
    width: `${theme.breakpoints.xs}`,
    '@media (max-width: 540px)': {
      width: '100%',
    }
  },
  drawerContent: {
    borderRadius: '8px 8px 0 0',
  },
  drawerBody: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  drawerTitle: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 500,
    fontSize: 18,
    padding: 16,
    margin: 0,
  },
  drawerHeader: {
    borderBottom: '1px solid #F0F0F0',
    padding: 0,
  },
  drawerClose: {
    display: 'none',
  },
}));

export const SearchResults = ({ }) => {

  const { classes, cx } = useStyles();
  const theme = useMantineTheme();

  const [activeFilter, setActiveFilter] = useState('');
  const [resetSelectedFilter, setResetSelectedFilter] = useState(false);
  const [user] = useAuthState(auth)

  const { userInfo } = useUserInfoStore();

  const [searchParams] = useSearchParams(); 

  const fade = {
    in: { opacity: 1 },
    out: { opacity: 0 },
    transitionProperty: 'opacity',
  };

  const {
    loveFactor,
    match,
    date,
    location,
    sort,
    tags: tags,
    setLoveFactor,
    setMatch,
    setDate,
    setLocation,
    setSort,
    searchInput,
    searching,
    setSearching,
    searchResults,
    setSearchResults,
    clearSearchResults,
    limit,
    setLimit,
    clearLimit,
    clearTags,
    page,
    setPage,
    clearPage
  } = useSearchStore();

  const querySearch = useSearch({
    search: searchInput.current && searchInput.current.value || '',
    loveFactor,
    date,
    location,
    sort,
    tags,
    page,
    limit,
    match,
    config: {
      enabled: searching,
      onSuccess: (data) => {
        if (data.data) {
          let events: SearchData[] = [];
          // previous page results
          if (searchResults.Events.length > 0) events.push(...searchResults.Events);
          // new page results
          if (data.data.Events) events.push(...data.data.Events);

          setSearchResults({
            ...data.data,
            Events: events,
          });
          if (events.length < data.data.Total) setPage(data.data.Page + 1);
          setLimit(10);
          setSearching(false);
        }
      },
      onError: (error) => {
        console.error(error);
        clearSearchResults();
        setSearching(false);
      }
    }
  });


  useEffect(() => {
    setSearching(true);
    if (searchParams.get('tags') === null) {
      clearTags();
    }
  }, [])

  const handleCloseDrawer = () => {
    setActiveFilter('');
  };

  const handleResetAllFilters = () => {
    clearPage();
    clearLimit();
    clearSearchResults();
    filterConfigs.forEach((filter) => {
      filter.setValue('');
    });
    clearTags();
    setSearching(true);
  };

  const handleResetAll = () => {
    clearPage();
    clearSearchResults();
    filterConfigs.forEach((filter) => {
      filter.setValue('');
    });
    clearTags();
    if (searchInput.current) searchInput.current.value = '';
    setSearching(true);
  };

  const handleFetchResults = () => {
    setSearching(true);
  };

  const filterConfigs: FilterConfigsProps[] = [
    {
      id: 'love-factor',
      title: 'Love Factor',
      enabled: true,
      icon: <LoveFactor className={cx(classes.icon)} fill={loveFactor.length > 0 ? theme.colors.primary[3] : '#555555'}
      />,
      value: loveFactor,
      setValue: setLoveFactor,
      body:
        <LoveFactorFilter
          filter={loveFactor}
          handleApply={(v) => {
            setLoveFactor(v);
            setActiveFilter('');
            clearPage();
            clearLimit();
            clearSearchResults();
            setSearching(true);
          }}
          resetSelectedFilter={resetSelectedFilter}
          setResetSelectedFilter={setResetSelectedFilter}
        />,
    },
    {
      id: 'match',
      title: 'Match',
      enabled: !!user && !!userInfo ? true : false,
      icon: <Match className={cx(classes.icon)} fill={match.length > 0 ? theme.colors.primary[3] : '#555555'}
      />,
      value: match,
      setValue: setMatch,
      drawerHeight: 430,
      body:
        <MatchFilter
          filter={match}
          handleApply={(v) => {
            console.log(v)
            setMatch(v);
            setActiveFilter('');
            clearPage();
            clearLimit();
            clearSearchResults();
            setSearching(true);
          }}
          resetSelectedFilter={resetSelectedFilter}
          setResetSelectedFilter={setResetSelectedFilter}
          user={user}
        />,
    },
    {
      id: 'date',
      title: 'Date',
      enabled: true,
      icon: <Date className={cx(classes.icon)} fill={date ? theme.colors.primary[3] : '#555555'} />,
      value: date,
      setValue: setDate,
      drawerHeight: 480,
      body:
        <DateFilter
          filter={date}
          handleApply={(v) => {
            setDate(v);
            setActiveFilter('');
            clearPage();
            clearLimit();
            clearSearchResults();
            setSearching(true);
          }}
          resetSelectedFilter={resetSelectedFilter}
          setResetSelectedFilter={setResetSelectedFilter}
        />,
    },
    {
      id: 'location',
      title: 'Location',
      enabled: true,
      icon: <Location className={cx(classes.icon)} fill={location.length > 0 ? theme.colors.primary[3] : '#555555'} />,
      value: location,
      setValue: setLocation,
      body:
        <LocationFilter
          filter={location}
          handleApply={(v) => {
            setLocation(v);
            setActiveFilter('');
            clearPage();
            clearLimit();
            clearSearchResults();
            setSearching(true);
          }}
          resetSelectedFilter={resetSelectedFilter}
          setResetSelectedFilter={setResetSelectedFilter}
        />,
    },
    {
      id: 'sort',
      title: 'Sort',
      enabled: true,
      icon: <Sort className={cx(classes.icon)} fill={sort.length > 0 ? theme.colors.primary[3] : '#555555'} />,
      value: sort,
      setValue: setSort,
      drawerHeight: 355,
      body:
        <SortFilter
          filter={sort}
          handleApply={(v) => {
            setSort(v);
            setActiveFilter('');
            clearPage();
            clearLimit();
            clearSearchResults();
            setSearching(true);
          }}
          resetSelectedFilter={resetSelectedFilter}
          setResetSelectedFilter={setResetSelectedFilter}
        />,
    }
  ];

  return (
    <>
      <Box>
        {
          !tags && (
            <SearchFilters
              activeFilter={activeFilter}
              setActiveFilter={setActiveFilter}
              filterConfigs={filterConfigs}
            />
          )
        }
        <Transition
          mounted={
            searchResults.Events && searchResults.Events.length > 0
            || false
          }
          transition={fade}
          duration={150}
          timingFunction="ease"
        >
          {(styles) => (
            <div style={{ ...styles }}>
              <SearchHasResults
                resetAllFilters={handleResetAllFilters}
                results={searchResults}
                fetchResults={handleFetchResults}
              />
            </div>
          )}
        </Transition>
        <Transition
          mounted={
            searchResults.Events.length == 0 || false
          }
          transition={fade}
          duration={150}
          timingFunction="ease"
        >
          {(styles) => (
            <div style={{ ...styles }}>
              <SearchNoResults
                resetAll={handleResetAll}
              />
            </div>
          )}
        </Transition>
      </Box>
      {
        filterConfigs.map(filterConfig => (
          <Drawer
            key={filterConfig.id}
            overlayProps={{
              color: theme.colors.dark[9],
              opacity: 0.1,
              blur: 3,
            }}
            position="bottom"
            size={filterConfig.drawerHeight || 305}
            classNames={{
              inner: cx(classes.drawerInner),
              header: cx(classes.drawerHeader),
              title: cx(classes.drawerTitle),
              body: cx(classes.drawerBody),
              content: cx(classes.drawerContent),
              close: cx(classes.drawerClose),
            }}
            // shadow="md"
            closeOnClickOutside
            opened={filterConfig.id === activeFilter}
            onClose={handleCloseDrawer}
            title={
              <>
                <Flex w="100%" justify="space-between">
                  <UnstyledButton
                    w={25}
                    className={cx(classes.clear)}
                    onClick={handleCloseDrawer}
                  >
                    <IconX width={25} />
                  </UnstyledButton>
                  <Text fw='bolder'>
                    {filterConfig.title}
                  </Text>
                  <UnstyledButton
                    w={40}
                    className={cx(classes.clear)}
                    onClick={() => {
                      setResetSelectedFilter(!resetSelectedFilter);
                    }}
                  >
                    Reset
                  </UnstyledButton>
                </Flex>
              </>
            }
          >
            {filterConfig.body}
          </Drawer>
        ))
      }
    </>
  );
};
