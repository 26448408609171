import { BaseButton } from '@/components/BaseButton';
import { createStyles, Flex, Image, Stack, useMantineTheme, Text, Box, UnstyledButton, ActionIcon } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { ReactComponent as Share } from '@/assets/Event/share-unfilled.svg';

type EventShareProps = {
  title: string;
  description?: string;
  url: string;
};

const useStyle = createStyles((theme) => ({
  button: {
    border: '1px solid #555555',
    borderRadius: 50,
    height: 28,
    display: 'flex',
    justifyContent: 'space-between',
    gap: 2,
    padding: '0 12px',
  },
  icon: {
    alignSelf: 'center',
    maxWidth: 15,
    marginRight: 8,
  },

}));

export const EventShare = ({ title, description, url }: EventShareProps) => {
  const { classes, cx } = useStyle();
  const theme = useMantineTheme();
  const handleShare = () => {
    const shareData = {
      title,
      // text: description,
      url
    };

    if (navigator.share) {
      navigator.share(shareData);
    } else {
      // fallback
      console.log('fallback');
    }

  };

  return (
    <UnstyledButton
      className={cx(classes.button)}
      onClick={handleShare}
      sx={(theme) => ({
      })}
    >
      <Share className={cx(classes.icon)} />
      <Text fz={14} sx={{ alignSelf: 'center', whiteSpace: 'nowrap' }}>Share</Text>
    </UnstyledButton>
  );
};
