import { Navigate, Route, Routes } from 'react-router-dom';
import { Booking } from './Booking';
import { Bookings } from './Bookings';
import { useRouteStore } from '@/stores/route';

type BookingsRoutesProps = {
  authenticated?: boolean;
};

export const BookingsRoutes = ({ authenticated }: BookingsRoutesProps) => {
  const { prevRoute, setPrevRoute } = useRouteStore();
  return (
    <Routes>
      <Route path="/" element={<Bookings />} />
      <Route path=":bookingId" element={<Booking />} />
      <Route path="*" element={<Navigate to={prevRoute ? prevRoute : '/'} />} />
    </Routes>
  );
};
