import { DefaultLoadingOverlay } from "@/components/DefaultLoadingOverlay";
import { HeadlessNavigation } from "@/components/HeadlessNavigation";
import auth from "@/lib/firebase";
import { Flex, Stack, Image, Text } from "@mantine/core";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from 'react-router-dom';
import noSavedEvents from '@/assets/Event/no-saved-events.svg'
import { NoSavedEventsList } from "./EmptySavedEventsList";
import { useSavedEvents } from "@/features/events/api/getSavedEvents";
import { SavedEventsList } from "./SavedEventsList";

export const SavedEvents = () => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  const savedEventsQuery = useSavedEvents({
    user,
    config: {
      enabled: !!user,
    }
  });

  return (
    <>
      <DefaultLoadingOverlay loading={!user} />
      <HeadlessNavigation back={1} title='Saved Events' headTitle='Saved Events' divider>
        <Flex direction='column'>
          {
            (savedEventsQuery?.data && savedEventsQuery.data.length > 0) ?
            <SavedEventsList savedEvents={savedEventsQuery.data} /> :
            <NoSavedEventsList />
          }
        </Flex>
      </HeadlessNavigation>
    </>
  );
};