import { Title, Text, Stack, Flex, UnstyledButton, createStyles, Image, Button } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { useSignInWithGoogle } from 'react-firebase-hooks/auth';
import { useLocation, useNavigate } from 'react-router-dom';

import google from '@/assets/Brand/google.svg';

import { HeadlessLayout } from '@/components/Layout/HeadlessLayout';
import { Head } from '@/components/Head';
import { ActionButton } from '../components/ActionButton';
import auth from '@/assets/Login/auth.svg';
import { BaseButton } from '@/components/BaseButton';
import { HeadlessNavigation } from '@/components/HeadlessNavigation';
import storage from '@/utils/storage';
import { useRouteStore } from '@/stores/route';

const useStyles = createStyles((theme) => ({
}));

export const AuthSuccess = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const { from } = state || { from: 'login' };


  const { classes, cx } = useStyles();

  const { redirectRoute, prevRoute } = useRouteStore();

  const handleContinue = async () => {
    if (redirectRoute) {
      navigate(redirectRoute);
      return;
    }
    navigate('/');
  }

  if (redirectRoute == '/') {
    navigate(redirectRoute);
  }

  return (
    <HeadlessNavigation>
      <Stack p="xl" spacing={4}>
        <Image
          src={auth}
          withPlaceholder
          height={250}
          radius='md'
          alt='Events'
          m="auto"
          mt="md"
          mb="md"
          width={250}
          pt={0}
          imageProps={{
            style: {
              height: 250,
              objectFit: 'contain',
              objectPosition: 'center',
            }
          }}
        />
        <Text
          fw={700}
          fz={32}
          mt={0}
          mb={0}
          align='center'
        >
          {from == 'login' ? 'Log-in' : 'Sign-up'} Success
        </Text>
        <Text
          m='auto'
          mt={0}
          mb={30}
          align='center'
          fz={16}
          w='100%'
        >
          Welcome to the journey of date-planning!
          {
            redirectRoute == '/payment/checkout' &&
            <Text>You can now continue your payment.</Text>
          }
        </Text>

        <BaseButton content="Continue" onClick={handleContinue} fullWidth />
      </Stack>
    </HeadlessNavigation>
  );
};
