import { ActionButton } from '@/components/ActionButton';
import { BaseButton } from '@/components/BaseButton';
import { DefaultLoadingOverlay } from '@/components/DefaultLoadingOverlay';
import { createStyles, Footer, UnstyledButton, Text, Grid, Loader } from '@mantine/core';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type CheckoutSuccessFooterProps = {
};

const useStyles = createStyles((theme) => ({
  footer: {
    padding: 19,
    paddingLeft: 16,
    paddingRight: 16,
    boxShadow: '0px -2px 7px rgba(0, 0, 0, 0.2)',
    WebkitBoxShadow: '0px -2px 7px rgba(0, 0, 0, 0.2)',
    bottom: 0,
  },
  footerButton: {
    width: '100%',
    padding: 14.5,
    backgroundColor: theme.colors.primary[8],
    borderRadius: 8,
    transition: '0.3s',
    '&:hover': {
      backgroundColor: theme.colors.primary[3],
    },
  },
  disabled: {
    backgroundColor: theme.colors.gray[5],
    pointerEvents: 'all',
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: theme.colors.gray[5],
    },
  },
}));

export const CheckoutSuccessFooter = ({ }: CheckoutSuccessFooterProps) => {
  const { classes, cx } = useStyles();
  const navigate = useNavigate();

  return (
    <Footer height='auto' className={cx(classes.footer)}>
      <Grid align="center" gutter={'xs'}>
        <Grid.Col span={12}>
          <BaseButton
            fontSize={18}
            fullWidth
            content='View Booking'
            onClick={() => {
              navigate('/bookings');
            }}
          />

        </Grid.Col>
        <Grid.Col span={12}>
          <ActionButton
            fontSize={18}
            fullWidth
            content='Back to home'
            onClick={() => {
              navigate('/');
            }}
          />
        </Grid.Col>
      </Grid>
    </Footer>


  );
};
