import { Flex, Group, UnstyledButton, Image, Text } from "@mantine/core";
import { createStyles } from "@mantine/styles";
import ArrowForward from '@/assets/Account/arrow-forward.svg'

type AccountOptionProps = {
  img?: string;
  text?: string;
  onClick: () => void;
};

const useStyles = createStyles((theme) => ({
  option: {
    cursor: 'pointer',
  }
}));

export const AccountOption = ({
  img,
  text,
  onClick
}: AccountOptionProps) => {
  const { classes, cx } = useStyles();

  return (
    <Flex
      className={cx(classes.option)}
      w='100%'
      justify='space-between'
      p="xl"
      py='0'
      onClick={onClick}
    >
      <Group spacing={5}>
        <Image src={img} width='md' sx={{ userSelect: 'none' }} />
        <Text fz='md' sx={{ userSelect: 'none' }}>{text}</Text>
      </Group>
      <Image src={ArrowForward} width='md' sx={{ userSelect: 'none' }} />
    </Flex>
  );
};
