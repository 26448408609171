import { Navigate, Route, Routes } from 'react-router-dom';

import { Event } from './Event';
import { Events } from './Events';


type EventsRoutesProps = {
  authenticated?: boolean;
};

export const EventsRoutes = ({ authenticated }: EventsRoutesProps) => {
  return (
    <Routes>
      <Route path="/" element={<Events />} />
      <Route path=":eventId" element={<Event />} />
    </Routes>
  );
};
