import { AppProvider } from '@/providers/app';
import { DevRoutes } from '@/devRoutes';
import { ProdRoutes } from '@/prodRoutes';
import 'default-passive-events'
import { DEPLOY_STAGE } from './config';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from './lib/firebase';
import { useUserInfoStore } from './stores/authentication';
import { DefaultLoadingOverlay } from './components/DefaultLoadingOverlay';

function App() {
  const [user, loading] = useAuthState(auth);
  const { userInfo } = useUserInfoStore();

  return (
    <html lang="en-US">
      <body>
        <AppProvider>
          <DefaultLoadingOverlay loading={loading} />
          {
            !loading && DEPLOY_STAGE == 'development' && <DevRoutes user={user} userInfo={userInfo} />}
          {
            !loading && DEPLOY_STAGE == 'production' && <ProdRoutes user={user} userInfo={userInfo} />
          }
        </AppProvider>
      </body>
    </html>
  );
}

export default App;
