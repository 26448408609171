import { Box, Flex, Stack, Text } from "@mantine/core";
import { useEventRecommendations } from "../api/getEventSuggestions";
import { EventCard } from "./EventCard";

export const EventRecommendationsList = () => {

  const recommendationsQuery = useEventRecommendations({
    eventId: "00000000-0000-0000-0000-000000000000",
    config: {
      enabled: true,
    }
  });

  return (
    <Box pt={40}>
      <Text fw='bold' fz='xl' >Something you may like</Text>
      <Stack spacing='md' py='sm'>
        {recommendationsQuery?.data?.map((event, i) => {
          return (
            <EventCard
              key={`recommended-${i}-card`}
              onClick={() => {
              }}
              width='100%'
              name={event.Name}
              startDate={event.StartDate}
              count={event.Count}
              packageCurrency={event.PackageCurrency}
              packagePrice={event.PackagePrice}
              snag={event.Snag}
              pictures={event.Pictures && event.Pictures.length > 0 ? event.Pictures[0] : undefined}
              cardHeight={200}
            />
          );
        })}

      </Stack>
    </Box>
  );
};