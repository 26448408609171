import { BaseButton } from '@/components/BaseButton';
import { createStyles, Flex, Image, Stack, useMantineTheme, Text, Box, UnstyledButton, ActionIcon, TextInput, Avatar, Group, Loader } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { createRef, useEffect, useState } from 'react';
import searchIcon from '@/assets/NavigationBar/search.svg';
import { useMatches } from '@/features/events/api/getMatches';

type MatchFilterProps = {
  filter: string;
  handleApply: (v: string) => void;
  resetSelectedFilter: boolean;
  setResetSelectedFilter: (v: boolean) => void;
  user: any;
};

const useStyle = createStyles((theme) => ({
  selectedButton: {
    '&:hover': {
      backgroundColor: theme.colors.primary[0],
    },
  },
  container: {
    width: 20,
    boxSizing: 'border-box',
    transition: 'all 0.3s',
    transitionTimingFunction: 'ease',
  },
  searchInput: {
    width: '100%',
  },
  linkIcon: {
    width: 25,
    height: 25,
    filter: theme.colorScheme === 'dark' ? 'invert(100%)' : 'none',
  },
  scrollBox: {
    overflowY: 'scroll',
    overflowX: 'hidden',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },

  }
}));

type MatchesOptionsType = {
  id: string;
  avatar: string;
  title: string;
}


export const MatchFilter = ({ filter, handleApply, resetSelectedFilter, setResetSelectedFilter, user }: MatchFilterProps) => {
  const { classes, cx } = useStyle();
  const theme = useMantineTheme();
  const [selected, setSelected] = useState<string>(filter);
  const [matchesOptions, setMatchesOptions] = useState<MatchesOptionsType[]>();
  const searchInput = createRef<HTMLInputElement>();
  const handleSelected = (id: string) => {
    setSelected(id);
  };
  const handleSearch = () => {
    if (searchInput.current) {
      const value = searchInput.current.value;
      if (value) {
        const options = matchesQuery?.data?.filter((option) => {
          return option.Name?.toLowerCase().includes(value.toLowerCase());
        });
        const r = options?.map((e) => {
          return {
            id: e.Uid,
            avatar: e.Photos ? e.Photos[0] : '',
            title: e.Name || '',
          };
        });
        setMatchesOptions(r);
      } else {
        setMatchesOptions(matchesQuery?.data?.map((e) => {
          return {
            id: e.Uid,
            avatar: e.Photos ? e.Photos[0] : '',
            title: e.Name || '',
          };
        }));
      }
    }
  };

  const matchesQuery = useMatches({
    user,
    config: {
      enabled: !!user
    }
  });

  useEffect(() => {
    if (resetSelectedFilter) {
      setSelected('');
      setResetSelectedFilter(false);
    }
  }, [resetSelectedFilter]);

  useEffect(() => {
    if (matchesQuery?.data) {
      const { data } = matchesQuery;
      const options: MatchesOptionsType[] = data.map((e) => {
        return {
          id: e.Uid,
          avatar: e.Photos ? e.Photos[0] : '',
          title: e.Name || '',
        };
      });
      setMatchesOptions(options);
    }
  }, [matchesQuery?.data])

  return (
    <>
      <Box className={cx(classes.container)}
        sx={{
          width: '100%',
          padding: '24px 20px',
        }}
      >
        <TextInput
          ref={searchInput}
          className={cx(classes.searchInput)}
          placeholder="Search name"
          radius={50}
          sx={(theme) => ({
            '.mantine-Input-input': {
              cursor: 'text',
              border: `1px solid ${theme.colors.primary[3]}`,
              width: '100%',
              transition: 'all 0.1s',
              paddingRight: 0,
              '@media (max-width: 425px)': {
                fontSize: '10px'
              }
            },
          })}
          onKeyPress={(e) => {
            if (e.code == 'Enter') {
              handleSearch();
            }
          }}
          onChange={(e) => {
            if (e.currentTarget.value == '') {
              handleSearch();
            }
          }}
          rightSectionWidth={30}
          rightSection={
            <UnstyledButton
              fz='xs'
              mr={16}
              onClick={handleSearch}
              sx={{
                display: 'block',
              }}
            >
              <img
                alt="search"
                src={searchIcon}
                className={cx(classes.linkIcon)}
              />

            </UnstyledButton>
          }
        />
      </Box>
      <Flex h={210} direction='column' className={cx(classes.scrollBox)}>
        {
          matchesQuery?.isFetched ?
            matchesOptions && matchesOptions.map((option) => (
              <UnstyledButton
                className={cx(classes.selectedButton)}
                key={option.id}
                component={Flex}
                w='100%'
                justify='space-between'
                onClick={() => {
                  handleSelected(option.id);
                }}
                px='md'
                py='sm'
              >
                <Group>
                  <Avatar src={option.avatar} alt={option.title} radius="xl" variant={selected == option.id ? 'outline' : ''} color='primary.3' />
                  <Text
                    color={selected == option.id ? 'primary.3' : 'dark'}
                  >{option.title}</Text>
                </Group>
                {selected == option.id &&
                  <Group>
                    <IconCheck
                      color={theme.colors.primary[3]}
                      size="1.125rem"
                    />
                  </Group>
                }
              </UnstyledButton>
            )) :
            <Flex justify="center" align="center" p="md" h='100%'>
              <Loader
                size={50}
                color="primary.3"
              />
            </Flex>
        }
      </Flex>
      <Box px='md' pt='xs'>
        <BaseButton
          fullWidth
          onClick={() => {
            handleApply(selected);
          }}
          content={'Apply'}
        />
      </Box>
    </>
  );
};
