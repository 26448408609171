import { Booking } from "@/features/bookings";
import { Flex, Stack, Text, Image, Card, Button, CopyButton, Group, Tooltip, ActionIcon, Box } from "@mantine/core";
import { IconCheck, IconCopy } from "@tabler/icons-react";
import dayjs from "dayjs";
import { BaseButton } from '../../../components/BaseButton/BaseButton';
import { DEPLOY_STAGE } from "@/config";

type BookingCardProps = {
  booking: Booking;
  onClick: () => void;
};

export const BookingCard = ({ booking, onClick }: BookingCardProps) => {
  return (
    <Card px={0}>
      <Flex justify='space-between' p="md">
        <Flex direction='column'>
          <Text fz={20} fw={700}>{booking.event_name}</Text>
          <Group noWrap spacing='xs'>
            <Text fz={14}>{booking.booking_id?.slice(0, 5)}...{booking.booking_id?.slice(booking.booking_id.length - 5, booking.booking_id.length)}</Text>
            <CopyButton value={booking.booking_id || ''} timeout={2000}>
              {({ copied, copy }) => (
                <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
                  <ActionIcon color={copied ? 'primary.3' : 'gray'} onClick={copy}>
                    {copied ? <IconCheck size={14} /> : <IconCopy size={14} />}
                  </ActionIcon>
                </Tooltip>
              )}
            </CopyButton>
          </Group>
          <Text fz={14}>{dayjs(booking.start_date_time).format('DD MMM')}</Text>
          <Text fz={14}>{booking.solt_name}</Text>
          {
            booking.total_payment && Number(booking?.total_payment) > 0 ?
              <>
                <Text fz={16} mt={4}>{booking.is_paid ? 'Paid' : 'Pay'} {booking.total_payment_currency} {booking.total_payment}</Text>
                <Text fz={16}>{
                  booking.is_paid ? 'Booking Confirmed' : 'Pending'
                }</Text>
              </> :
              <>
                <Text fz={16} mt={4}>Free Event</Text>
                <Text fz={16}>{
                  'Booking Confirmed'
                }</Text>
              </>

          }
          
        </Flex>
        <Flex
          justify='end' pl='md' sx={{ flexGrow: 1 }}>
          <Image radius='lg' fit='cover' withPlaceholder src={booking?.event_imgs && booking.event_imgs.length > 0 ? booking.event_imgs[0] : ''} width={100} height={70} sx={{
            alignSelf: booking?.event_imgs && booking.event_imgs.length > 0 ? 'start' : 'center',
          }} />
        </Flex>
      </Flex>
      <Flex justify='start' gap="xs" p="md" pt={0}>
        {/* {
          DEPLOY_STAGE === 'production' && (
            <BaseButton
              padding='12px 16px'
              content="View booking details"
              onClick={() => onClick()}
              fontSize={14}
              style={{
                width: '100%',
              }}
            />
          )
        } */}
        {
          DEPLOY_STAGE === 'production' && (
            <>
              <BaseButton
                padding='12px 16px'
                content="View booking details"
                onClick={() => onClick()}
                fontSize={14}
                style={{
                  width: '50%',
                }}
              />
              <BaseButton
                padding='12px 16px'
                content="Share this event"
                onClick={() => {
                  if (navigator.share) {
                    navigator.share({
                      title: booking.event_name,
                      url: `${window.location.origin}/events/${booking.snag}`,
                    });
                  }
                }}
                light
                fontSize={14}
                style={{
                  width: '40%',
                }}
              />
            </>
          )
        }

      </Flex>

    </Card>
  );
};;