import { Title, Text, Stack, Flex, UnstyledButton, createStyles, NumberInput, Input, PinInput, Group, TextInput, InputBase, Box, Space } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { useAuthState, useSignInWithGoogle, useSignOut } from 'react-firebase-hooks/auth';
import { useNavigate } from 'react-router-dom';

import google from '@/assets/Brand/google.svg';
import phone from '@/assets/Account/phone.svg';

import { HeadlessLayout } from '@/components/Layout/HeadlessLayout';
import auth from '@/lib/firebase';
import { Head } from '@/components/Head';
import { ActionButton } from '../components/ActionButton';
import { useEffect, useState, useRef } from 'react';
import { HeadlessNavigation } from '@/components/HeadlessNavigation';
import { BaseButton } from '@/components/BaseButton';
import { useGetSMSVerificationCode } from '../api/getSMSVerificationCode';
import { IMaskInput } from 'react-imask';
import { useId } from '@mantine/hooks';
import { RecaptchaVerifier } from 'firebase/auth';
import { useSMSConfirmationStore } from '@/stores/authentication';


const useStyles = createStyles((theme) => ({
}));

export const AuthPhone = () => {
  const navigate = useNavigate();

  const { classes, cx } = useStyles();
  const [user] = useAuthState(auth);
  const [signOut, loading, error] = useSignOut(auth); 

  const [phoneNumber, setPhoneNumber] = useState('');
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier>();
  const [gettingCode, setGettingCode] = useState(false);
  const [isError, setIsError] = useState(false);
  const recaptchaWrapperRef = useRef<any>(null);

  const { confirmationResult, setConfirmationResult } = useSMSConfirmationStore();
  
  const handlePhoneInput = (value: string) => {
    setGettingCode(false);
    // remove all spaces from the value
    value = value.replace(/\s/g, '');
    if (value.length == 12 && isError) setIsError(false);
    setPhoneNumber(value);
  };

  const handlePhoneSubmit = async () => {
    if (phoneNumber.length < 12 || phoneNumber.length > 12) {
      setIsError(true);
      return;
    }
    if (phoneNumber.length == 12 && !isError) {
      try {
        setGettingCode(true);
        setIsError(false);
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    const _signOut = async () => {
      await signOut();
    };
    if (user) {
      _signOut();
    }
  }, [])

  useEffect(() => {
    setConfirmationResult(null);
  }, []);

  useEffect(() => {
    if (gettingCode == false && phoneNumber.length !== 12) return;
    const verifier = new RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
    }, auth);
    if (!recaptchaVerifier) setRecaptchaVerifier(verifier);
    return () => {
      recaptchaVerifier?.clear();
      if (recaptchaWrapperRef.current && recaptchaWrapperRef.current.innerHTML) {
        recaptchaWrapperRef.current.innerHTML = '<div id="recaptcha-container"></div>';
        setRecaptchaVerifier(undefined);
      }
    };
  }, [gettingCode]);

  useGetSMSVerificationCode({
    phoneNumber: phoneNumber,
    recaptchaVerifier: recaptchaVerifier,
    config: {
      enabled: gettingCode == true && recaptchaVerifier !== undefined && phoneNumber.length == 12,
      retry: false,
      onSuccess: (r) => {
        setGettingCode(false);
        setPhoneNumber('');
        setConfirmationResult(r);
        navigate('/auth/signup/phone/verify', {
          state: {
            phoneNumber: phoneNumber.replace(/(\d{3})(\d{4})(\d{2})/, "$1 $2 $3"),
          }
        });
      },
      onError: (e: any) => {
        console.log('e', e);
        setGettingCode(false);
        setPhoneNumber('');
        setRecaptchaVerifier(undefined);
      }
    },
  });

  return (
    <HeadlessNavigation back={1}>
      <Stack p="xl" spacing="md">
        <Title order={3}>Enter your phone number</Title>
        <Text fz={18}>We will send you a verification code via SMS.</Text>
      </Stack>
      <Box p="xl" mt={40}>
        <Input<any>
          id={useId()}
          component={IMaskInput}
          description="Error and description are"
          size='lg'
          radius={8}
          placeholder="Phone number"
          value={phoneNumber}
          onChange={(v: React.ChangeEvent<HTMLInputElement>) => handlePhoneInput(v.target.value)}
          error={isError ? 'Invalid phone number' : undefined}
          inputWrapperOrder={['input', 'error']}
          mask="+852 0000 0000"
          mb={90}
        />
        <div ref={recaptchaWrapperRef}>
          <div id="recaptcha-container"></div>
        </div>
        <BaseButton content="Next" onClick={handlePhoneSubmit} fullWidth />
      </Box>
    </HeadlessNavigation>
  );
};
