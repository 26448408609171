import { Flex, Loader, LoadingOverlay, Text } from "@mantine/core";

type DefaultLoadingOverlayProps = {
  loading: boolean;
  text?: string;
};

export const DefaultLoadingOverlay = ({ loading, text }: DefaultLoadingOverlayProps) => {
  return (
    <LoadingOverlay
      zIndex={9999}
      visible={loading}
      overlayBlur={10}
      loaderProps={{ size: 'lg', color: 'primary.2', variant: 'oval' }}
      overlayOpacity={0.1}
      overlayColor="primary.0"
      loader={<>
        <Flex justify="center">
          <Loader color="primary.2" size="xl" />
        </Flex>
        {
          text && <Text color="primary.2" size="lg" mt="md">{text}</Text>
        }
      </>

      }

    />
  );
};