import { createStyles, Flex } from '@mantine/core';
import * as React from 'react';

type HeadlessLayoutProps = {
  id?: string;
  children?: React.ReactNode;
};

const useStyles = createStyles({
  HeadlessLayout: {
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    '::MsOverflowStyle': 'none',
  },
  mainWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export const HeadlessLayout = ({ id, children }: HeadlessLayoutProps) => {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.mainWrapper)}>
      <main>
        <Flex
          className={cx(classes.HeadlessLayout)}
          direction="column"
          w="100%"
          justify="space-between"
          h="100vh"
        >
          {children}
        </Flex>
      </main>
    </div>
  );
};
