import { useQuery } from 'react-query';

import { GOOGLE_MAPS_API_KEY } from '@/config';
import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { Location } from '../types';

export const getGeocode = async (location?: Location) => {
  try {
    const data =
      await axios.get<any>(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${location?.lat},${location?.lng}&sensor=true&key=${GOOGLE_MAPS_API_KEY}
`);
    const res: any = data;
    return {
      data: res,
    };
  } catch (e) {
    return {
      data: null,
    };
  }
};

type QueryFnType = typeof getGeocode;

type UseGeocodeOption = {
  id?: string;
  location?: Location;
  config?: QueryConfig<QueryFnType>;
};

export const useGeocode = ({ id, location, config }: UseGeocodeOption) => {
  try {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: ['geocode', id, location],
      queryFn: () => getGeocode(location),
      enabled: !!location,
    });
  } catch (e) {
    return;
  }
};
