import { useIdToken, useAuthState } from 'react-firebase-hooks/auth';
import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { Event } from '../types';

type getSavedEventsProps = {
  user?: any;
};

export const getSavedEvents = async ({ user }: getSavedEventsProps) => {
  if (user) {
    const idToken = await user?.getIdToken();
    const { data } = await axios.get<Event[]>(`users/events/saved`, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });

    return data;
  }
};

type QueryFnType = typeof getSavedEvents;

type UseSavedEventsOptions = {
  user?: any;
  toggleSave?: boolean;
  config?: QueryConfig<QueryFnType>;
};

export const useSavedEvents = ({ config, user, toggleSave }: UseSavedEventsOptions) => {
  try {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [toggleSave],
      queryFn: () => getSavedEvents({ user }),
    });
  } catch (e) {
    console.log(e);
    return;
  }
};
