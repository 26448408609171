import * as React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { initMocks } from './test/server';
import { createRoot } from 'react-dom/client';

initMocks();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  // temp disable strict mode due to react 18 google map react issue
  // https://github.com/google-map-react/google-map-react/pull/1146
  // <React.StrictMode>
  
    <App />
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
