import { Tuple } from '@mantine/core';

export const colors: Record<string, Tuple<string, 10>> = {
  primary: [
    '#FFF3EE',
    '#F2D9D3',
    '#D79C8E',
    '#B76959',
    '#FCEFC8',
    '#D4EDF1',
    '#90D2DD',
    '#1B465C',
    '#555555',
    '#CCCCCC',
  ],
};
