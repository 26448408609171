import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';
import { SearchData, SearchTotal, getSearchProps } from '../types';

export type getSearchResponse = {
  Events: SearchData[];
  Page: number;
  Total: number;
};

export const getSearch = async ({
  search,
  loveFactor,
  match,
  date,
  location,
  sort,
  tags,
  limit,
  page,
}: getSearchProps) => {
  let params: {
    limit: number;
    page: number;
    search?: string;
    loveFactor?: string;
    match?: string;
    from?: string;
    to?: string;
    location?: string;
    sort?: string;
    tags?: string;
  } = {
    limit: limit,
    page: page,
    sort: sort,
  };

  if (tags) params.tags = tags;
  if (search) params.search = search;
  if (loveFactor) params.loveFactor = loveFactor;
  if (match) params.match = match;
  if (date) {
    params.from = date;
    params.to = date;
  }
  if (location) params.location = location;

  const { data } = await axios.get<{
    data: getSearchResponse;
    total: SearchTotal;
  }>(`/events`, {
    params,
  });
  return data;
};

type QueryFnType = typeof getSearch;

type UseSearchOptions = {
  search: string;
  loveFactor?: string;
  match?: string;
  date?: string;
  location?: string;
  sort?: string;
  tags?: string;
  limit: number;
  page: number;
  config?: QueryConfig<QueryFnType>;
};

export const useSearch = ({
  config,
  search,
  loveFactor,
  date,
  location,
  match,
  sort,
  tags,
  limit,
  page,
}: UseSearchOptions) => {
  try {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [search, loveFactor, match, date, location, sort, tags, limit, page],
      queryFn: () =>
        getSearch({ search, loveFactor, match, date, location, sort, tags, limit, page }),
    });
  } catch (e) {
    console.log(e);
    return;
  }
};
