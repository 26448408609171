import { BaseButton } from '@/components/BaseButton';
import { createStyles, Flex, Image, Stack, useMantineTheme, Text, Box, UnstyledButton, ActionIcon } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { ReactComponent as BookmarkUnfilled } from '@/assets/Event/bookmark-unfilled.svg';
import { ReactComponent as BookmarkFilled } from '@/assets/Event/bookmark-filled.svg';
import { User } from 'firebase/auth';
import { useSavedEvents } from '../api/getSavedEvents';
import { useCreateSavedEvent } from '../api/createSavedEvent';
import { useDeleteSavedEvent } from '../api/deleteSavedEvent';
import { useNavigate } from 'react-router-dom';
import { useRouteStore } from '@/stores/route';


type EventSaveProps = {
  isToggleSave?: string | null;
  user: User | null | undefined;
  userInfo: any;
  eventId: string;
};

const useStyle = createStyles((theme) => ({
  button: {
    border: '1px solid #555555',
    borderRadius: 50,
    height: 28,
    display: 'flex',
    justifyContent: 'space-between',
    gap: 2,
    padding: '0 12px',
  },
  icon: {
    alignSelf: 'center',
    maxWidth: 15,
    marginRight: 8,
  },

}));

export const EventSave = ({ user, userInfo, eventId, isToggleSave }: EventSaveProps) => {
  const { classes, cx } = useStyle();
  const theme = useMantineTheme();
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const [toggleSave, setToggleSave] = useState<boolean>(false);
  const navigate = useNavigate();
  const { setRedirectRoute, } = useRouteStore();
  const handleToggleSave = () => {
    if (!user || !userInfo) {
      setRedirectRoute(`${window.location.pathname}?toggleSave=t`);
      navigate('/auth');
      return;
    }
    setToggleSave(true);
  };

  useCreateSavedEvent({
    user,
    toggleSave,
    eventId,
    config: {
      enabled: toggleSave && !isSaved && !!user && !!userInfo,
      onSuccess: (data) => {
        setToggleSave(false);
      },
      onError: (error) => {
        console.log(error);
        setToggleSave(false);
      }
    },
  });

  useDeleteSavedEvent({
    user,
    toggleSave,
    eventId,
    config: {
      enabled: toggleSave && isSaved && !!user && !!userInfo,
      onSuccess: () => {
        setToggleSave(false);
      },
      onError: (error: any) => {
        console.log(error);
        setToggleSave(false);
      }
    },
  });


  const savedEventsQuery = useSavedEvents({
    user,
    toggleSave,
    config: {
      enabled: !!user && !!userInfo,
      onSuccess: (data) => {
      },
      onError: (error) => {
        console.log(error);
      }
    }
  });

  useEffect(() => {
    if (savedEventsQuery?.data) {
      savedEventsQuery?.data?.forEach((savedEvent) => {
        if (savedEvent.ID === eventId) {
          setIsSaved(true);
          return;
        }
      });
    } else {
      if (savedEventsQuery?.data !== undefined) setIsSaved(false);
    }
  }, [savedEventsQuery?.data]);

  useEffect(() => {
    if (isToggleSave && eventId) {
      setToggleSave(true);
    }
  }, [isToggleSave, eventId])

  return (
    <UnstyledButton
      className={cx(classes.button)}
      onClick={handleToggleSave}
      sx={(theme) => ({
      })}
    >
      {isSaved ? <BookmarkFilled className={cx(classes.icon)} /> : <BookmarkUnfilled className={cx(classes.icon)} />}
      <Text fz={14} sx={{ alignSelf: 'center', whiteSpace: 'nowrap' }}>
        {isSaved ?
          'Unsave' :
          'Save'
        }
      </Text>
    </UnstyledButton>
  );
};
