import { DefaultLoadingOverlay } from "@/components/DefaultLoadingOverlay";
import { HeadlessNavigation } from "@/components/HeadlessNavigation";
import auth from "@/lib/firebase";
import { Flex, Stack, Image, Text, Box } from "@mantine/core";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from 'react-router-dom';
import noSavedEvents from '@/assets/Event/no-saved-events.svg';
import { EventRecommendationsList } from "@/features/events/components/EventRecommendationsList";

export const NoSavedEventsList = () => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  return (
    <Box px='md'>
      <Stack pt='sm' spacing='0.5rem'>
        <Image src={noSavedEvents} alt='No saved events' width={106} m='auto' />
        <Text m='auto' fz='xl'>No saved events yet.</Text>
      </Stack>
      <EventRecommendationsList />
    </Box>

  );
};