import { useRoutes } from 'react-router-dom';

import routes from './routes';
import { User } from 'firebase/auth';

export const ProdRoutes = ({ user, userInfo }: { user: User | null | undefined, userInfo: string | undefined }) => {
  const element = useRoutes(routes({
    authenticated: !!user && !!userInfo,
  }));
  return element;
};
