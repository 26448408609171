import { createStyles, Stack, Title, Text, Divider, Select, TextInput, Flex, UnstyledButton, Drawer, useMantineTheme, Box } from '@mantine/core';

import { HeadlessNavigation } from '@/components/HeadlessNavigation';
import { useForm } from 'react-hook-form';
import { BaseButton } from '@/components/BaseButton';
import { ErrorMessage } from '@hookform/error-message';
import { useEffect, useState } from 'react';
import { IconX } from '@tabler/icons-react';
import { RelationshipStatusFilter } from '@/features/search/components/SearchRelationshipStatusFilter';
import { useUserInfoStore } from '../../../stores/authentication';
import { useNewUser } from '@/features/auth/api/newUser';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '@/lib/firebase';
import { Gender } from '../types/account';

const useStyles = createStyles((theme) => ({
  input: {
    margin: 0,
    marginBottom: theme.spacing.xs,
    '.mantine-Input-input': {
      borderBottom: '1px solid #E5E5E5',
    }
  },
  clear: {
    opacity: 0.5,
    transition: '0.3s',
    '&:hover': {
      color: theme.colors.primary[3],
    },
  },
  drawerInner: {
    margin: 'auto',
    marginBottom: 0,
    width: `${theme.breakpoints.xs}`,
    '@media (max-width: 540px)': {
      width: '100%',
    }
  },
  drawerContent: {
    borderRadius: '8px 8px 0 0',
  },
  drawerBody: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  drawerTitle: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 500,
    fontSize: 18,
    padding: 16,
    margin: 0,
  },
  drawerHeader: {
    borderBottom: '1px solid #F0F0F0',
    padding: 0,
  },
  drawerClose: {
    display: 'none',
  },
  selectInput: {
    margin: 0,
    marginBottom: theme.spacing.xs,
    '[data-selected]': {
      backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.primary[3],
      color: theme.white,
      ':hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[9] : theme.colors.primary[3],
        color: theme.white,
      },
    },

    // applies styles to hovered item (with mouse or keyboard)
    '[data-hovered]': {},
  },
}));

export const PersonalInformation = () => {
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();

  const { register, handleSubmit, formState: { errors }, setValue } = useForm();
  const [isEditContactInformation, setIsEditContactInformation] = useState<boolean>(false);
  const [isEditRelationshipStatus, setIsEditRelationshipStatus] = useState<boolean>(false);
  const [contactInformation, setContactInformation] = useState<any>({});
  const [relationshipStatus, setRelationshipStatus] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { userInfo, setUserInfo } = useUserInfoStore();
  const [user, loading] = useAuthState(auth);

  const onEditContactInformation = (data: any) => {
    setContactInformation({ ...data, email: userInfo.Email });
    setIsSubmitting(true);
    setIsEditContactInformation(false);
  };


  const handleCloseDrawer = () => {
    setIsEditRelationshipStatus(false);
  };

  const handleSelectRelationshipStatus = (value: string) => {
    setRelationshipStatus(value);
    setIsEditRelationshipStatus(false);
  }

  useNewUser({
    id: userInfo.ID,
    user: user,
    givenName: contactInformation.givenName,
    familyName: contactInformation.familyName,
    phoneNumber: contactInformation.phoneNumber,
    email: contactInformation.email,
    sex: contactInformation.sex,
    config: {
      enabled: !!contactInformation && isSubmitting,
      retry: false,
      onSuccess: () => {
        setUserInfo({
          ...userInfo,
          GivenName: contactInformation.givenName,
          FamilyName: contactInformation.familyName,
          PhoneNumber: contactInformation.phoneNumber,
          Email: contactInformation.email,
          Sex: contactInformation.sex,
        })
        setIsSubmitting(false);

      },
      onError: (e: any) => {
        setIsSubmitting(false);
      }
    },
  });

  return (
    <HeadlessNavigation back={1} headTitle='Personal information' title='Personal information' divider>
      <Stack p='xl' spacing='md'>
        <Title fz={18}>Log in method</Title>
        <Text>{userInfo.SignInProvider}</Text>
      </Stack>
      <Divider size={8} color='#f0f0f0' />
      <form onSubmit={handleSubmit(onEditContactInformation)}>
        <Stack p='xl' spacing='md'>
          <Flex justify='space-between'>
            <Title fz={18}>Contact information</Title>
            {
              !isEditContactInformation && (
                <UnstyledButton
                  onClick={() => setIsEditContactInformation(true)}
                >
                  <Text fz='sm' c='#B55D49'>
                    Edit
                  </Text>
                </UnstyledButton>
              )
            }
          </Flex>
          <div>
            <TextInput
              id='givenName'
              label="First Name"
              className={classes.input}
              variant="unstyled"
              // withAsterisk
              defaultValue={userInfo.GivenName}
              disabled={!isEditContactInformation}
              {...register(`givenName`,
                // { required: 'First Name required' }
              )}
            />
            <ErrorMessage
              errors={errors}
              name='givenName'
              render={({ message }) => <Text color="red" size="sm">{message}</Text>}
            />
          </div>
          <div>
            <TextInput
              id='familyName'
              label="Last Name"
              className={classes.input}
              variant="unstyled"
              // withAsterisk
              defaultValue={userInfo.FamilyName}
              disabled={!isEditContactInformation}
              {...register(`familyName`,
                // { required: 'Last Name required' }
              )}
            />
            <ErrorMessage
              errors={errors}
              name='familyName'
              render={({ message }) => <Text color="red" size="sm">{message}</Text>}
            />
          </div>
          <div>
            <TextInput
              id='phoneNumber'
              label="Phone Number (for WhatsApp)"
              className={classes.input}
              variant="unstyled"
              // withAsterisk
              defaultValue={userInfo.MobileNumber}
              disabled={!isEditContactInformation}
              {...register(`phoneNumber`,
                // { required: 'Phone Number required' }
              )}
            />
            <ErrorMessage
              errors={errors}
              name={'phoneNumber'}
              render={({ message }) => <Text color="red" size="sm">{message}</Text>}
            />
          </div>
          <div>
            <TextInput
              id='email'
              label="Email"
              className={classes.input}
              variant="unstyled"
              // withAsterisk
              defaultValue={userInfo.Email}
              disabled={true}
              {...register(`email`,
                {
                  // required: 'Email required',
                  pattern: /^\S+@\S+$/
                }
              )}
            />
            <ErrorMessage
              errors={errors}
              name={'email'}
              render={({ message }) => <Text color="red" size="sm">{message}</Text>}
            />
          </div>
          <div>
            <Select
              id='sex'
              label='Gender'
              placeholder="Gender"
              className={classes.selectInput}
              withAsterisk
              disabled={!isEditContactInformation}
              data={[
                { value: Gender.Female, label: 'Female (Ms)' },
                { value: Gender.Male, label: 'Male (Mr)' },
                { value: Gender.Female, label: 'Non-binary (Mx)' },
              ]}
              defaultValue={userInfo.Sex}
              styles={(theme) => ({
                item: {
                  '&[data-selected]': {
                    '&, &:hover': {
                      backgroundColor: theme.colors.primary[3],
                      color: theme.white
                    },
                  },
                }
              })}
              {...register(`sex`)}
              onChange={(v) => {
                if (v) setValue('sex', v);
              }}
            />
          </div>
          {
            isEditContactInformation && <BaseButton type='submit' content="Save" style={{
              marginTop: 16
            }}

            />
          }
        </Stack>
      </form>
      <Divider size={8} color='#f0f0f0' />
      {/* <Stack p='xl' spacing='md'>
        <Flex justify='space-between'>
          <Title fz={18}>Relationship status</Title>
          {
            !isEditRelationshipStatus && (
              <UnstyledButton
                onClick={() => setIsEditRelationshipStatus(true)}
              >
                <Text fz='sm' c='#B55D49'>
                  Edit
                </Text>
              </UnstyledButton>
            )
          }
        </Flex>
        <Text>{relationshipStatus}</Text>
      </Stack>
      <Drawer
        key="relationship-status-drawer"
        overlayProps={{
          color: theme.colors.dark[9],
          opacity: 0.1,
          blur: 3,
        }}
        position="bottom"
        size={245}
        classNames={{
          inner: cx(classes.drawerInner),
          header: cx(classes.drawerHeader),
          title: cx(classes.drawerTitle),
          body: cx(classes.drawerBody),
          content: cx(classes.drawerContent),
          close: cx(classes.drawerClose),
        }}
        // shadow="md"
        closeOnClickOutside
        opened={isEditRelationshipStatus}
        onClose={handleCloseDrawer}
        title={
          <>
            <Flex w="100%" justify="space-between">
              <UnstyledButton
                w={25}
                className={cx(classes.clear)}
                onClick={handleCloseDrawer}
              >
                <IconX width={25} />
              </UnstyledButton>
              <Box w='100%'>
                <Text fw='bolder' ta='center'>
                  Relationship status
                </Text>
              </Box>
            </Flex>
          </>
        }
      >
        <RelationshipStatusFilter
          filter={relationshipStatus}
          handleApply={handleSelectRelationshipStatus}
          resetSelectedFilter={false}
          setResetSelectedFilter={() => { }}
        />
      </Drawer> */}
    </HeadlessNavigation>
  );
};
