import { BaseButton } from '@/components/BaseButton';
import { createStyles, Flex, Image, Stack, useMantineTheme, Text, Box, UnstyledButton, ActionIcon, Group } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
import { useEffect, useState } from 'react';

type SortFilterProps = {
  filter: string;
  handleApply: (v: string) => void;
  resetSelectedFilter: boolean;
  setResetSelectedFilter: (v: boolean) => void;
};

const useStyle = createStyles((theme) => ({
  selectedButton: {
    '&:hover': {
      backgroundColor: theme.colors.primary[0],
    },
  }
}));


export const SortFilter = ({ filter, handleApply, resetSelectedFilter, setResetSelectedFilter }: SortFilterProps) => {
  const { classes, cx } = useStyle();
  const theme = useMantineTheme();
  const [selected, setSelected] = useState<string>(filter);
  const handleSelected = (id: string) => {
    setSelected(id);
  };
  const options = [
    {
      id: 'popular',
      title: 'Popular',
    },
    {
      id: 'recommended',
      title: 'Recommend',
    },
    {
      id: 'highestPrice',
      title: 'Highest Price',
    },
    {
      id: 'lowestPrice',
      title: 'Lowest Price',
    }
  ];

  useEffect(() => {
    if (resetSelectedFilter) {
      setSelected('');
      setResetSelectedFilter(false);
    }
  }, [resetSelectedFilter]);
  return (
    <>
      <Flex h='100%' direction='column' justify='space-evenly'>
        {
          options.map((option) => (
            <UnstyledButton
              className={cx(classes.selectedButton)}
              key={option.id}
              component={Flex}
              w='100%'
              justify='space-between'
              onClick={() => {
                handleSelected(option.id);
              }}
              p='md'
            >
              <Text
                color={selected == option.id ? 'primary.3' : 'dark'}
              >{option.title}</Text>
              {selected == option.id &&
                <Group>
                  <IconCheck
                    color={theme.colors.primary[3]}
                    size="1.125rem"
                  />
                </Group>
              }
            </UnstyledButton>
          ))
        }
      </Flex>
      <Box px='md' pt='xs'>
        <BaseButton
          fullWidth
          onClick={() => {
            handleApply(selected);
          }}
          content={'Apply'}
        />
      </Box>
    </>
  );
};
