import { Helmet } from 'react-helmet-async';

type HeadProps = {
  title?: string;
  headDescription?: string;
};

export const Head = ({ title = '', headDescription: description = '' }: HeadProps = {}) => {
  return (
    <Helmet
      title={title ? `${title} | ${description}` : undefined}
      defaultTitle="OhMyDate HK"
    >
      <meta name="description" content={description} />
    </Helmet>
  );
};
