import { useIdToken, useAuthState } from 'react-firebase-hooks/auth';
import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { EventsData } from '../types';

type getEventRecommendationsProps = {
  eventId?: string;
};

type getEventRecommendationsResponse = EventsData[];

export const getEventRecommendations = async ({ eventId }: getEventRecommendationsProps) => {
  const { data } = await axios.get<getEventRecommendationsResponse>(`/events/recommendations`, {
    params: {
      eventId,
    },
  });
  return data;
};

type QueryFnType = typeof getEventRecommendations;

type UseEventRecommendationsOptions = {
  eventId?: string;
  config?: QueryConfig<QueryFnType>;
};

export const useEventRecommendations = ({ config, eventId }: UseEventRecommendationsOptions) => {
  try {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: ['eventRecommendations', eventId],
      queryFn: () => getEventRecommendations({ eventId }),
    });
  } catch (e) {
    console.log(e);
    return;
  }
};
