import { Text, Flex, Box } from '@mantine/core';

type SectionTitleProps = {
  title: string;
};

export const SectionTitle = ({ title }: SectionTitleProps) => {
  return (
    <Flex>
      <Box
        h={24}
        w={6}
        mr={8}
        sx={(theme) => ({
          borderRadius: 20,
          backgroundColor:
            theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.primary[3],
          alignSelf: 'center',
        })}
      />
      <Text fw={700} fz={24}>
        {title}
      </Text>
    </Flex>
  );
};
