import { HorizontalScrollContainer } from "@/components/HorizontalScrollContainer";
import { Text, createStyles, Flex, Image, UnstyledButton, useMantineTheme } from "@mantine/core";
import { FilterConfigsProps } from "./SearchResults";

type SearchFiltersProps = {
  activeFilter: string;
  setActiveFilter: (id: string) => void;
  filterConfigs: FilterConfigsProps[];
};

type FilterProps = {
  id: string;
  children: React.ReactNode;
  icon: React.ReactNode;
  activeFilter?: boolean;
  setActiveFilter: (id: string) => void;
};

const useStyle = createStyles((theme) => ({
  filter: {
    border: '1px solid #E5E5E5',
    borderRadius: 50,
    height: 28,
    display: 'flex',
    justifyContent: 'space-between',
    gap: 2,
    padding: '0 12px',
  },
}));

const Filter = ({ id, children, icon, activeFilter, setActiveFilter: setActive }: FilterProps) => {
  const { classes, cx } = useStyle();
  return (
    <UnstyledButton
      className={cx(classes.filter)}
      onClick={() => {
        setActive(id);
      }}
      sx={(theme) => ({
        borderColor: activeFilter ? theme.colors.primary[3] : theme.colors.gray[3],
        background: activeFilter ? theme.colors.primary[0] : theme.white,
        color: activeFilter ? theme.colors.primary[3] : '#555555',
      })}
    >
      {icon}
      <Text fz={14} sx={{ alignSelf: 'center', whiteSpace: 'nowrap' }}>{children}</Text>
    </UnstyledButton>
  );
};

export const SearchFilters = ({ activeFilter, setActiveFilter, filterConfigs }: SearchFiltersProps) => {
  const { classes, cx } = useStyle();
  const theme = useMantineTheme();

  const handleActiveFilter = (id: string) => {
    if (activeFilter === id) {
      setActiveFilter('');
      return;
    }
    setActiveFilter(id);
  };

  return (
    <HorizontalScrollContainer style={{ paddingTop: '1rem', paddingBottom: '1.375rem', gap: theme.spacing.xs }}>
      {
        filterConfigs.map((filterConfig) => {
          if (filterConfig.enabled) {
            return (
              <Filter
                key={filterConfig.id}
                id={filterConfig.id}
                activeFilter={filterConfig.value.length > 0}
                setActiveFilter={handleActiveFilter}
                icon={filterConfig.icon}>
                {filterConfig.title}
              </Filter>
            );
          }
        })
      }

    </HorizontalScrollContainer>
  );
};
