import { Box, createStyles, Flex, Transition } from '@mantine/core';
import * as React from 'react';

import { NavigationBar } from '@/components/NavigationBar/NavigationBar';
import storage from '@/utils/storage';
import { useSearchStore } from '@/stores/search';
import { SearchResults } from '@/features/search/components/SearchResults';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { delayedResponse } from '../../test/server/utils';

type MainLayoutProps = {
  children: React.ReactNode;
};

const useStyles = createStyles({
  mainLayout: {
    overflowY: 'auto',
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    '::MsOverflowStyle': 'none',
  },
  mainWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
});

export const MainLayout = ({ children }: MainLayoutProps) => {
  const { classes, cx } = useStyles();
  const { searchOpened, setSearchOpened } = useSearchStore();
  const navigate = useNavigate();

  const fade = {
    in: { opacity: 1 },
    out: { opacity: 0 },
    transitionProperty: 'opacity',
  };

  useEffect(() => {
    if (searchOpened) setSearchOpened(false);
  }, [navigate])

  return (
    <div className={cx(classes.mainWrapper)}>
      <main>
        <Flex
          id="main"
          className={cx(classes.mainLayout)}
          direction="column"
          w="100%"
          justify="space-between"
          h="100vh"
        >
          <NavigationBar />
          <Transition
            mounted={searchOpened}
            transition={fade}
            duration={150}
            timingFunction="ease"
          >
            {(styles) => (
              <Flex
                direction="column"
                justify='space-between'
                style={{ ...styles, flexGrow: 1, width: '100%' }}
              >
                <Box w="100vw" maw="540px">
                  <SearchResults />
                </Box>
              </Flex>
            )}
          </Transition>
          <Transition
            mounted={!searchOpened}
            transition={fade} duration={150} timingFunction="ease">
            {(styles) => (
              <Flex
                direction="column"
                justify='space-between'
                style={{ ...styles, flexGrow: 1 }}>
                {children}
              </Flex>
            )}
          </Transition>


        </Flex>
      </main>
    </div>
  );
};
