import { createStyles, Footer, UnstyledButton, Text, Grid, Loader } from '@mantine/core';

import { DEPLOY_STAGE } from '@/config';
import { BaseButton } from '@/components/BaseButton';
import { RWebShare } from 'react-web-share';

type BookingDetailFooterProps = {
  bookingTitle: string;
  bookingSlug: string;
  onClick: () => void;
};

const useStyles = createStyles((theme) => ({
  footer: {
    padding: 19,
    paddingLeft: 16,
    paddingRight: 16,
    boxShadow: '0px -2px 7px rgba(0, 0, 0, 0.2)',
    WebkitBoxShadow: '0px -2px 7px rgba(0, 0, 0, 0.2)',
  },
  footerButton: {
    width: '100%',
    padding: 14.5,
    backgroundColor: theme.colors.primary[3],
    borderRadius: 8,
    transition: '0.3s',
  },
  disabled: {
    backgroundColor: theme.colors.gray[5],
    pointerEvents: 'all',
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: theme.colors.gray[5],
    },
  },
}));

export const BookingDetailFooter = ({ bookingTitle, bookingSlug, onClick }: BookingDetailFooterProps) => {
  const { classes, cx } = useStyles();
  return (
    <Footer height="auto" className={cx(classes.footer)}>
      <Grid align="center" gutter={'xs'}>
        {
          DEPLOY_STAGE === 'production' && (
            <Grid.Col span={12} pb="sm">
              <BaseButton
                fontSize={18}
                fullWidth
                content='Share booking details'
                onClick={() => {
                  if (navigator.share) {
                    navigator.share({
                      title: bookingTitle,
                      url: `${window.location.origin}/events/${bookingSlug}`,
                    });
                  }
                }}
              />
            </Grid.Col>
          )
        }

        <Grid.Col span={12} pb="sm">
          <BaseButton
            fontSize={18}
            fullWidth
            light
            content='View event'
            onClick={() => {
              onClick()
            }}
          />
        </Grid.Col>
      </Grid>
    </Footer>
  );
};
