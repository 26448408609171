import { createStyles, Flex, Image, Stack, useMantineTheme, Text, Box } from '@mantine/core';
import spotlightMagnified from '@/assets/Search/search-magnified.svg';
import { BaseButton } from "@/components/BaseButton";
import { EventRecommendationsList } from "@/features/events/components/EventRecommendationsList";

type SearchHasNoResultsProps = {
  resetAll: () => void;
};

const useStyle = createStyles((theme) => ({
}));


export const SearchNoResults = ({ resetAll }: SearchHasNoResultsProps) => {
  const { classes, cx } = useStyle();
  const theme = useMantineTheme();

  return (
    <Box px='md'>
      <Stack pt='sm' spacing='0.5rem'>
        <Image src={spotlightMagnified} alt='No results' width={106} m='auto' />
        <Text m='auto' fw='bold' fz='md'>Ops! No results found.</Text>
        <Text m='auto' fz='sm'>Try to change or reduce the words.</Text>
        <BaseButton content="Reset all filters" onClick={resetAll} fullWidth style={{ margin: 'auto', marginTop: 8 }} />
        <EventRecommendationsList />
      </Stack>
    </Box>
  );

};
