import { useIdToken, useAuthState } from 'react-firebase-hooks/auth';
import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { User } from '../types';
import auth from '@/lib/firebase';
import { RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

type getSMSVerificationCodeProps = {
  phoneNumber: string;
  recaptchaVerifier?: RecaptchaVerifier;
};

export const getSMSVerificationCode = ({
  phoneNumber,
  recaptchaVerifier,
}: getSMSVerificationCodeProps) => {
  try {
    if (recaptchaVerifier !== undefined) {
      return signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
    }
  } catch (e) {
    console.log(e);
    return e;
  }
};

type QueryFnType = typeof getSMSVerificationCode;

type UseGetSMSVerificationCodeOption = {
  phoneNumber: string;
  recaptchaVerifier?: RecaptchaVerifier;
  config?: QueryConfig<QueryFnType>;
};

export const useGetSMSVerificationCode = ({
  config,
  phoneNumber,
  recaptchaVerifier,
}: UseGetSMSVerificationCodeOption) => {
  try {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: ['phoneNumber', phoneNumber],
      queryFn: () => getSMSVerificationCode({ phoneNumber, recaptchaVerifier }),
    });
  } catch (e) {
    console.log(e);
    return;
  }
};
