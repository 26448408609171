import { Text, Flex, UnstyledButton, createStyles, Image, Radio, Box } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';


import { Head } from '@/components/Head';

import single from '@/assets/Login/single.svg';
import couple from '@/assets/Login/couple.svg';
import { BaseButton } from '@/components/BaseButton';
import { HeadlessNavigation } from '@/components/HeadlessNavigation';

const useStyles = createStyles((theme) => ({
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    padding: '0 20px',
    '@media (max-width: 425px)': {
      fontSize: 16,
    }
  },
  radioGroup: {
    paddingTop: theme.spacing.md,
  },
  image: {
    '.mantine-Image-imageWrapper': {
      width: 200,
      margin: 'auto',
      '@media (max-width: 425px)': {
        width: 150,
      }
    },
  }
}));

export const Journey = () => {
  const navigate = useNavigate();
  const { classes, cx } = useStyles();

  // hook to save journey data
  const handleJourney = async () => {
    try {
      navigate('/auth/success', {
        state: {
          from: 'signup',
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <HeadlessNavigation>
      <Flex direction='column'
        sx={{ flexGrow: 1 }}
      >
        <Text className={classes.title}>
          Choose your status to get suggested dating events!
        </Text>
        <Radio.Group
          name="relationshipStatus"
          defaultValue='single'
          pt={25}
        >
          <Flex w="100%" direction='column' gap={5} pb='sm'>
            <Image src={single} className={cx(classes.image)}
            />
            <Radio
              color='primary.3'
              value="single"
              label="Single"
              m="auto"
              sx={{ labelWrapper: { fontWeight: 500 } }}
            />
          </Flex>
          <Flex w="100%" direction='column' gap={5}>
            <Image src={couple} m='auto' className={cx(classes.image)} />
            <Radio
              color='primary.3'
              value="in-relationship"
              label="In relationship"
              m="auto"
              sx={{ labelWrapper: { fontWeight: 500 } }}
            />
          </Flex>
        </Radio.Group>
        <BaseButton onClick={handleJourney} content='Continue' style={{ margin: '0 20px', marginTop: 25 }} />
      </Flex>
    </HeadlessNavigation>
  );
};
