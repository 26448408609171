import {
  Badge,
  Box,
  Divider,
  Flex,
  Grid,
  Text,
  Loader,
  Title,
  UnstyledButton,
  Group,
  createStyles,
  Stack,
  SimpleGrid,
  LoadingOverlay,
  useMantineTheme,
} from '@mantine/core';
import { IconChevronRight } from '@tabler/icons-react';
import dayjs, { utc } from 'dayjs';
import _ from 'lodash';
import { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import calendar from '@/assets/Event/calendar.svg';
import clock from '@/assets/Event/clock.svg';
import location from '@/assets/Event/location.svg';
import money from '@/assets/Event/money.svg';
import { Head } from '@/components/Head';
import { HorizontalScrollContainer } from '@/components/HorizontalScrollContainer/HorizontalScrollContainer';

import { useEvent } from '../api/getEvent';
import { useGeocode } from '../api/getGeocode';
import { EventFooter } from '../components/EventFooter';
import { Organizer } from '../components/Organizer';
import { SectionTitle } from '../components/SectionTitle';
import { Showcase } from '../components/Showcase';
import { SlotDateCard } from '../components/SlotDateCard';
import { SlotDatePicker } from '../components/SlotDatePicker';
import { SlotPackageTypeCard } from '../components/SlotPackageTypeCard';
import { SlotTimeCard } from '../components/SlotTimeCard';
import { SummaryInfo } from '../components/SummaryInfo';
import { Solt } from '../types';
import { EventCard } from '../components/EventCard';
import { useIdToken } from 'react-firebase-hooks/auth';
import auth from '@/lib/firebase';
import { DefaultLoadingOverlay } from '../../../components/DefaultLoadingOverlay/DefaultLoadingOverlay';
import { ConfirmBooking } from '../components/ConfirmBooking';
import { EventSave } from './EventSave';
import { EventShare } from './EventShare';
import { useEventRecommendations } from '../api/getEventSuggestions';
import { Carousel } from '@mantine/carousel';
import EventsCarousel from '../components/EventsCarousel';
import { useRouteStore } from '@/stores/route';
import { useBookingStore } from '@/stores/booking';
import { useUserInfoStore } from '@/stores/authentication';
import { DEPLOY_STAGE } from '@/config';
import { Interest } from '../components/Interest';

const useStyles = createStyles((theme) => ({
  icon: {
    width: 14,
    height: 14,
    color: theme.colors.primary[3],
  },
  packageDetailWrapper: {
    h2: {
      fontSize: 16,
      margin: 4,
    },
    ul: {
      margin: 0,
    },
  },
}));

export const Event = () => {
  const { eventId } = useParams();
  const [searchParam] = useSearchParams();
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const theme = useMantineTheme();
  const { setRedirectRoute, } = useRouteStore();
  const { setBooking } = useBookingStore();
  const [isSinglesEvent, setIsSinglesEvent] = useState(false);
  const [earliestEventSlot, setEarliestEventSlot] = useState<any>({});

  const [user, loading, error] = useIdToken(auth);

  // queries
  const eventQuery = useEvent({
    eventId,
    config: {
      enabled: !!eventId 
    },
  });

  const recommendationsQuery = useEventRecommendations({
    eventId: eventQuery?.data?.ID,
    config: {
      enabled: !!eventQuery?.data?.ID,
    }
  });

  const event = eventQuery?.data;


  useEffect(() => {
    if (eventQuery?.data) {
      const isSinglesEvent = event?.Tags?.some((tag) => tag.Name.includes(':singles')) ? true : false;
      setIsSinglesEvent(isSinglesEvent);
    }
  }, [eventQuery?.data])

  useEffect(() => {
    const earliestSlot = event?.Solts?.filter((slot) => {
      return dayjs(slot.StartDateTime).isAfter(dayjs());
    }
    ).sort((a, b) => {
      return dayjs(a.StartDateTime).diff(dayjs(b.StartDateTime));
    }
    )[0];

    setEarliestEventSlot(earliestSlot);
  }, [event?.Solts])


  event?.Solts && event.Solts.sort((a, b) => { return dayjs(a.StartDateTime).diff(dayjs(b.StartDateTime)); });
  const slotsByDate = _.groupBy(event?.Solts, (slot) => {
    return dayjs(slot.StartDateTime).format('YYYY-MM-DD');
  });
  const slotsByDateArray = Object.keys(slotsByDate)
    .map((date) => {
      const slotByTime = _.groupBy(slotsByDate[date], (slot) => {
        return `${dayjs(slot.StartDateTime).format('hh:mm a')} - ${dayjs(slot.EndDateTime).format(
          'hh:mm a'
        )}`;
      });

      const times = Object.keys(slotByTime)
        .map((time) => {
          const startTime = time.split(' - ')[0];
          const startDateTime = dayjs(`${date} ${startTime}`, 'YYYY-MM-DD hh:mm a');
          const isExpired = startDateTime.isBefore(dayjs());

          return {
            time,
            slots: slotByTime[time],
            isExpired,
          };
        })
        .sort((a, b) => {
          return dayjs(a.time.split(' - ')[0], 'hh:mm a').diff(dayjs(b.time.split(' - ')[0], 'hh:mm a'));
        });

      // Check if all timeslots are expired
      const areAllTimesExpired = times.every((t) => t.isExpired);

      return {
        date,
        times,
        areAllTimesExpired,
      };
    })
    .sort((a, b) => {
      return dayjs(a.date).diff(dayjs(b.date));
    });
  const eventGeocodeQuery = useGeocode({ location: event?.Location, id: 'event' });
  const organizerGeocodeQuery = useGeocode({
    location: event?.Organization?.Location,
    id: 'organizer',
  });
  // const organizerFormattedAddress =
  //   organizerGeocodeQuery?.data?.data?.results[0]?.formatted_address;
  const eventFormattedAddress =
    (event?.Location?.lat && event?.Location?.lng) ? eventGeocodeQuery?.data?.data?.results[0]?.formatted_address :
      event?.Location?.display;
  const now = new Date();

  const mostSpotsSlot = event?.Solts?.reduce<Solt | undefined>((prev, current) => {
    const currentStart = new Date(current.StartDateTime);
    if (currentStart > now) {
      return (prev?.SpotsAvailable ?? 0) > current.SpotsAvailable ? prev : current;
    } else {
      return prev;
    }
  }, undefined);

  const totalSpots = event?.Solts?.reduce((total, slot) => {
    const slotStart = new Date(slot.StartDateTime);
    if (slotStart > now) {
      return total + (slot.SpotsAvailable ?? 0);
    } else {
      return total;
    }
  }, 0);

  // local states
  const [openMoreDates, setOpenMoreDates] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string | undefined>(undefined);
  const [selectedTime, setSelectedTime] = useState<string | undefined>(undefined);
  const [selectedPackageType, setSelectedPackageType] = useState<Solt | undefined>(undefined);
  const [confirmBooking, setConfirmBooking] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number>(1);
  const { userInfo } = useUserInfoStore();
  const dateRef = useRef<HTMLDivElement>(null);
  const timeRef = useRef<HTMLDivElement>(null);

  const handleSelectDate = useCallback(
    (date?: string) => {
      if (date) {
        date == selectedDate ? setSelectedDate(undefined) : setSelectedDate(date);
        dateRef.current?.scrollIntoView({ behavior: 'smooth' });
        setSelectedTime(undefined);
        setSelectedPackageType(undefined);
      }
    },
    [selectedDate]
  );

  const handleSelectTime = useCallback(
    (time?: string) => {
      if (time) {
        time === selectedTime ? setSelectedTime(undefined) : setSelectedTime(time);
        timeRef.current?.scrollIntoView({ behavior: 'smooth' });
        setSelectedPackageType(undefined);
      }
    },
    [selectedTime]
  );

  const handleSelectPackageType = useCallback(
    (slot?: Solt) => {
      if (slot) {
        slot === selectedPackageType
          ? setSelectedPackageType(undefined)
          : setSelectedPackageType(slot);
      }
    },
    [selectedPackageType]
  );

  const handleBook = () => {
    setConfirmBooking(true);
  };

  const handleConfirmBooking = async () => {
    setBooking({
      selectedDate,
      selectedTime,
      selectedPackage: selectedPackageType,
      quantity,
      isSinglesEvent,
    });
    if (!!user && !!userInfo) {
      navigate('/payment/checkout');
    } else {
      await setRedirectRoute('/payment/checkout');
      navigate('/auth');
    }
  };

  const eventTags = event?.Tags && event.Tags.filter((tag) => {
    return (tag.Name.includes(':')) ? false : true;
  });

  return (
    <>
      <Head title="Events" headDescription="Date events" />
      <DefaultLoadingOverlay loading={!eventQuery?.isFetched} />
      <ConfirmBooking
        open={confirmBooking}
        selectedPackageType={selectedPackageType}
        selectedDate={selectedDate}
        selectedTime={selectedTime}
        quantity={quantity}
        setQuantity={setQuantity}
        onClose={() => {
          setConfirmBooking(false);
        }}
        setConfirmBooking={setConfirmBooking}
        handleConfirmBooking={() => handleConfirmBooking()}
      />
      <SlotDatePicker
        open={openMoreDates}
        onClose={() => {
          setOpenMoreDates(!openMoreDates);
        }}
        date={dayjs(selectedDate).toDate()}
        setDate={(date) => {
          handleSelectDate(dayjs(date).format('YYYY-MM-DD'));
        }}
        slotsByDate={slotsByDateArray}
      />
      <Box sx={{ flexGrow: 1 }} w='100vw' maw='540px'>
        <Grid justify="center" align="center" w="100%" sx={{ margin: 'auto' }}>
          <Showcase images={event?.Pictures} />
          {/* Title */}
          <Grid.Col span={12} p="md" pt={0} pb={14}>
            {event?.Name ? (
              <Text
                fw={700}
                sx={{
                  fontSize: 24,
                }}
              >
                {event.Name}
              </Text>
            ) : (
              <Loader color="gray" variant="dots" />
            )}
          </Grid.Col>
              <Grid.Col span={12} pt={0} p="md">
                {
                  <Text
                    color="primary.3"
                    fz="md"
                    sx={(theme) => ({
                      [`@media (min-width: ${theme.breakpoints.md})`]: {
                        fontSize: theme.fontSizes.lg,
                      },
                    })}
                  >
                {totalSpots ? totalSpots : 0} {totalSpots && totalSpots > 1 ? 'spots' : 'spot'} left
                  </Text>
                }
          </Grid.Col>

          {eventTags && eventTags.length > 0 && (
            <Grid.Col pt={0} px="md">
              <Flex
                gap={8}
                sx={{
                  flexWrap: 'wrap',
                }}
              >
                {eventTags
                  .map((tag, i) => {
                    return <Interest key={i} tag={tag} /> 
                  })}
              </Flex>
            </Grid.Col>
          )}
          {
            DEPLOY_STAGE === 'production' &&
            <Grid.Col span={12} px="md">
              <Flex
                gap={8}
                sx={{
                  flexWrap: 'wrap',
                }}
              >
                <EventSave
                  isToggleSave={searchParam.get('toggleSave')}
                  user={user}
                  userInfo={userInfo}
                  eventId={event?.ID || ''}
                />
                <EventShare
                  title={event?.Name || 'Event'}
                  // description={event?. || 'Event'}
                  url={window.location.href}
                />
              </Flex>
            </Grid.Col>
          }

          <Grid.Col span={12} p={0} pb="md">
            <Divider my="xs" color="primary.9" />
          </Grid.Col>
          {/* Summary */}
          <Grid.Col span={12} pt={0} p="md">
            <Grid gutter={16}>
              <SummaryInfo
                icon={calendar}
                content={
                  <>
                    {`${dayjs(earliestEventSlot && earliestEventSlot.StartDateTime).format('hh:mm a')} - ${dayjs(
                      event?.Solts && event.Solts[0].EndDateTime
                    ).format('hh:mm a')} HKT`}&nbsp;
                    {
                      event?.Solts && event?.Solts.length > 1 && (
                        <span>(Earliest)</span>
                      )
                    }
                  </>
                }
              />
              <SummaryInfo
                icon={clock}
                content={<>{dayjs(earliestEventSlot && earliestEventSlot.StartDateTime).format('DD MMM YYYY, dddd')}&nbsp;
                  {
                    event?.Solts && event?.Solts.length > 1 && (
                      <span>(Earliest)</span>
                    )
                  }</>}
              />
              <SummaryInfo icon={location} content={event?.Location && event.Location.display} />
              <SummaryInfo
                icon={money}
                content={event?.IsRefundable ? 'Refundable' : 'No refunds'}
              />
              <Grid.Col span={12}>
                {event?.PackageDetails ? (
                  <Text pt={10} fz="md">
                    {event.PackageDetails}
                  </Text>
                ) : (
                  <Loader color="gray" variant="dots" />
                )}
              </Grid.Col>
            </Grid>
          </Grid.Col>
          <Grid.Col span={12} p="md" pt={0}>
            <Divider my="xs" color="primary.9" />
          </Grid.Col>
          {/* Package Option */}
          <Grid.Col span={12} px="md" pt={0} pb="sm">
            <SectionTitle title="Package Options" />
          </Grid.Col>
          <Grid.Col span={12} pt={0} px="md" pb="sm">
            <Flex justify="space-between" py="md" ref={dateRef}>
              <Title order={4}>Date</Title>
              <UnstyledButton onClick={() => setOpenMoreDates(!openMoreDates)}>
                <Group spacing={1}>
                  <Text color="primary.3" fw={400}>
                    More dates
                  </Text>
                  <IconChevronRight className={cx(classes.icon)} />
                </Group>
              </UnstyledButton>
            </Flex>
            <HorizontalScrollContainer snap>
              {slotsByDateArray.length > 0 &&
                slotsByDateArray
                  .filter((date: any) => date.date >= dayjs().format('YYYY-MM-DD'))
                  .map((date: any, i: number) => {
                  return (
                    <SlotDateCard
                      key={i}
                      selected={selectedDate === date.date}
                      date={date.date}
                      handleSelect={handleSelectDate}
                      areAllTimesExpired={date.areAllTimesExpired}
                    />
                  );
                })}
            </HorizontalScrollContainer>
          </Grid.Col>
          {!!selectedDate &&
            slotsByDateArray.filter((date: any) => date.date === selectedDate)[0]?.times.length > 0 && (
            <Grid.Col span={12} px="md" pt={0} pb="md" ref={timeRef}>
              <Title order={4} py="md">
                  Time
                </Title>

                <SimpleGrid cols={2}>
                {slotsByDateArray
                    .filter((date: any) => date.date === selectedDate)
                    .map((date: any) => {
                      return date.times
                        .filter((time: any) => {
                          const availableSlots = time.slots.filter(
                            (slot: any) => slot.SpotsAvailable > 0
                          );
                          return availableSlots.length > 0;
                        })
                        .map((time: any, id: string) => {
                          return (
                            <SlotTimeCard
                              id={id}
                              key={id}
                              selected={selectedTime === time.time}
                              time={time.time}
                              isExpired={time.isExpired}
                              handleSelect={handleSelectTime}
                            />
                          );
                        });
                    })}
                </SimpleGrid>
              </Grid.Col>
            )}
          {!!selectedDate &&
            !!selectedTime &&
            slotsByDateArray
              .filter((date: any) => date.date === selectedDate)[0]
              ?.times.filter((time: any) => time.time === selectedTime)[0]?.slots.length > 0 && (
              <Grid.Col span={12} px="md" pt={0} pb={60}>
                <Title order={4} py="md">
                  Package Type
                </Title>
                <Stack>
                {slotsByDateArray
                    .filter((date: any) => date.date === selectedDate)
                    .map((date: any) => {
                      return date.times
                        .filter((time: any) => time.time === selectedTime)
                        .map((time: any) => {
                          return time.slots
                            .filter((slot: any) => slot.SpotsAvailable > 0)
                            .map((slot: any, id: string) => {
                              return (
                                <SlotPackageTypeCard
                                  id={id}
                                  key={id}
                                  selected={selectedPackageType?.ID === slot.ID}
                                  slot={slot}
                                  handleSelect={handleSelectPackageType}
                                />
                              );
                          });
                        });
                    })}
                </Stack>
              </Grid.Col>
            )}

          {/* Package Details */}
          <Grid.Col span={12} pt={0} p="md">
            <SectionTitle title={'Package details'} />
          </Grid.Col>
          <Grid.Col span={12} pt={0} p="md" className={cx(classes.packageDetailWrapper)}>
            <Box pb="md">
              {event?.Description && (
                <div dangerouslySetInnerHTML={{ __html: event?.Description }} />
              )}
            </Box>
          </Grid.Col>
          <Grid.Col span={12} p="md" pt={0}>
            <SectionTitle title={'Organizer'} />
          </Grid.Col>
          <Grid.Col p="md" pt={0}>
            <Organizer
              logo={event?.Organization?.Picture}
              title={event?.Organization?.Name}
              description={event?.Organization?.Description}
              contacts={event?.Organization?.Contacts}
              address={eventFormattedAddress}
              lat={event?.Location?.lat}
              lng={event?.Location?.lng}
            />

          </Grid.Col>
          {/* More Recommendations */}
          {
            DEPLOY_STAGE === 'production' && (
              <>
                <Grid.Col p='md' pt={0}>
                  <SectionTitle title='More recommendations' />
                </Grid.Col>
                <Grid.Col p={0}>
                  <HorizontalScrollContainer>
                    {
                      recommendationsQuery?.data && recommendationsQuery?.data.length > 0 ?
                        recommendationsQuery?.data.map((event: any, i: number) => {
                          return (

                            <EventCard
                              key={`recommendations-${i}-card`}
                              onClick={() => {
                                navigate(`/events/${event.Snag}`);
                              }}
                              width={'calc(45% - 8px)'}
                              name={event.Name}
                              startDate={event.StartDate}
                              count={event.Count}
                              packageCurrency={event.PackageCurrency}
                              packagePrice={event.PackagePrice}
                              snag={event.Snag}
                              pictures={event.Pictures && event.Pictures.length > 0 ? event.Pictures[0] : undefined}
                              cardHeight={120}
                              tags={event.Tags}
                            />
                          );
                        })
                        :
                        <Flex
                          justify="center"
                          align="center"
                          p="md"
                          w="100%"
                        >
                          <Text
                            weight={700}
                            align="center">
                            No suggested events
                          </Text>
                        </Flex>
                    }

                  </HorizontalScrollContainer>
                </Grid.Col>
              </>
            )
          }

        </Grid>
      </Box>
      <Box
        style={{
          position: 'sticky',
          bottom: 0,
        }}
      >
        <EventFooter
          isToggleSave={searchParam.get('toggleSave')}
          user={user}
          userInfo={userInfo}
          eventId={event?.ID || ''}
          title={event?.Name || 'Event'}
          url={window.location.href} 
          disabled={selectedPackageType == undefined}
          currency={selectedPackageType?.PackageCurrency}
          price={selectedPackageType?.PackagePrice}
          onBook={() => {
            if (selectedPackageType == undefined) return;
            handleBook();
          }}
        />
      </Box>

    </>
  );
};
