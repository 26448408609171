import auth from '@/lib/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Box, createStyles, Divider, Flex, Group, Image, Stack, Title, UnstyledButton, Text, Grid } from '@mantine/core';
import { Head } from '@/components/Head';
import { IconArrowLeft } from '@tabler/icons-react';
import eventIcon from '@/assets/Booking/event.svg';
import shareIcon from '@/assets/Booking/share.svg';
import { BookingDetailFooter } from './BookingDetailFooter';
import { HeadlessNavigation } from '@/components/HeadlessNavigation';
import { useBookings } from '../api/getBookings';
import dayjs from 'dayjs';
import { DEPLOY_STAGE } from '@/config';

const useStyles = createStyles((theme) => ({
}));

const BookingOption = ({ title, content }: {
  title: string;
  content: string;
}) => {
  return (
    <Stack spacing={5}>
      <Text c='dimmed'>{title}</Text>
      <Text>{content}</Text>
    </Stack>
  );
};

const handleShare = () => {

};

const handleCalendar = () => {

};

const extractBookingId = (url: string) => {
  const pathArray = url.split('/');
  const bookingsIndex = pathArray.indexOf('bookings');

  if (bookingsIndex !== -1 && bookingsIndex + 1 < pathArray.length) {
    const bookingId = pathArray[bookingsIndex + 1];
    return bookingId.split('?')[0];
  }

  return null;
};

export const Booking = () => {
  // const { bookingId } = useParams<{ bookingId: string; }>();
  const bookingId = extractBookingId(window.location.href);
  const { classes, cx } = useStyles();
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  const bookingsQuery = useBookings({
    user,
    config: {
      enabled: !!user,
    }
  });

  const booking = bookingsQuery?.data?.data.find((booking) => booking.booking_id === bookingId);

  return (
    <HeadlessNavigation back={1} title='Booking details' headTitle='Booking details' divider>
      <Box p='xl' pt={0} sx={{
        flexGrow: 1
      }}>
        <Image
          radius={3.36}
          width='50%'
          height={100}
          src={booking && booking.event_imgs && booking.event_imgs[0]}
          withPlaceholder
          alt=""
        />
        <Stack spacing='sm' mt='xl'>
          <Title order={5}>{booking && booking.event_name}</Title>
          {
            DEPLOY_STAGE === 'development' && (
              <>
                <Group spacing={5} onClick={handleShare} sx={{
                  cursor: 'pointer'
                }}>
                  <Image src={shareIcon} width='md' />
                  <Text fz='md' color=''>Share this event</Text>
                </Group>
                <Group spacing={5} onClick={handleCalendar} sx={{
                  cursor: 'pointer'
                }}>
                  <Image src={eventIcon} width='md' />
                  <Text fz='md' color=''>Add to Google calendar</Text>
                </Group>
              </>
            )
          }

        </Stack>
        <Stack spacing='lg' mt='xl'>
          <BookingOption title='Order number' content={`${booking && booking.booking_id?.toString()}`} />
          {/* <BookingOption title='Name' content={} /> */}
          <BookingOption title='Date' content={dayjs(booking && booking.start_date_time).format('ddd, D MMM YYYY')} />
          <BookingOption title='Time' content={`${dayjs(booking && booking.start_date_time).format('h:mm a')} - ${dayjs(booking && booking.end_date_time).format('h:mm a')}`} />
          {/* <BookingOption title='Venue' content={booking.} /> */}
          <BookingOption title='Quantity' content={`${booking && booking.qty} person`} />
          {/* <BookingOption title='Package' content={booking.} /> */}
        </Stack>
      </Box>
      <Box
        style={{
          position: 'sticky',
          bottom: 0,
        }}
      >
        <BookingDetailFooter bookingSlug={`${booking && booking.snag}`} bookingTitle={`${booking && booking.event_name}`} onClick={() => {
          navigate(`/events/${booking && booking.snag}`);
        }} />
      </Box>
    </HeadlessNavigation>
  );

};