import { Badge } from "@mantine/core";
import { Tags } from "../types";

function getTextColor(backgroundColor: any) {
  // Convert hex to RGB
  function hexToRgb(hex: string) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : {
      r: 0,
      g: 0,
      b: 0
    };
  }

  // Calculate relative luminance
  function relativeLuminance(rgbColor: { r: number; g: number; b: number; }) {
    const sRgb = {
      r: rgbColor.r / 255,
      g: rgbColor.g / 255,
      b: rgbColor.b / 255,
    };

    const linearRgb = {
      r: sRgb.r <= 0.03928 ? sRgb.r / 12.92 : Math.pow((sRgb.r + 0.055) / 1.055, 2.4),
      g: sRgb.g <= 0.03928 ? sRgb.g / 12.92 : Math.pow((sRgb.g + 0.055) / 1.055, 2.4),
      b: sRgb.b <= 0.03928 ? sRgb.b / 12.92 : Math.pow((sRgb.b + 0.055) / 1.055, 2.4),
    };

    return 0.2126 * linearRgb.r + 0.7152 * linearRgb.g + 0.0722 * linearRgb.b;
  }

  // Convert the background color to RGB
  const rgbColor = hexToRgb(backgroundColor);

  // Calculate the relative luminance of the background color
  const luminance = relativeLuminance(rgbColor);

  // Set the text color based on the luminance
  const textColor = luminance > 0.5 ? 'black' : 'white';

  return textColor;
}

function complementaryColor(hexColor: string) {
  // Convert hex to RGB
  let r = parseInt(hexColor.slice(1, 3), 16);
  let g = parseInt(hexColor.slice(3, 5), 16);
  let b = parseInt(hexColor.slice(5, 7), 16);

  // Calculate complementary color
  let compR = 255 - r;
  let compG = 255 - g;
  let compB = 255 - b;

  // Convert to hex 
  let compHex = compR.toString(16) + compG.toString(16) + compB.toString(16);

  return '#' + compHex.toUpperCase();
}

export const Interest = ({ tag, key }: { key: number, tag: Tags; }) => {
  const tags = [
    {
      id: 'e7d6c7aa-177a-4662-a02c-6b44883c97d2',
      color: '#E74C3C',
      display: '🎨 Arts',
    },
    {
      id: '8d1c547c-161a-44cd-b542-2ca211ed8285',
      color: '#E67E22',
      display: '🥘 Baking/Cooking',
    },
    {
      id: '4b722102-2976-4a02-bd3e-d704f5e52742',
      color: '#F1C40F',
      display: '🎲 Board games',
    },
    {
      id: '0dea0497-829e-4be2-8a99-9762980fa3be',
      color: '#2ECC71',
      display: '☕ Coffee/Tea',
    },
    {
      id: '4890b198-c9a3-4b76-9983-c00e46a7915f',
      color: '#1ABC9C',
      display: '💃 Dancing',
    },
    {
      id: '23196e12-1d12-4d09-9f8f-d327eb2e14d2',
      color: '#3498DB',
      display: '🧵 DIY/Crafts',
    },
    {
      id: '7d32c9a6-6af5-49aa-aa8a-93ddf2ffb80b',
      color: '#9B59B6',
      display: '🥯 Foodie',
    },
    {
      id: '1ecf21e5-4186-4ac7-8c46-11ac67268dd7',
      color: '#34495E',
      display: '🎮 Gaming',
    },
    {
      id: '4f8e103f-5ec2-4685-b840-377f2892418a',
      color: '#16A085',
      display: '🏋️ Gym/Fitness',
    },
    {
      id: 'a635c894-5a33-4ea8-a35e-22f8f1945087',
      color: '#27AE60',
      display: '🎥 Movies/TV',
    },
    {
      id: '52d790ee-a832-4bca-bd87-0be4c4ec5499',
      color: '#2980B9',
      display: '🎶 Music',
    },
    {
      id: '577a37e1-86c5-44e3-9fcb-86e4395b7188',
      color: '#8E44AD',
      display: '🌵 Outdoor/Nature',
    },
    {
      id: '46c02a19-122f-4251-954f-56295ff2ddd0',
      color: '#2C3E50',
      display: '🎉 Social/Parties',
    },
    {
      id: '40a3d708-a520-4b32-976a-642a0d29ed5f',
      color: '#F39C12',
      display: '📷 Photography',
    },
    {
      id: '4e45d225-97c4-4ba0-8937-f307a69ab508',
      color: '#D35400',
      display: '📚 Reading',
    },
    {
      id: '064586c5-4b40-4531-8327-fb96a40d14ac',
      color: '#C0392B',
      display: '⚽ Sports',
    },
    {
      id: 'af63807a-8670-43b1-85cb-af5c809c8b7c',
      color: '#7F8C8D',
      display: '🌇 Travelling',
    },
    {
      id: '0f1f0235-8d8a-498e-b331-08f44b177534',
      color: '#BDC3C7',
      display: '💖 Volunteering',
    },
    {
      id: '14894f25-9dd8-45f1-925d-58b9e8bec583',
      color: '#7D3C98',
      display: '🏄 Water sports',
    },
    {
      id: '44279d61-5127-42f9-bd14-b54534eb0813',
      color: '#6C3483',
      display: '🍷 Wine/beer',
    },
    {
      id: '3a29ce1b-7baf-4701-8bc4-35f6827e87da',
      color: '#1A5276',
      display: '🧘 Yoga/meditation',
    },
  ];

  const foundTag = tags.find((_t) => _t.id === tag.ID);
  const color = foundTag?.color ?? '#ddf1F4';
  const display = foundTag?.display ?? tag.Name;

  return (
    <Badge
      key={key}
      variant='gradient'
      gradient={{
        from: color,
        to: color,
        deg: 60,
      }}
      sx={{
        color: getTextColor(color),
        fontWeight: 'normal',
        fontSize: '12px',
        padding: '4px 12px',
      }}
    >
      {display}
    </Badge>
  );
};