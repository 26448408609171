import { useIdToken, useAuthState } from 'react-firebase-hooks/auth';
import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { SavedEvent } from '../types';

type createSavedEventProps = {
  id?: string;
  user?: any;
};

export const createSavedEvent = async ({ id, user }: createSavedEventProps) => {
  if (user) {
    const idToken = await user?.getIdToken();
    const { data } = await axios.post<SavedEvent>(
      `/users/events/saved`,
      {
        eventId: id,
      },
      {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }
    );
    return data;
  }
};

type QueryFnType = typeof createSavedEvent;

type UseCreateSavedEventOptions = {
  eventId?: string;
  toggleSave?: boolean;
  user?: any;
  config?: QueryConfig<QueryFnType>;
};

export const useCreateSavedEvent = ({
  eventId,
  toggleSave,
  config,
  user,
}: UseCreateSavedEventOptions) => {
  try {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [toggleSave],
      queryFn: () => createSavedEvent({ id: eventId, user }),
    });
  } catch (e) {
    console.log(e);
    return;
  }
};
