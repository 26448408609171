import { Box, Text, createStyles } from "@mantine/core";


const useStyle = createStyles((theme) => ({
  box: {
    width: '100%',
    height: 200,
    background: '#FEF8F5',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  triangle: {
    clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)',
    background: '#FBCCC0',
    marginTop: 10,
    height: 125,
    width: 155,
  },
  triangleText: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    paddingTop: 55,
    fontSize: 12,
    fontWeight: 500,
    textAlign: 'center',
  },
  subtitle: {
    paddingTop: 5,
    fontSize: 8,
    fontWeight: 400,
    textAlign: 'center',
  },
  top: {
    position: 'absolute',
    top: 'calc(50% - 80px)',
    fontSize: 10,
    fontWeight: 500,
  },
  left: {
    position: 'absolute',
    right: 'calc(50% + 90px)',
    bottom: 'calc(50% - 70.5px)',
    fontSize: 10,
    fontWeight: 500,
  },
  right: {
    position: 'absolute',
    left: 'calc(50% + 90px)',
    bottom: 'calc(50% - 70.5px)',
    fontSize: 10,
    fontWeight: 500,
  }
}));


export const TriangleInfographic = () => {
  const { classes, cx } = useStyle();
  return (
    <Box className={cx(classes.box)}>
      <Box className={cx(classes.triangle)}>
        <Box className={cx(classes.triangleText)}>
          <Text className={cx(classes.title)}>
            Consummate
            <br />
            Love
          </Text>
          <Text className={cx(classes.subtitle)}>
            Intimacy + Passion +<br />Commitment
          </Text>
        </Box>
      </Box>
      <Text className={cx(classes.top)}>
        Intimacy
      </Text>
      <Text className={cx(classes.left)}>
        Passion
      </Text>
      <Text className={cx(classes.right)}>
        Commitment
      </Text>
    </Box>
  );
};