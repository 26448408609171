import { Badge, Box, Card, createStyles, Flex, Image, Loader, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { CSSProperties } from 'react';
import { Tags } from '../types';
import { DEPLOY_STAGE } from '@/config';
type EventCardProps = {
  startDate?: Date;
  endDate?: Date;
  name?: string;
  count?: number;
  packageCurrency?: string;
  packagePrice?: string;
  pictures?: string | null;
  snag?: string;
  spotsAvailable?: number;
  width?: number | string;
  noThumbnail?: boolean;
  noDetails?: boolean;
  snap?: boolean;
  cardHeight?: number | string;
  tags?: Tags[];
  style?: CSSProperties;
  onClick: (arg0: any) => void;
};

const useStyles = createStyles(() => ({
  // card: {
  // },
}));

export const EventCard = ({
  startDate,
  endDate,
  name,
  packageCurrency,
  packagePrice,
  count,
  pictures,
  snag,
  spotsAvailable,
  width,
  noDetails,
  noThumbnail,
  snap,
  cardHeight,
  style,
  tags,
  onClick,
}: EventCardProps) => {
  const { classes, cx } = useStyles();

  return (
    <Box
      style={style}
      onClick={() => {
        onClick(snag);
      }
      }
      sx={{
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: width ? width : 160,
        cursor: 'pointer',
        // scrollSnapAlign: snap ? 'start' : undefined,
        userSelect: 'none',
      }}
    >
      {
        !noThumbnail &&
        <Image src={pictures} withPlaceholder height={cardHeight ? cardHeight : 135} alt={name} radius={8} />
      }
      {
        !noDetails &&
        <Flex gap={2} direction="column" pt="sm">
          <Text weight={500} size={16} sx={{ lineHeight: 1.25 }}>
            {name}
          </Text>
            <Text color="dimmed" size={14}>
              {
                count && count > 1 ?
                  'Multiple dates available'
                  :
                  dayjs(startDate).format('ddd')} &middot; {dayjs(startDate).format('DD MMM')} &middot; {dayjs(startDate).format('HH:mm')}
            </Text>
            <Text weight={700} size={16}>
              {packageCurrency} {packagePrice}
            </Text>
            {
              spotsAvailable !== undefined &&
              <Badge variant='light' color={'primary.3'} size="xs" sx={(theme) => ({
                alignSelf: 'start',
              })}>
                {spotsAvailable} spots left
              </Badge>
            }
            {
              tags?.map((tag, i) => {
                return (
                  <Badge
                    key={`${tag}-${i}`}
                    variant='light'
                    color={'primary.3'}
                    size="xs"
                    sx={(theme) => ({
                      alignSelf: 'start',
                    })}
                  >
                    {tag.Name}
                  </Badge>
                );
              })
            }
          </Flex>
      }

    </Box>
  );
};
