import { Text, Stack, Flex, UnstyledButton, createStyles, Image, Button } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

import { HeadlessLayout } from '@/components/Layout/HeadlessLayout';
import { Head } from '@/components/Head';
import { ActionButton } from '../components/ActionButton';
import omdLogo from '@/assets/logo.svg';
import { HeadlessNavigation } from '@/components/HeadlessNavigation';
import { useEffect } from 'react';
import storage from '@/utils/storage';
import { useRouteStore } from '../../../stores/route';

const useStyles = createStyles((theme) => ({
  image: {
    '.mantine-Image-image': {
      margin: 'auto',
      width: 150,
      height: 150,
      '@media (max-width: 425px)': {
        width: 100,
        height: 100,
      }
    }
  }
}));

export const Auth = () => {
  const navigate = useNavigate();

  const { classes, cx } = useStyles();
  const { redirectRoute, setRedirectRoute, prevRoute } = useRouteStore();

  const handleLogin = () => {
    navigate('/auth/login');
  };

  const handleSignUp = () => {
    navigate('/auth/signup');
  };

  return (
    <>
      <HeadlessNavigation back={2}>
        <Stack p="xl" spacing={30}>
          <Image
            src={omdLogo}
            withPlaceholder
            radius='md'
            alt='Events'
            m="auto"
            mb="md"
            pt={0}
            className={cx(classes.image)}
            imageProps={{
              style: {
                objectFit: 'contain',
                objectPosition: 'center',
              }
            }}
          />
          <Text
            w="100%"
            align='center'
            fz={18}
            mb="md"
          >
            A date planner for everyone to enjoy a meaningful and genuine love connection
          </Text>
          {/* <ActionButton content="Log in as OMD user" onClick={handleLogin} /> */}
          {/* <ActionButton content="Sign up as new user" onClick={handleSignUp} /> */}
          <ActionButton content="Get Started" onClick={handleSignUp} />
        </Stack>
      </HeadlessNavigation>
    </>
  );
};
