import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface SMSConfirmationResultStore {
  confirmationResult: any;
  setConfirmationResult: (confirmationResult: any) => void;
  clearConfirmationResult: () => void;
}

interface UserInfoStore {
  userInfo: any;
  setUserInfo: (user: any) => void;
  clearUserInfo: () => void;
}

export const useSMSConfirmationStore = create<SMSConfirmationResultStore>((set) => ({
  confirmationResult: null,
  setConfirmationResult: (confirmationResult: any) => set({ confirmationResult }),
  clearConfirmationResult: () => set({ confirmationResult: null }),
}));

export const useUserInfoStore = create(
  persist(
    (set) => ({
      userInfo: null,
      setUserInfo: (userInfo: any) => set({ userInfo }),
      clearUserInfo: () => set({ userInfo: null }),
      _hasHydrated: false,
      setHasHydrated: (state: any): void => {
        set({
          _hasHydrated: state,
        });
      },
    }),
    {
      name: 'evol_app_user_info',
      getStorage: () => sessionStorage, // Use sessionStorage or localStorage
      onRehydrateStorage: () => (state: any) => {
        state.setHasHydrated(true);
      },
    }
  )
);