import { MantineProvider, ColorSchemeProvider, ColorScheme } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router, useLocation, useNavigate } from 'react-router-dom';

import { queryClient } from '@/lib/react-query';

import { colors } from './colors';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone';
import { DEPLOY_STAGE } from '@/config';
import { useWindowEvent } from '@mantine/hooks';
import history from 'history';
import storage from '@/utils/storage';
import { useRouteStore } from '@/stores/route';

function ErrorFallback() {
  return (
    // <div
    //   className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
    //   role="alert"
    // >
    //   <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
    //   <Button className="mt-4" onClick={() => window.location.assign(window.location.origin)}>
    //     Refresh
    //   </Button>
    // </div>
    <></>
  );
}

type AppProviderProps = {
  children: React.ReactNode;
};

dayjs.extend(customParseFormat);
dayjs.extend(utc)
dayjs.extend(timezone);

dayjs.tz.setDefault('Asia/Hong_Kong')

export const AppProvider = ({ children }: AppProviderProps) => {
  const [cookies, setCookie] = useCookies(['color-scheme']);
  const [colorScheme, setColorScheme] = useState<ColorScheme>('light');
  const toggleColorScheme = () => {
    const nextColorScheme = colorScheme === 'dark' ? 'light' : 'dark';
    setColorScheme(nextColorScheme);
    setCookie('color-scheme', nextColorScheme, { maxAge: 60 * 60 * 24 * 365 });
  };
  const { prevRoute, setPrevRoute } = useRouteStore();

  useWindowEvent('popstate', async (e) => {
    if (window.location.pathname === '/auth' && !prevRoute.includes('/auth')) {
      window.history.go(-2);
    }

    if (prevRoute == '/auth/success') {
      await setPrevRoute('/');
      window.history.go(-2);
    }
  });

  return (
    <React.Suspense fallback={<div>error fallback</div>}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <CookiesProvider>
          <HelmetProvider>
            <QueryClientProvider client={queryClient}>
              {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
              <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
                <MantineProvider
                  withCSSVariables
                  withGlobalStyles
                  withNormalizeCSS
                  theme={{
                    colorScheme: colorScheme,
                    fontFamily: 'Roboto, sans-serif',
                    fontSizes: {
                      xs: '0.75rem',
                      sm: '0.875rem',
                      md: '0.9375rem',
                      lg: '1rem',
                      xl: '1.125rem',
                    },
                    colors: colors,
                    primaryColor: 'primary',
                    lineHeight: 1.5,
                    components: {
                      Input: {
                        styles: (theme) => ({
                          input: {
                            '&:focus': {
                              borderColor:
                                theme.colorScheme === 'dark'
                                  ? theme.colors.primary[3]
                                  : theme.colors.primary[0],
                            },
                            '&:focus-within': {
                              borderColor:
                                theme.colorScheme === 'dark'
                                  ? theme.colors.primary[0]
                                  : theme.colors.primary[3],
                            },
                          },
                        }),
                      },
                    },
                    globalStyles: (theme) => {
                      return {
                        '*, *::before, *::after': {
                          boxSizing: 'border-box',
                        },
                        html: {
                          whitespace: 'nowrap',
                          overflowX: 'hidden',
                          overflowY: 'auto',
                          textOverflow: 'ellipsis',
                          overscrollBehaviorY: 'none',
                          backgroundColor: theme.colors.primary[0],
                        },
                        body: {
                          margin: 0,
                          backgroundColor:
                            theme.colorScheme === 'dark' ? theme.colors.dark[7] : 'white',
                          minWidth: '320px',
                          [`@media (max-width: 320px)`]: {
                            minWidth: '100%',
                          },
                        },
                        '.root-layout': {},
                        main: {
                          boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                          background: 'white',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                          height: 'fill-available',
                          position: 'fixed',
                          maxWidth: theme.breakpoints.xs,
                        },
                        '.mantine-Modal-root': {
                          display: 'flex',
                          justifyContent: 'center'
                        }
                      };
                    },
                  }}
                >
                    <Router>{children}</Router>
                </MantineProvider>
              </ColorSchemeProvider>
            </QueryClientProvider>
          </HelmetProvider>
        </CookiesProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
