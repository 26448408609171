import { Carousel, Embla } from "@mantine/carousel";
import { Avatar, createStyles, Flex, Tabs, Text, Image, Button, Box, Title, Loader } from "@mantine/core";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMatchesResponse } from "../api/getMatches";
import { Match } from "../types/user";
import { useEvents } from "../api/getEvents";
import { useMatches } from "../api/getMatches";
import { types } from "util";
import { EventCard } from "./EventCard";
import { EventSeeMoreCard } from "./EventSeeMoreCard";
import EventsCarousel from "./EventsCarousel";

type MatchesTabProps = {
  type: string;
  display?: string;
  active: boolean;
  events?: any[];
  cover: string;
  activeTab: any;
};

type MatchesTabsProps = {
  user: any;
  snap: boolean;
  setIsMatchLoaded: (isMatchLoaded: boolean) => void;
};

const useStyles = createStyles((_theme, _params) => ({
  tabs: {
    display: 'flex',
    justifyContent: 'start',
    padding: _theme.spacing.md,
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: _theme.spacing.md,
    flexWrap: 'nowrap',
    WebkitOverflowScrolling: 'touch',
    overflowX: 'auto',
    scrollbarWidth: 'none',
    '::webkit-scrollbar': {
      display: 'none',
    },
    '::MsOverflowStyle': 'none',
  },
  tab: {
    border: 'none',
    borderRadius: _theme.radius.xl,
    background: 'none',
    padding: 'none',
    cursor: 'pointer'
  },
  displayName: {
    paddingTop: 8,
    fontSize: 14,
    '@media (max-width: 425px)': {
      fontSize: 12,
    }


  },
  icon: {
    '.mantine-Image-root': {
      paddingBottom: 4,
    },
    '.mantine-Image-imageWrapper': {
      margin: 'auto',
    },
    '@media (max-width: 425px)': {
      '.mantine-Image-imageWrapper': {
      },
    }
  },
  loader: {
    transition: '0.2s',
  }
}));

const MatchesTab = ({
  user,
  setIsMatchLoaded,
  snap,
}: MatchesTabsProps) => {
  const { classes, cx } = useStyles();
  const [selectedMatch, setSelectedMatch] = useState<Match | undefined>();
  const [matchesTab, setMatchesTab] = useState<MatchesTabProps[]>();
  const [slides, setSlides] = useState<JSX.Element | JSX.Element[]>();

  const navigate = useNavigate();

  const matchesQuery = useMatches({
    user,
    config: {
      enabled: !!user
    }
  });

  const eventsQuery = useEvents({
    matchId: selectedMatch?.Uid,
    config: {
      enabled: !!selectedMatch,
    }
  });

  const matches = matchesQuery?.data;
  const events = eventsQuery?.data?.data;

  useEffect(() => {
    if (matchesQuery?.isFetched) setIsMatchLoaded(true);
  }, [matchesQuery?.isFetched]);

  useEffect(() => {
    if (matchesQuery?.data) {
      setSelectedMatch(matchesQuery?.data[0]);
    }
  }, [matchesQuery?.data]);

  useEffect(() => {
    if (matches && matches.length > 0) {
      const matchesTab: MatchesTabProps[] | undefined = matches?.map((match) => {
        return {
          type: match.Uid,
          display: match.Name,
          cover: match.Photos ? match.Photos[0] : '',
          active: true,
          activeTab: selectedMatch?.Uid === match.Uid,
        };
      });
      setMatchesTab(matchesTab);
    }
  }, [matches]);

  useEffect(() => {
    const slides = events?.Events && events?.Events.length > 0 ? events?.Events.map((event: any, i) => {
      return (
        <Carousel.Slide key={i}>
          {
            i == events.Events.length - 1 && events.Events.length % 2 == 1 ?
              <Flex
                sx={{
                  flexGrow: 1,
                }}
              >
                <EventCard
                  key={`${i}-card`}
                  snap={snap}
                  onClick={() => {
                    navigate(`/events/${event.Snag}`);
                  }}
                  width={'calc(100% - 8px)'}
                  name={event.Name}
                  startDate={event.StartDate}
                  count={event.Count}
                  packageCurrency={event.PackageCurrency}
                  packagePrice={event.PackagePrice}
                  snag={event.Snag}
                  pictures={event.Pictures && event.Pictures.length > 0 ? event.Pictures[0] : undefined}
                  cardHeight={120}
                />
                <EventSeeMoreCard
                  onClick={() => {
                    navigate({
                      pathname: '/search',
                      search: `?match=${selectedMatch?.Uid}`,
                    });
                  }}
                  width={'calc(100% - 8px)'}
                />
              </Flex>
              :
              <EventCard
                key={`${i}-card`}
                snap={snap}
                onClick={() => {
                  navigate(`/events/${event.Snag}`);
                }}
                width={'calc(50% - 8px)'}
                name={event.Name}
                startDate={event.StartDate}
                count={event.Count}
                packageCurrency={event.PackageCurrency}
                packagePrice={event.PackagePrice}
                snag={event.Snag}
                pictures={event.Pictures && event.Pictures.length > 0 ? event.Pictures[0] : undefined}
                cardHeight={120}
              />
          }
        </Carousel.Slide>
      );
    }) : <Flex
      justify="center"
      align="center"
      p="md"
      w="100%"
    >
      <Text
        weight={700}
        align="center"
      >
        No suggested events
      </Text>
    </Flex>;
    setSlides(slides);
  }, [events?.Events, selectedMatch]);

  return (
    matchesQuery?.isFetched ?
      matches && matches.length > 0 ?
        <Box pt='md' className={cx(classes.loader)}
          sx={
            matchesTab && matchesTab.length > 0 ?
              {
                opacity: 1,
              } : {
                opacity: 0,
              }
          }>
          <Title size={20} p='md'>
            For you&nbsp;
            <Text span size={20} color='primary.3' fw='bold'>
              and&nbsp;
              <Text span inherit sx={{
                transition: '0.2s ease-in-out',
                opacity: selectedMatch?.Uid != 'nil' ? 1 : 0,
              }}>
                {selectedMatch?.Name}
              </Text>
            </Text>
          </Title>
          <Tabs
            unstyled
            defaultValue={selectedMatch?.Uid}
            value={selectedMatch?.Uid}
            onTabChange={(v: any) => {
              if (selectedMatch && selectedMatch.Uid == v) return;
              setSelectedMatch({
                Uid: 'nil',
              });
              // wait for animation to finish
              setTimeout(() => {
                setSelectedMatch(matches?.find((match) => match.Uid == v));
              }, 200);
            }}
            keepMounted={false}
          >
            <Tabs.List className={cx(classes.tabs)}>
              {
                matchesTab && matchesTab.map(({ active, type, cover, display }: MatchesTabProps, i: number) => {
                  if (active) {
                    return (
                      <Tabs.Tab
                        key={i}
                        value={type}
                        className={cx(classes.tab)}
                      >
                        <Flex
                          direction="column"
                          justify="center"
                          align="center"
                          p="md"
                          pl={0}
                          py={0}
                          sx={{
                            '@media (max-width: 425px)': {
                              paddingLeft: 8,
                              paddingRight: 8,
                            },
                          }}
                        >
                          {cover ?
                            <Box
                              sx={{
                              }}
                            >
                              <Image
                                radius={100}
                                width={70}
                                src={cover}
                                className={cx(classes.icon)}
                                sx={(theme) => ({
                                  '.mantine-Image-image': {
                                    boxSizing: 'border-box',
                                    borderRadius: 100,
                                    border: selectedMatch?.Uid == type ? `solid 3px ${theme.colors.primary[2]}` : 'none',
                                  }
                                })}
                              />
                              <Text
                                weight='bold'
                                color='primary.3'
                                align="center"
                                className={cx(classes.displayName)}
                                sx={(theme) => ({
                                  transition: '0.2s ease-in-out',
                                  filter: selectedMatch?.Uid == type ? 'none' : 'grayscale(100%)',
                                })}
                              >
                                {display}
                              </Text>
                            </Box>
                            :
                            <Box>
                              <Avatar
                                radius={100}
                                mb={4}
                                h={50}
                                w={50}
                                color="primary.2"
                                sx={(theme) => ({
                                  margin: 'auto',
                                  border: selectedMatch?.Uid == type ? `solid 3px ${theme.colors.primary[2]}` : 'none',
                                })}
                              >
                                {
                                  type.slice(0, 1).toUpperCase()
                                }
                              </Avatar>
                              <Text
                                weight={700}
                                color='primary.3'
                                align="center"
                                className={cx(classes.displayName)}
                              >
                                {display}
                              </Text>
                            </Box>
                          }
                        </Flex>
                      </Tabs.Tab>
                    );
                  }
                })
              }
            </Tabs.List>

            <Tabs.Panel
              key={'nil'}
              value={'nil'}
            >
              <Flex justify="center" align="center" p="md" h={241}>
                <Loader
                  size={50}
                  color="primary.3"
                />
              </Flex>

            </Tabs.Panel>

            {
              selectedMatch?.Uid && selectedMatch?.Uid != 'nil' &&
              <Tabs.Panel
                key={selectedMatch?.Uid}
                value={selectedMatch?.Uid ? selectedMatch?.Uid : 'x'}
              >
                {
                  events && <EventsCarousel
                    events={events.Events}
                    slideSize="50%"
                    slideGap={2}
                    slideToScroll={2}
                  >
                    {slides}
                  </EventsCarousel>
                }

              </Tabs.Panel>
            }
          </Tabs>
        </Box> : <></>
      :
      <Flex justify="center" align="center" p="md" h='100%'>
        <Loader
          size={50}
          color="primary.3"
        />
      </Flex>

  );
};

export default MatchesTab;