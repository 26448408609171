import { Text, Stack, Flex, UnstyledButton, createStyles, Image } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { useLocation, useNavigate } from 'react-router-dom';


import { Head } from '@/components/Head';
import { CheckoutFailedFooter } from '../components/CheckoutFailedFooter';
import { useEffect, useState } from 'react';
import { DefaultLoadingOverlay } from '@/components/DefaultLoadingOverlay';
import { useQuery } from '../../../hooks/useQuery';
import { usePayment } from '../api/createPayment';
import { useAuthState } from 'react-firebase-hooks/auth';
import auth from '@/lib/firebase';
import { HeadlessNavigation } from '@/components/HeadlessNavigation';
import { useBookingStore } from '@/stores/booking';

const useStyles = createStyles((theme) => ({
}));

export const CheckoutFailed = () => {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);
  const [tryAgain, setTryAgain] = useState(false);
  const { clearBooking } = useBookingStore();


  useEffect(() => {
    clearBooking();
  }, [])


  const booking_id = useQuery().get('booking_id');

  useEffect(() => {
    if (!(booking_id)) {
      setTimeout(() => {
        navigate('/');
      }, 2000);
    }
  }, [booking_id]);

  const { classes, cx } = useStyles();

  usePayment({
    bookingId: booking_id ? booking_id : '',
    user,
    config: {
      enabled: !!booking_id && !!user && tryAgain,
      onSuccess: (data: any) => {
        setTryAgain(false);
        if (data.success == true) {
          window.open(data.redirect_to, '_self');
        }
      },
      onError: (error: any) => {
        setTryAgain(false);
      }
    }
  });

  const handleOnClick = () => {
    setTryAgain(true);
  }

  return (
    !!booking_id ?
      <>
        <HeadlessNavigation>
          <Stack p="xl" spacing="md">
            <Image
              src=''
              withPlaceholder
              height={250}
              radius='md'
              alt='Events'
              m="auto"
              mt="md"
              mb="md"
              width={250}
              pt={0}
              imageProps={{
                style: {
                  height: 250,
                  objectFit: 'contain',
                  objectPosition: 'center',
                }
              }}
            />
            <Text
              fw={700}
              fz={32}
              mt={0}
              mb={0}
              align='center'
            >
              Payment failed
            </Text>
            <Text
              m='auto'
              mt={0}
              mb='md'
              align='center'
              fz={18}
              w='100%'
            >
              Sorry. You payment could not be processed just now. Please try again.
            </Text>
          </Stack>
        </HeadlessNavigation>
        <CheckoutFailedFooter onClick={() => handleOnClick()} />
      </> :
      <DefaultLoadingOverlay loading={true} text={'No booking found, redirecting...'} />
  );
};
