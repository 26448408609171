import { Navigate, Route, Routes } from 'react-router-dom';
import { Account } from './Account';
import { PersonalInformation } from './PersonalInformation';

type AccountRoutesProps = {
  authenticated?: boolean;
};

export const AccountRoutes = ({ authenticated }: AccountRoutesProps) => {
  return (
    <Routes>
      <Route path="/" element={authenticated ? <Account /> : <Navigate to="/auth" />} />
      <Route path='/personal-information' element={authenticated ? <PersonalInformation /> : <Navigate to='/auth' />} />
    </Routes>
  );
};
