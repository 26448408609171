import { useIdToken, useAuthState } from 'react-firebase-hooks/auth';
import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { EventsData, EventsTotal } from '../types';

type getEventsProps = {
  tags?: string;
  matchId?: string;
  limit?: number;
  skip?: number;
};

type getEventsResponse = {
  Events: EventsData[];
  Page: number;
  Total: number;
};

export const getEvents = async ({ tags, matchId, limit, skip }: getEventsProps) => {
  if (matchId == 'nil') return;
  const { data } = await axios.get<{
    data: getEventsResponse;
    total: EventsTotal;
  }>(`/events`, {
    params: {
      matchId: matchId,
      tags: tags,
      limit: limit,
      skip: skip,
    },
  });
  return data;
};

type QueryFnType = typeof getEvents;

type UseEventsOptions = {
  limit?: number;
  skip?: number;
  tags?: string;
  matchId?: string;
  config?: QueryConfig<QueryFnType>;
};

export const useEvents = ({ config, tags, matchId, limit, skip }: UseEventsOptions) => {
  try {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [tags, matchId],
      queryFn: () => getEvents({ tags, matchId, limit }),
    });
  } catch (e) {
    console.log(e);
    return;
  }
};
