import { Navigate, Route, Routes } from 'react-router-dom';
import { Checkout } from './Checkout';
import { CheckoutFailed } from './CheckoutFailed';
import { CheckoutSuccess } from './CheckoutSuccess';
import { useRouteStore } from '@/stores/route';

type PaymentRoutesProps = {
  authenticated?: boolean;
};

export const PaymentRoutes = ({ authenticated }: PaymentRoutesProps) => {
  const { prevRoute, setPrevRoute } = useRouteStore();
  return (
    <Routes>
      <Route path="/" element={<Checkout />} />
      <Route path="checkout" element={<Checkout />} />
      <Route path="success" element={<CheckoutSuccess />} />
      <Route path="fail" element={<CheckoutFailed />} />
      <Route path="*" element={<Navigate to={prevRoute ? prevRoute : '/'} />} />
    </Routes>
  );
};
