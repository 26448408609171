import { Carousel } from '@mantine/carousel';
import { Box, createStyles, Image, getStylesRef } from '@mantine/core';

const useStyles = createStyles((_theme, _params) => ({
  controls: {
    ref: getStylesRef('controls'),
    transition: 'opacity 150ms ease',
    opacity: 0,
  },

  root: {
    '&:hover': {
      [`& .${getStylesRef('controls')}`]: {
        opacity: 1,
      },
    },
  },
}));

export const Showcase = ({ images }: { images: string[] | undefined; }) => {
  const { classes } = useStyles();
  const slides =
    images && images.length > 0 ? (
      images.map((url) => (
        <Carousel.Slide key={url} sx={{ margin: 'auto' }}>
          <Image src={url} imageProps={{
            style: {
              maxHeight: '240px',
              objectFit: 'contain',
            }
          }} />
        </Carousel.Slide>
      ))
    ) : (
        <Carousel.Slide sx={{ margin: 'auto ' }}>
          <Image src="https://via.placeholder.com/500x200?text=Showcase" />
      </Carousel.Slide>
    );
  return (
    <Carousel w="100%" mx="auto" classNames={classes} loop withIndicators height='100%' mb="md" sx={{ background: 'black' }}>
        {slides}
    </Carousel>
  );
};
