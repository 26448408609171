import { Navigate, Route, Routes } from 'react-router-dom';
import { Theory } from './Theory';

type TheoryRoutesProps = {
};

export const TheoryRoutes = ({ }: TheoryRoutesProps) => {
  return (
    <Routes>
      <Route path="/" element={<Theory />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
};
