import { Card, createStyles, Text } from '@mantine/core';

type SlotTimeCardProps = {
  selected?: boolean;
  id?: string;
  time?: string;
  isExpired?: boolean;
  handleSelect: (time?: string) => void;
};

const useStyles = createStyles((theme) => ({
  card: {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: 80,
    transition: '0.3s',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.colors.primary[1],
      color: theme.colors.primary[3],
    },
  },
}));

export const SlotTimeCard = ({ selected, id, time, isExpired, handleSelect }: SlotTimeCardProps) => {
  const { classes, cx } = useStyles();

  return (
    <Card
      id={id}
      className={cx(classes.card)}
      shadow="sm"
      p="md"
      onClick={() => {
        if (!isExpired) {
          handleSelect(time);
        }
      }}
      radius="md"
      withBorder
      sx={(theme) => ({
        backgroundColor: selected ? theme.colors.primary[1] : 'transparent',
        color: selected ? theme.colors.primary[3] : 'inherit',
        opacity: isExpired ? 0.5 : 1,
        pointerEvents: isExpired ? 'none' : 'auto',
      })}
    >
      <Card.Section>
        <Text weight={500} size={14} p="xs" align="center">
          {time}
        </Text>
      </Card.Section>
    </Card>
  );
};
