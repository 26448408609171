import { createStyles, Flex, TextInput, UnstyledButton } from "@mantine/core";
import { useEffect, useCallback } from 'react';
import { Box } from "@mantine/core";
import searchIcon from '@/assets/NavigationBar/search.svg';
import { IconArrowLeft } from "@tabler/icons-react";
import storage from "@/utils/storage";
import { useNavigate } from "react-router-dom";
import { useSearchStore } from "@/stores/search";
import { useRouteStore } from "@/stores/route";

type SearchInputBoxProps = {
  searchOpened: boolean;
  setSearchOpened: (searchOpened: boolean) => void;
};

const useStyle = createStyles((theme) => ({
  container: {
    width: 20,
    boxSizing: 'border-box',
    transition: 'all 0.3s',
    transitionTimingFunction: 'ease',
  },
  searchInput: {
    width: '100%',
  },
  linkIcon: {
    width: 25,
    height: 25,
    filter: theme.colorScheme === 'dark' ? 'invert(100%)' : 'none',
  }
}));

export const SearchInputBox = ({ searchOpened, setSearchOpened }: SearchInputBoxProps) => {

  const { classes, cx } = useStyle();
  const navigate = useNavigate();
  const { searchInput, searching, setSearching, page, clearPage, clearLimit, searchResults, clearSearchResults, clearLoveFactor, clearDate, clearLocation, clearSort } = useSearchStore();
  const { prevRoute } = useRouteStore();

  if (!searchOpened && searchInput.current && searchInput.current?.value.length > 0) {
    searchInput.current.value = '';
  }

  const reset = () => {
    clearSearchResults();
    clearLimit();
    clearPage();
    clearLoveFactor();
    clearDate();
    clearLocation();
    clearSort();
  };

  const handleSearch = () => {
    clearSearchResults();
    clearLimit();
    clearPage();
    if (searchOpened) {
      !searching && setSearching(true);
    }
  };

  const handleKeyDown = useCallback((e) => {
    const main = document.getElementById('main');
    if (main) {
      if (e.key == 'Escape') {
        searchInput.current?.blur();
        reset();
        setSearchOpened(false);
        if (window.location.pathname.includes('/search')) {
          navigate('/');
        }
      }
      if (e.key == 'Enter') {
        handleSearch();
      }
    }
  }, [searchOpened]);

  useEffect(() => {
    const main = document.getElementById('main');
    if (main) {
      main.addEventListener('keydown', handleKeyDown, { passive: true });
    }
    return () => {
      if (main) {
        main.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, [searchOpened]);

  return (
    <>
      {
        searchOpened &&
        <UnstyledButton
            onClick={async () => {
            searchInput.current?.blur();
              reset();
              if (window.location.pathname.includes('/search') && prevRoute) {
                navigate(prevRoute);
              } else {
                setSearchOpened(!searchOpened);
            }
          }}
          mr='xs'
        >
          <Flex direction='column' justify="center">
            <IconArrowLeft size={20} />
          </Flex>
        </UnstyledButton>
      }
      <Box className={cx(classes.container)}
        sx={{
          width: searchOpened ? '100%' : '30px',
          padding: 0,
        }}
      >
        <TextInput
          ref={searchInput}
          className={cx(classes.searchInput)}
          placeholder="Search activities, locations or others..."
          radius={50}
          icon={
            searchOpened ?
              null :
              <img
                alt="search"
                src={searchIcon}
                className={cx(classes.linkIcon)}
              />
          }
          sx={(theme) => ({
            '.mantine-Input-input': {
              cursor: searchOpened ? 'text' : 'pointer',
              border: searchOpened ? `1px solid ${theme.colors.primary[3]}` : 'none',
              width: searchOpened ? '100%' : '30px',
              transition: 'all 0.1s',
              paddingRight: 0,
              '@media (max-width: 425px)': {
                fontSize: '10px'
              }
            },
          })}
          onFocus={() => {
            if (!searchOpened) {
              setSearchOpened(!searchOpened);
            }
          }}
          rightSectionWidth={30}
          rightSection={
            searchOpened &&
            <UnstyledButton
              fz='xs'
              mr={16}
              onClick={handleSearch}
              sx={{
                display: searchOpened ? 'block' : 'none',
                transition: 'all 0.1s',
              }}
            >
              <img
                alt="search"
                src={searchIcon}
                className={cx(classes.linkIcon)}
              />

            </UnstyledButton>
          }
        />
      </Box>
    </>
  );
};
