import { useState } from 'react';

interface TimerProps {
  time: number;
  setTimeLeft: (time: number) => void;
}

export const timer = ({ time, setTimeLeft }: TimerProps) => {
  const countDownDate = new Date().getTime() + time;

  const _timer = setInterval(function () {
    const now = new Date().getTime();
    const distance = countDownDate - now;
    let seconds = Math.ceil((distance % (1000 * 60)) / 1000);
    if (seconds < 0) {
      seconds = 0;
    }
    setTimeLeft(seconds);
    if (distance < 0) {
      clearInterval(_timer);
    }
  }, 1000);
};
