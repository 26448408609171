import { MainLayout } from '@/components/Layout';
import { AuthRoutes } from '@/features/auth';
import { Navigate, Outlet, RouteObject, useNavigate } from 'react-router-dom';
import { EventsRoutes } from '@/features/events';
import { AccountRoutes } from '@/features/account';
import { PaymentRoutes } from '@/features/payment';
import { HeadlessLayout } from '@/components/Layout/HeadlessLayout';
import { BookingsRoutes } from '@/features/bookings';
import { authenticate } from '../test/server/utils';
import { Landing } from '@/features/events/routes/Landing';
import { TheoryRoutes } from '@/features/theory';
import storage from '@/utils/storage';
import { Search } from '@/features/search/routes/Search';
import { useRouteStore } from '@/stores/route';
import { SavedEventsRoutes } from '@/features/savedEvents';
import { PrivacyPolicy } from '@/features/misc/routes/PrivacyPolicy';

const App = () => {
  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};

const Headless = () => {
  return (
    <HeadlessLayout>
      <Outlet />
    </HeadlessLayout>
  );
};

type RouteProps = {
  authenticated?: boolean;
  sessionHistory?: string | undefined;
};

const routes = ({ authenticated }: RouteProps) => {
  const { prevRoute, setPrevRoute, setRedirectRoute } = useRouteStore();
  const navigate = useNavigate();

  if (window.location.pathname !== '/auth' && !window.location.pathname.includes('/search')) {
    if (prevRoute !== window.location.pathname && prevRoute !== '/auth/success') {
      setPrevRoute(window.location.pathname);
    }
    if (prevRoute == '/auth/success' && window.location.pathname == '/account') {
      setPrevRoute('/account');
      setRedirectRoute('/');
    }
  }

  const routes: RouteObject[] = [
    {
      path: '/auth/*',
      element: <Headless />,
      children: [
        {
          path: '*', element: <AuthRoutes authenticated={authenticated} />
        },
      ]
    },
    {
      path: '/theory-of-love/*',
      element: <Headless />,
      children: [
        { path: '*', element: <TheoryRoutes /> },
      ]
    },
    {
      path: '/',
      element: <App />,
      children: [
        { path: '/', element: <Landing /> },
        { path: '/events/*', element: <EventsRoutes authenticated={authenticated} /> },
        { path: '*', element: <Navigate to={'/'} /> }
      ]
    },
    {
      path: '/account/*',
      element: <Headless />,
      children: [
        { path: '*', element: <AccountRoutes authenticated={authenticated} /> },
      ]
    },
    {
      path: '/payment/*',
      element: <Headless />,
      children: [
        { path: '*', element: <PaymentRoutes authenticated={authenticated} /> }
      ]
    },
    {
      path: '/bookings/*',
      element: <Headless />,
      children: [
        { path: '*', element: <BookingsRoutes authenticated={authenticated} /> }
      ]
    },
    {
      path: '/saved-events/*',
      element: <Headless />,
      children: [
        { path: '*', element: <SavedEventsRoutes authenticated={authenticated} /> }
      ]
    },
    {
      path: '/search/*',
      element: <App />,
      children: [
        { path: '*', element: <Search /> }
      ]
    },
    {
      path: '/privacy-policy',
      element: <PrivacyPolicy />
    }
  ];
  return routes;
};

export default routes;