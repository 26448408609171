import { Badge, Box, Card, createStyles, Flex, Image, Loader, Stack, Text, useMantineTheme } from '@mantine/core';
import arrowLeft from '@/assets/Event/arrow.svg';

type EventSeeMoreCardProps = {
  ml?: number;
  width?: number | string;
  onClick: (arg0: any) => void;
};

const useStyles = createStyles(() => ({
  // card: {
  // },
}));

export const EventSeeMoreCard = ({
  width,
  ml,
  onClick,
}: EventSeeMoreCardProps) => {
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.colors.primary[0],
        borderRadius: 8,
        height: 220,
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: width ? width : 160,
        cursor: 'pointer',
        userSelect: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      ml={ml !== undefined ? ml : 32}
      onClick={() => {
        onClick({});
      }}
    >
      <Stack spacing='xs'>
        <Text size='md' color='primary.3' weight={700}>See More</Text>
        <Image
          src={arrowLeft}
          width={50}
          m='auto'
        />
      </Stack>
    </Box>
  );
};
