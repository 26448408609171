import { Carousel, Embla } from "@mantine/carousel";
import { Avatar, createStyles, Flex, Tabs, Text, Image, Button, Box } from "@mantine/core";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { EventCard } from "./EventCard";
import EventsCarousel from "./EventsCarousel";
import { EventSeeMoreCard } from "./EventSeeMoreCard";

type TypeTabsProps = {
  types: TypeTabProps[];
  defaultValue?: string;
  snap: boolean;
};

export type TypeTabProps = {
  type: string;
  display: string;
  cover?: string;
  onTabClick?: () => void;
  onCardClick: (arg0: any) => void;
  active: boolean;
  activeType: 'underline' | 'outline' | string;
  events?: any;
};

const useStyles = createStyles((_theme, _params) => ({
  tabs: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: _theme.spacing.md,
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: _theme.spacing.md,
    flexWrap: 'nowrap',
    WebkitOverflowScrolling: 'touch',
    overflowX: 'auto',
    scrollbarWidth: 'none',
    '::webkit-scrollbar': {
      display: 'none',
    },
    '::MsOverflowStyle': 'none',
  },
  tab: {
    border: 'none',
    borderRadius: _theme.radius.xl,
    background: 'none',
    padding: 'none',
    cursor: 'pointer'
  },
  displayName: {
    fontSize: 14,
    '@media (max-width: 425px)': {
      fontSize: 12,
    }


  },
  icon: {
    '.mantine-Image-root': {
      paddingBottom: 4,
    },
    '.mantine-Image-imageWrapper': {
      width: 30,
      margin: 'auto'
    },
    '@media (max-width: 425px)': {
      '.mantine-Image-imageWrapper': {
        width: 25,
      },
    }

  }
}));

const TypeTabs = ({ types, defaultValue, snap }: TypeTabsProps) => {
  const { classes, cx } = useStyles();
  const [activeTab, setActiveTab] = useState<string | null | undefined>(defaultValue);
  const navigate = useNavigate();

  return (
    <Tabs
      unstyled
      defaultValue={activeTab}
      value={activeTab}
      onTabChange={(v) => {
        if (activeTab == v) return;
        else setActiveTab(v);
      }}
      keepMounted={false}
    >
      <Tabs.List className={cx(classes.tabs)}>
        {
          types.map(({ active, type, onTabClick, activeType, cover, display }: TypeTabProps, i) => {
            if (active) {
              return (
                <Tabs.Tab
                  key={i}
                  value={type}
                  className={cx(classes.tab)}
                  onClick={onTabClick}
                >
                  <Flex
                    direction="column"
                    justify="center"
                    align="center"
                    p="md"
                    sx={{
                      '@media (max-width: 425px)': {
                        paddingLeft: 8,
                        paddingRight: 8,
                      }
                    }}
                  >
                    {cover ?
                      <Box
                        sx={{
                          filter: activeTab == type ? 'none' : 'grayscale(100%)',
                          ':hover': {
                            filter: 'none',
                            transition: 'all 0.3s ease',
                          }
                        }}
                      >
                        <Image
                          src={cover}
                          className={cx(classes.icon)}
                        />
                        <Text
                          weight={700}
                          color='primary.3'
                          align="center"
                          className={cx(classes.displayName)}
                        >
                          {display}
                        </Text>
                      </Box>
                      :
                      <Box
                        sx={{
                          filter: activeTab == type ? 'none' : 'grayscale(100%)',
                          ':hover': {
                            filter: 'none',
                            transition: 'all 0.3s ease',
                          }
                        }}>
                        <Avatar
                          radius={100}
                          mb={4}
                          h={50}
                          w={50}
                          color="primary.2"
                          sx={(theme) => ({
                            margin: 'auto',
                            border: activeTab == type && activeType == 'outline' ? `solid 3px ${theme.colors.primary[2]}` : 'none',
                          })}
                        >
                          {
                            type.slice(0, 1).toUpperCase()
                          }
                        </Avatar>
                        <Text
                          weight={700}
                          color='primary.3'
                          align="center"
                          className={cx(classes.displayName)}
                        >
                          {display}
                        </Text>
                      </Box>
                    }
                  </Flex>
                </Tabs.Tab>
              );
            }
          })
        }
      </Tabs.List>

      {
        types.map(({ type, onCardClick, events }: TypeTabProps, i) => {
          const slides = events && events.length > 0 ?
            events.map((event: any, i: number) => {

              return (
                <Carousel.Slide key={`${type}-${i}`}
                >
                  {
                    i == events.length - 1 && events.length % 2 == 1 ?
                      <Flex
                        sx={{
                          flexGrow: 1,
                        }}
                      >
                        <EventCard
                          key={`${type}-${i}-card`}
                          snap={snap}
                          onClick={() => onCardClick(event.Snag)}
                          width={'calc(100% - 8px)'}
                          name={event.Name}
                          startDate={event.StartDate}
                          count={event.Count}
                          packageCurrency={event.PackageCurrency}
                          packagePrice={event.PackagePrice}
                          snag={event.Snag}
                          pictures={event.Pictures && event.Pictures.length > 0 ? event.Pictures[0] : undefined}
                          cardHeight={120}
                        />
                        <EventSeeMoreCard
                          onClick={() => {
                            navigate({
                              pathname: '/search',
                              search: `?love-factor=${type}`,
                            })
                          }}
                          width={'calc(100% - 8px)'}
                        />
                      </Flex>
                      :
                      <EventCard
                        key={`${type}-${i}-card`}
                        snap={snap}
                        onClick={() => onCardClick(event.Snag)}
                        width={'calc(50% - 8px)'}
                        name={event.Name}
                        startDate={event.StartDate}
                        count={event.Count}
                        packageCurrency={event.PackageCurrency}
                        packagePrice={event.PackagePrice}
                        snag={event.Snag}
                        pictures={event.Pictures && event.Pictures.length > 0 ? event.Pictures[0] : undefined}
                        cardHeight={120}
                      />

                  }
                </Carousel.Slide>
              );
            })
            :
            <Flex
              justify="center"
              align="center"
              p="md"
              w="100%"
            >
              <Text
                weight={700}
                align="center"
              >
                No suggested events
              </Text>
            </Flex>;

          return (
            <Tabs.Panel
              key={i}
              value={type}
            >
              {
                events && <EventsCarousel
                  events={events}
                  slideSize="50%"
                  slideGap={2}
                  slideToScroll={2}
                >
                  {slides}
                </EventsCarousel>
              }

            </Tabs.Panel>
          );
        })
      }
    </Tabs>
  );
};

export default TypeTabs;