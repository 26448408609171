import { Box } from "@mantine/core";
import { useNavigate } from 'react-router-dom';
import { EventCard } from '@/features/events/components/EventCard';

type SavedEventsListProps = {
  savedEvents: any[];
};

export const SavedEventsList = ({
  savedEvents,
}: SavedEventsListProps) => {
  const navigate = useNavigate();

  return (
    <>
      {
        savedEvents.map((event, i) => {
          return (
            <Box px="lg" py="xl">
              <EventCard
                key={`${i}-saved-event`}
                onClick={() => {
                  navigate(`/events/${event.Snag}`);
                }}
                width='100%'
                name={event.Name}
                startDate={event.CreatedAt}
                count={event.Count}
                packageCurrency={event.PackageCurrency}
                packagePrice={event.PackagePrice}
                snag={event.Snag}
                pictures={event.Pictures && event.Pictures.length > 0 ? event.Pictures[0] : undefined}
                spotsAvailable={event.SpotsAvailable}
                cardHeight={200}
                tags={event.Tags}
              />
            </Box>
          );
        })
      }
    </>

  );
};