import { createStyles, Text, Grid, Loader, Box, Title, Avatar, Stack } from '@mantine/core';
import GoogleMapReact from 'google-map-react';

import facebook from '@/assets/Brand/facebook.svg';
import whatsapp from '@/assets/Brand/whatsapp.svg';
import instagram from '@/assets/Brand/instagram.svg';
import { GOOGLE_MAPS_API_KEY } from '@/config';

import { Contacts } from '../types';

type OrganizerProps = {
  logo?: string;
  title?: string;
  description?: string;
  contacts?: Contacts[];
  address?: string;
  lat?: number;
  lng?: number;
};

const useStyles = createStyles({
  avatar: {
    padding: 0,
    width: 60,
    height: 60,
    margin: 'auto',
    [`@media (max-width: 425px)`]: {
      width: 40,
      height: 40,
    },
  },
});

const contactInfo: any = {
  fb: {
    src: facebook,
    alt: 'facebook',
  },
  ig: {
    src: instagram,
    alt: 'instagram',
  },
  whatsapp: {
    src: whatsapp,
    alt: 'whatsapp',
  },
};

export const Organizer = ({
  logo,
  title,
  description,
  contacts,
  address,
  lat,
  lng,
}: OrganizerProps) => {
  const { classes, cx } = useStyles();

  return (
    <Grid>
      <Grid.Col span={2} pt={6} p="md">
        <Avatar
          src={logo}
          alt={title}
          radius={50}
          color="primary.3"
          className={cx(classes.avatar)}
        />
      </Grid.Col>
      <Grid.Col span={10} pt={0}>
        <Stack spacing={6}>
          {title ? <Title order={5}>{title}</Title> : <Loader color="gray" variant="dots" />}
          {description ? (
            <Text fz={16}>{description}</Text>
          ) : (
            <Loader color="gray" variant="dots" />
          )}
          {contacts && contacts.length > 0 ? (
            <Grid gutter={8}>
              {contacts?.map((contact, i) => (
                <Grid.Col span={1} key={i}>
                  <a href={contact.href} target="_blank" rel="noopener noreferrer">
                    <img
                      src={contactInfo[contact?.type as keyof any].src}
                      alt={contactInfo[contact?.type as keyof any].alt}
                      width={18}
                      height={18}
                    />
                  </a>
                </Grid.Col>
              ))}
            </Grid>
          ) : (
            <Loader color="gray" variant="dots" />
          )}
        </Stack>
      </Grid.Col>
      <Grid.Col span={12}>
        <Stack spacing={8}>
          {address ?
            <>
            <Title order={5}>Address</Title>
              <Text>{address}</Text>
            </> : <></>
          }
          {
            lat && lng ?
          <Box pb="md" h={250}>
                <GoogleMapReact
                bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
                defaultCenter={{
                  lat,
                  lng,
                }}
                defaultZoom={16}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) => {
                  return new maps.Marker({
                    position: { lat, lng },
                    map,
                  });
                }
                }
                />

              </Box> : <></>
          }
        </Stack>
      </Grid.Col>
    </Grid>
  );
};
