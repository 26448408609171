import { Title, Text, Stack, Flex, UnstyledButton, createStyles, NumberInput, Input, PinInput, Group, Box } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useLocation, useNavigate } from 'react-router-dom';

import google from '@/assets/Brand/google.svg';
import phone from '@/assets/Account/phone.svg';

import { HeadlessLayout } from '@/components/Layout/HeadlessLayout';
import auth from '@/lib/firebase';
import storage from '@/utils/storage';
import { Head } from '@/components/Head';
import { ActionButton } from '../components/ActionButton';
import { useEffect, useState } from 'react';
import { useGetUser } from '../api/getUser';
import { HeadlessNavigation } from '@/components/HeadlessNavigation';
import { BaseButton } from '@/components/BaseButton';
import { useVerifySMSVerificationCode } from '../api/verifySMSVerificationCode';
import { useSMSConfirmationStore, useUserInfoStore } from '@/stores/authentication';
import { DefaultLoadingOverlay } from '@/components/DefaultLoadingOverlay';
import { timer } from '@/utils/timer';

const useStyles = createStyles((theme) => ({
}));

export const AuthPhoneVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { classes, cx } = useStyles();

  const { confirmationResult, setConfirmationResult, clearConfirmationResult } = useSMSConfirmationStore();

  const [user] = useAuthState(auth);
  const { setUserInfo } = useUserInfoStore();

  const [verificationCode, setVerificationCode] = useState('');
  const [isVerifying, setIsVerifying] = useState(false);
  const [isError, setIsError] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [timeLeft, setTimeLeft] = useState(3);
  const [verifiedData, setVerifiedData] = useState<any>(null);

  const handleSMSVerificationInput = (value: string) => {
    setIsVerifying(false);
    if (value.length == 6 && isError) setIsError(false);
    setVerificationCode(value)
  }

  const handleSMSVerificationSubmit = () => {
    if (verificationCode.length < 6) {
      setIsError(true);
      return;
    }
    if (verificationCode.length == 6) {
      setIsError(false);
      setIsVerifying(true);
    }
  };

  useEffect(() => {
    if (!confirmationResult) navigate('/auth/signup');
    if (verifiedData) setVerifiedData(null);
  }, [navigate])


  useVerifySMSVerificationCode({
    verificationCode: verificationCode,
    confirmationResult: confirmationResult,
    config: {
      enabled: verificationCode.length == 6 && isVerifying == true,
      retry: false,
      onSuccess: (r) => {
        setIsVerifying(false);
        setIsError(false);
        setVerificationCode('');
        setVerifiedData(r);
        setConfirmationResult(null);
        setRedirecting(true);
      },
      onError: (e: any) => {
        setIsVerifying(false);
        setIsError(true);
        setVerificationCode('');
        setVerifiedData(null);
      }
    },
  });

  useGetUser({
    user: user,
    config: {
      enabled: !!user && !!verifiedData,
      retry: false,
      onSuccess: async (data) => {
        if (data) {
          setVerifiedData(null);
          timer({
            time: 3000,
            setTimeLeft,
          });
          setTimeout(() => {
            setUserInfo(data);
          }, 4000);
          setTimeout(() => {
            setRedirecting(false);
            navigate('/auth/success', {
              state: {
                from: 'login',
              },
            });
          }, 4000);
        }
      },
      onError: (e: any) => {
        setVerifiedData(null);
        if (e && e.response.data.message == 'user not found') {
          timer({
            time: 3000,
            setTimeLeft,
          });
          setTimeout(() => {
            setRedirecting(false);
            navigate('/auth/signup/details', {
              state: {
                method: 'Phone',
              }
            });
          }, 4000);
        }
        else {
          setRedirecting(false);
        }
      }
    },
  });

  return (
    <HeadlessNavigation back={1}>
      <DefaultLoadingOverlay loading={redirecting} text={timeLeft > 0 ? `Logging in... Redirecting in ${timeLeft}` : 'Redirecting...'} />
      <Stack p="xl" spacing="md">
        <Title order={3}>Enter verification code</Title>
        <Text fz={18}>We sent it to the number {location.state && location.state.phoneNumber}. You can send another code in 30 seconds.</Text>
      </Stack>
      <Box p="xl" mt={40}>
        <Group position="center" h={50} mb={90}>
          <PinInput
            length={6}
            value={verificationCode}
            autoFocus
            oneTimeCode
            size='xl'
            placeholder=''
            type={/^[0-9]+/}
            inputType="tel"
            inputMode="numeric"
            onChange={handleSMSVerificationInput}
            error={isError}
          />
        </Group>
        <BaseButton content="Submit" onClick={handleSMSVerificationSubmit} fullWidth />
      </Box>
    </HeadlessNavigation>
  );
};
