import { DefaultFooter } from '@/components/Footer';
import { Head } from '@/components/Head';
import { Image, Flex, Text, Box, Button, SimpleGrid } from '@mantine/core';
import { useState, useEffect, useRef, useCallback } from 'react';
import { EventCard } from '../components/EventCard';
import { useNavigate } from 'react-router-dom';
import { EventsData } from '../types';
import { useEvents } from '../api/getEvents';
import { DefaultLoadingOverlay } from '@/components/DefaultLoadingOverlay';
import { motion, useMotionValue, useTransform } from 'framer-motion';

import heroTagline from '@/assets/Hero/hero-tagline.svg';
import heroCouples from '@/assets/Hero/couples.png';
import { BaseButton } from '@/components/BaseButton';

export const Landing = () => {
  const navigate = useNavigate();

  const [allPage, setAllPages] = useState(1);
  const [allLimit, setAllLimit] = useState(5);

  const allQuery = useEvents({
    limit: allLimit,
    skip: (allPage - 1) * allLimit,
    config: {
    }
  });

  const scrollY = useMotionValue(0);


  const y0 = useTransform(scrollY, [0, 100], [-120, -250]);
  const y1 = useTransform(scrollY, [0, 100], [0, -15]);
  const y2 = useTransform(scrollY, [0, 400], [160, 160]);
  const y3 = useTransform(scrollY, [0, 400], [0, -50]);
  const y4 = useTransform(scrollY, [0, 0], [0, 0]);

  const all = allQuery?.data;


  const handleScroll = useCallback(() => {
    const main = document.getElementById('main');
    if (main) {
      scrollY.set(main.scrollTop);
    }
  }, [document.getElementById('main')]);

  useEffect(() => {
    const main = document.getElementById('main');
    if (main) {
      main.addEventListener('scroll', handleScroll, { passive: true });
    }
    return () => {
      if (main) {
        main.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <Flex
      direction="column"
      justify='space-between'
      sx={{ flexGrow: 1 }}
    >
      <Head title="Welcome" headDescription="OMD" />
      <DefaultLoadingOverlay loading={!all} />
      <Flex justify='start' direction="column" w='100vw' maw='540px'>
        {/* <motion.div style={{ y: y1 }}> */}
        <Box
          p={50}
          sx={(theme) => ({
            height: 425,
            background: theme.colors.primary[0],
            clipPath: 'circle(355px at 50% 15%)',
            width: '100%',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          })}
        >
          <motion.div style={{
            // y: y1
          }}>
            <Image src={heroTagline} sx={{ width: '100%', minWidth: '250px', alignSelf: 'center' }} />
            <BaseButton
              content="Explore more events"
              onClick={() => navigate('/events')}
              style={{ margin: 'auto', maxWidth: '250px', minWidth: '200px', alignSelf: 'center', marginTop: 20 }}
            />
          </motion.div>
          <motion.div style={{
            y: y2
          }}>
            <Flex
              sx={{
                position: 'absolute',
                bottom: 0,
                zIndex: -1,
                width: '100%',
                ['@media screen and (max-width: 570px)']: {
                  bottom: -12.5,
                }
              }}
              justify='center'
            >
              <Image
                src={heroCouples}
                sx={{
                  maxWidth: '250px',
                  minWidth: '250px',
                }}

              />
            </Flex>
          </motion.div>

        </Box>
        {/* </motion.div> */}

        {/* <motion.div style={{ y: y3 }}> */}
          <Box p='md'>
          {all?.data && all?.data?.Events.length > 0 ?
            <motion.div style={{
              // y: y3
            }}>
              <SimpleGrid cols={2} spacing='lg'>
                {
                  all?.data?.Events?.map((event: EventsData, i) => {
                    return <EventCard
                      snap
                      width={250}
                      key={i}
                      startDate={event.StartDate}
                      endDate={event.EndDate}
                      name={event.Name}
                      packageCurrency={event.PackageCurrency}
                      packagePrice={event.PackagePrice}
                      snag={event.Snag}
                      spotsAvailable={event.SpotsAvailable}
                      pictures={event.Pictures && event.Pictures.length > 0 ? event.Pictures[0] : null}
                      style={{
                        marginBottom: 36,
                      }}
                      onClick={() => {
                        navigate('/events/' + event.Snag);
                      }
                      }
                    />;
                  })
                }
              </SimpleGrid>
            </motion.div>
              :
              <Flex
                justify="center"
                align="center"
                p="md"
                w="100%"
              >
                <Text
                  weight={700}
                  align="center"

                >
                  No events
                </Text>
              </Flex>
            }
          </Box>
        {/* </motion.div> */}
      </Flex>
      {/* <motion.div style={{ y: y4 }}> */}
      <DefaultFooter />
      {/* </motion.div> */}
    </Flex>
  );
};
