import { createStyles, Drawer, Flex, useMantineTheme, UnstyledButton, Button, Stack, Title, Text, Group, Divider, Box, Footer, rem } from '@mantine/core';
import { useEffect, useState } from 'react';
import { IconMinus, IconPlus, IconX } from '@tabler/icons-react';
import { Event, Solt } from '../types';
import person from '@/assets/NavigationBar/person.svg';
import { BookingFooter } from './BookingFooter';

type ConfirmBookingProps = {
  open: boolean;
  selectedPackageType: Solt | undefined;
  selectedDate: string | undefined;
  selectedTime: string | undefined;
  quantity: number;
  onClose: () => void;
  setQuantity: (value: number) => void;
  setConfirmBooking: (value: boolean) => void;
  handleConfirmBooking: () => void;
};

const useStyles = createStyles((theme) => ({
  clear: {
    opacity: 0.5,
    transition: '0.3s',
    '&:hover': {
      color: theme.colors.primary[3],
    },
  },
  drawerWrapper: {
    height: 650,
    '@media (max-width: 425px)': {
      height: '100vh'
    }
  },
  drawerInner: {
    margin: 'auto',
    marginBottom: 0,
    width: theme.breakpoints.xs,
    '@media (max-width: 540px)': {
      width: '100%'
    }
    },
  drawerContent: {
    height: 650,
    overflow: 'hidden',
    borderRadius: '8px 8px 0 0',
    '@media (max-width: 425px)': {
      height: '100vh'
    }
    },
  drawerHeader: {
    display: 'none'
  },
  drawerBody: {
    padding: 0,
    minHeight: 650,
    '@media (max-width: 425px)': {
      height: '100vh'
    }
  },
  drawerClose: {
    display: 'none',
  },
}));

export const ConfirmBooking = ({ open, onClose, setConfirmBooking, selectedDate, selectedPackageType, selectedTime, quantity, setQuantity, handleConfirmBooking }: ConfirmBookingProps) => {
  const { classes, cx } = useStyles();
  const theme = useMantineTheme();

  return (
    <Drawer
      overlayProps={{
        color: theme.colors.dark[9],
        opacity: 0.55,
        blur: 3,
      }}
      position="bottom"
      opened={open}
      onClose={onClose}
      classNames={{
        inner: cx(classes.drawerInner),
        header: cx(classes.drawerHeader),
        content: cx(classes.drawerContent),
        body: cx(classes.drawerBody),
        close: cx(classes.drawerClose),
      }}
      // shadow="md"
      closeOnClickOutside
    >
      <Flex justify='space-between' direction='column' className={cx(classes.drawerWrapper)}>
        <Stack spacing="md">
          <div>
            <Flex p={rem(27)} pb={0}>
              <UnstyledButton
                w={40}
                className={cx(classes.clear)}
                onClick={() => setConfirmBooking(false)}
              >
                <IconX size={24} />
              </UnstyledButton>
              <Text w='100%' ta='center' fw={500} fz={20}>
                Booking
              </Text>

            </Flex>
            <Divider mt="xl" />
          </div>
          <Title order={3} fw={700} fz={18} px={rem(27)}>
            {selectedPackageType?.PackageName}
          </Title>
          <Text fz={16} fw={700} px={rem(27)}>
            <Text inherit fz={14} fw={400}>Date</Text>
            {selectedDate}
          </Text>
          <Text fz={16} fw={700} px={rem(27)}>
            <Text inherit fz={14} fw={400}>Time</Text>
            {selectedTime}
          </Text>
          <Text fz={16} fw={700} px={rem(27)}>
            <Text inherit fz={14} fw={400}>Package Info</Text>
            {selectedPackageType?.Info}
          </Text>
          <Divider mt="xl" />
          <Flex justify='space-between' mt="xl" px={rem(27)}>
            <Text fz={16} fw={700}>
              <Text inherit fz={14} fw={400}>Quantity</Text>
              {selectedPackageType?.PackagePrice} {selectedPackageType?.PackageCurrency} / {selectedPackageType?.PackagePerUnit}
            </Text>
            <Group>
              <Button
                variant="outline"
                color="dark"
                size="sm"
                p="xs"
                radius="sm"
                onClick={() => quantity > 0 && setQuantity(quantity - 1)}
                disabled={quantity <= 0}
              >
                <IconMinus size={16} />
              </Button>
              <Text fz={16} fw={700}>
                {quantity}
              </Text>
              <Button
                variant="filled"
                color="dark"
                size="sm"
                p="xs"
                radius="sm"
                onClick={() => setQuantity(quantity + 1)}
                disabled={selectedPackageType?.SpotsAvailable ? quantity >= selectedPackageType?.SpotsAvailable : true}
              >
                <IconPlus size={16} />
              </Button>
            </Group>
          </Flex>
        </Stack>
        <BookingFooter
          quantity={quantity}
          currency={selectedPackageType?.PackageCurrency}
          price={selectedPackageType?.PackagePrice}
          onSubmit={handleConfirmBooking}
        />
      </Flex>
    </Drawer>
  );
};
