import { Flex, Stack, Title, UnstyledButton, Image, Text } from "@mantine/core";
import { useNavigate } from 'react-router-dom';
import { Head } from '@/components/Head';
import { IconArrowLeft } from "@tabler/icons-react";
import { TriangleInfographic } from "../components/TriangleInfographic";
import { HeadlessNavigation } from "@/components/HeadlessNavigation";

export const Theory = () => {
  const navigate = useNavigate();

  return (
    <HeadlessNavigation>
      <Flex direction='column'>
        <Text size={24} fw={500} p='md' pt={0}>Triangular Theory of Love</Text>
        <TriangleInfographic />
        <Text align="left" p="md" pb={0} fz={16}>
          The triangular theory of love is a theory of love developed by Robert Sternberg. The three components of love are <Text inherit span color="#B55D49" fw={500}>intimacy</Text>, <Text inherit span color="#B55D49" fw={500}>passion</Text> and <Text inherit span color="#B55D49" fw={500}>commitment</Text>.
        </Text>
        <Text align="left" p="md" pb={0} fz={16}>
          <Text inherit span color="#B55D49" fw={500}>Intimacy</Text> involves feelings of closeness, connectedness, and bondedness.
        </Text>
        <Text align="left" p="md" pb={0} fz={16}>
          <Text inherit span color="#B55D49" fw={500}>Passion</Text> involves feelings and desires that lead to physical attraction, romance, and sexual consummation.
        </Text>
        <Text align="left" p="md" pb={0} fz={16}>
          <Text inherit span color="#B55D49" fw={500}>Commitment</Text> involves feelings that lead a person to remain with someone and move toward shared goals.
        </Text>
      </Flex>
    </HeadlessNavigation>
  );
};