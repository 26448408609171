import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface BookingStore {
  booking: any;
  setBooking: (booking: any) => void;
}

export const useBookingStore = create(
  persist(
    (set) => ({
      booking: {},
      setBooking: (booking: any) => set({ booking }),
      clearBooking: () => set({ booking: {} }),
    }),
    {
      name: 'evol_app_booking',
      getStorage: () => sessionStorage, // Use sessionStorage or localStorage
      onRehydrateStorage: () => (state: any) => {
        state.setHasHydrated(true);
      },
    }
  )
);
