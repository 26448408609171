import { createStyles, Footer, UnstyledButton, Text, Grid, Loader } from '@mantine/core';

type BookingFooterProps = {
  quantity: number;
  currency?: string;
  price?: string;
  onSubmit?: () => void;
};

const useStyles = createStyles((theme) => ({
  footer: {
    maxHeight: 150,
    padding: 19,
    paddingLeft: 16,
    paddingRight: 16,
    boxShadow: '0px -2px 7px rgba(0, 0, 0, 0.2)',
    WebkitBoxShadow: '0px -2px 7px rgba(0, 0, 0, 0.2)',
    bottom: 0,
    position: 'fixed'
  },
  footerButton: {
    width: '100%',
    padding: 14.5,
    backgroundColor: theme.colors.primary[3],
    borderRadius: 8,
    transition: '0.3s',
  },
  disabled: {
    backgroundColor: theme.colors.gray[5],
    pointerEvents: 'all',
    cursor: 'not-allowed',
    '&:hover': {
      backgroundColor: theme.colors.gray[5],
    },
  },
}));

export const BookingFooter = ({ currency, price, quantity, onSubmit }: BookingFooterProps) => {
  const { classes, cx } = useStyles();
  return (
    <Footer height='100%'className={cx(classes.footer)}>
      <Grid align="center" gutter={'xs'}>
        <Grid.Col span={12} pb="sm">
          {
            price && currency ? (
              <Text fz={14}>
                Total&nbsp;
                <Text
                  span
                  fz={20}
                  fw={700}
                  sx={(theme) => ({
                    color:
                      theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.primary[3],
                  })}
                >
                  {currency} {parseFloat(price) * quantity}
                </Text>
              </Text>
            ) : <></>
          }
        </Grid.Col>
        <Grid.Col span={12}>
          <UnstyledButton
            className={cx(classes.footerButton)}
            onClick={onSubmit}
          >
            <Text fz={18} fw={700} color="white" align="center">
              Confirm
            </Text>
          </UnstyledButton>
        </Grid.Col>
      </Grid>
    </Footer>
  );
};
