import { DEPLOY_STAGE } from '@/config';
import { createStyles, Flex, Space, UnstyledButton, Text, Divider } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';
import { useNavigate } from 'react-router';
import { Head } from '../Head/Head';
type HeadlessNavigationProps = {
  headTitle?: string;
  headDescription?: string;
  title?: string;
  divider?: boolean;
  children: React.ReactNode;
  back?: number;
};

const useStyles = createStyles((theme) => ({
}));

export const HeadlessNavigation = ({ children, headTitle, headDescription, back, title, divider }: HeadlessNavigationProps) => {
  const { classes, cx } = useStyles();

  const navigate = useNavigate();

  return (
    <Flex direction='column' h='100%' w='100vw' maw='540px'>
      <Head title={headTitle ? headTitle : ''} headDescription={headDescription ? headDescription : ''} />
      <Flex p="xl" pb={divider ? 'xs' : 'xl'}>
        {
          back ? 
          <UnstyledButton
            onClick={() => {
              navigate(back ? -back : -1);
            }}
          >
            <Flex direction='column' justify="center">
              <IconArrowLeft size={20} />
            </Flex>
            </UnstyledButton>
            :
            <Space h={20} />
        }
        {
          title ?
            <Text w='100%' ta='center' fz='xl' fw='bold' >{title}</Text>
            :
            <></>
        }
      </Flex>
      {
        divider ? <Divider my='sm' /> : <></>
      }
      {children}
    </Flex>
  );
};
