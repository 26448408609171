import { Navigate, Route, Routes } from 'react-router-dom';
import { SavedEvents } from './SavedEvents';

type SavedEventsProps = {
  authenticated?: boolean;
};

export const SavedEventsRoutes = ({ authenticated }: SavedEventsProps) => {
  return (
    <Routes>
      <Route path="/" element={authenticated ? <SavedEvents /> : <Navigate to="/auth" />} />
    </Routes>
  );
};
