import { useIdToken, useAuthState } from 'react-firebase-hooks/auth';
import { useQuery } from 'react-query';

import { axios } from '@/lib/axios';
import { ExtractFnReturnType, QueryConfig } from '@/lib/react-query';

import { SavedEvent } from '../types';

type deleteSavedEventProps = {
  id?: string;
  user?: any;
};

export const deleteSavedEvent = async ({ id, user }: deleteSavedEventProps) => {
  if (user) {
    const idToken = await user?.getIdToken();
    const { data } = await axios.delete<SavedEvent>(`/users/events/saved/${id}`, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    });
    return data;
  }
};

type QueryFnType = typeof deleteSavedEvent;

type UseDeleteSavedEventOptions = {
  eventId?: string;
  toggleSave?: boolean;
  user?: any;
  config?: QueryConfig<QueryFnType>;
};

export const useDeleteSavedEvent = ({
  eventId,
  toggleSave,
  config,
  user,
}: UseDeleteSavedEventOptions) => {
  try {
    return useQuery<ExtractFnReturnType<QueryFnType>>({
      ...config,
      queryKey: [toggleSave],
      queryFn: () => deleteSavedEvent({ id: eventId, user }),
    });
  } catch (e) {
    console.log(e);
    return;
  }
};
