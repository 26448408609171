import { createStyles, useMantineTheme, Flex, Text, UnstyledButton, Box } from '@mantine/core';
import { getSearchResponse } from '../api/getSearch';
import InfiniteScroll from 'react-infinite-scroll-component';
import { BaseButton } from '@/components/BaseButton';
import { EventCard } from '@/features/events/components/EventCard';
import { useNavigate } from 'react-router-dom';

type SearchHasResultsProps = {
  resetAllFilters: () => void;
  results?: getSearchResponse;
  fetchResults: (page: number) => void;
};

const useStyle = createStyles((theme) => ({
  infiniteScroll: {
    scrollbarWidth: 'none',
    '::-webkit-scrollbar': {
      display: 'none',
    },
    '::MsOverflowStyle': 'none',
  }
}));


export const SearchHasResults = ({ results, fetchResults, resetAllFilters }: SearchHasResultsProps) => {
  const { classes, cx } = useStyle();
  const theme = useMantineTheme();
  const navigate = useNavigate();

  return (
    <Box>
      <Flex
        justify="space-between"
        w='100%'
        px='md'
        pb='xl'
      >
        <Text>
          <Text inherit span color='primary.3' fw='bold' fz={14}>
            {results?.Total}&nbsp;
          </Text>
          results
        </Text>
        <UnstyledButton
          onClick={resetAllFilters}
        >
          <Text fz={14} underline>
            Reset all filters
          </Text>
        </UnstyledButton>
      </Flex>
      <Box px='md'>
        <InfiniteScroll
          className={cx(classes.infiniteScroll)}
          dataLength={results?.Events?.length || 0} //This is important field to render the next data
          height='calc(100vh - 100px)'
          next={() => fetchResults(results?.Page || 0)}
          hasMore={results?.Events && results?.Events?.length < results?.Total || false}
          scrollThreshold={0.8}
          loader={<h4>Loading...</h4>}
          endMessage={
            <Text align='center' fw='bold' pb='xl'>End of search results</Text>
          }
        >
          {
            results?.Events?.map((event, i) => {
              return (
                <EventCard
                  key={`${event.Snag}-${i}`}
                  onClick={() => {
                    open(`/events/${event.Snag}`, '_blank');
                  }}
                  width={'100%'}
                  name={event.Name}
                  packageCurrency={event.PackageCurrency}
                  packagePrice={event.PackagePrice}
                  snag={event.Snag}
                  tags={event.Tags}
                  pictures={event.Pictures}
                  cardHeight={200}
                  style={{
                    marginBottom: theme.spacing.xl,
                  }}
                />
              );
            })
          }
        </InfiniteScroll>
      </Box>
    </Box>
  );
};
