import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface RouteStore {
  redirectRoute: string;
  setRedirectRoute: (redirectRoute: string) => void;
  clearRedirectRoute: () => void;
  prevRoute: string;
  setPrevRoute: (prevRoute: string) => void;
  clearPrevRoute: () => void;
}

export const useRouteStore = create(
  persist(
    (set) => ({
  redirectRoute: '',
  setRedirectRoute: (redirectRoute: string) => set({ redirectRoute }),
  clearRedirectRoute: () => set({ redirectRoute: '' }),
  prevRoute: '',
  setPrevRoute: (prevRoute: string) => set({ prevRoute }),
  clearPrevRoute: () => set({ prevRoute: '' }),
    }),
    {
      name: 'evol_app_route',
      getStorage: () => sessionStorage, // Use sessionStorage or localStorage
      onRehydrateStorage: () => (state: any) => {
        state.setHasHydrated(true);
      },
    }
  )
);
